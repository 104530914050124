import api from '../api/api';
import { RootStore } from './rootStore';
import { Project } from '../types/project';
import { action, observable, runInAction } from 'mobx';
import { FeeCalculation, FeeCalculationDetailed } from '../types/feeCalculation';
import Config from '../config';
import { ExportContractResponse } from '../types/exportContractResponse';

export default class FeeCalculationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable feeCalculation: FeeCalculation | null = new FeeCalculation();
    @observable feeCalculationDetailed: FeeCalculationDetailed | undefined = new FeeCalculationDetailed();
    @observable isLoadingFeeCalc = false;
    @observable isDirtyForm = false;

    @action getFeeCalculationDetailed = async (feeCalculationId: string | undefined) => {
        this.isLoadingFeeCalc = true;
        if (feeCalculationId) {
            try {
                const feeCalculationDetailed: FeeCalculationDetailed = await api.FeeCalculationMethods.getFeeCalculation(feeCalculationId);
                runInAction(() => {
                    this.feeCalculationDetailed = feeCalculationDetailed;
                    this.isLoadingFeeCalc = false;
                });
                return feeCalculationDetailed;
            } catch (error) {
                runInAction(() => {
                    this.isLoadingFeeCalc = false;
                });
                console.log(error);
            } finally {
                runInAction(() => {
                    this.isLoadingFeeCalc = false;
                });
            }
        } else {
            const feeCalculationDetailed = new FeeCalculationDetailed();
            runInAction(() => {
                this.feeCalculationDetailed = feeCalculationDetailed;
                this.isLoadingFeeCalc = false;
            });
        }
    }


    @action createFeeDraftCalculation = async (feeCalculation: FeeCalculation) => {
        if (feeCalculation) {
            this.isLoadingFeeCalc = true;
            try {
                const createdFeeCalc: FeeCalculation = await api.FeeCalculationMethods.create(feeCalculation);
                runInAction('Create Fee Calculation.', () => {
                    this.feeCalculation = createdFeeCalc;
                    this.isLoadingFeeCalc = false;
                });

                return createdFeeCalc
            } catch (error) {
                console.log(error);
                runInAction(() => {
                    this.isLoadingFeeCalc = false;
                });
            } finally {
                runInAction(() => {
                    this.isLoadingFeeCalc = false;
                });
            }
        }
    }

    @action editFeeCalculation = async (feeCalculation: FeeCalculation) => {
        // this.isLoadingFeeCalc = true;
        try {
            const editedFeeCalculation: FeeCalculation = await api.FeeCalculationMethods.edit(feeCalculation);

            runInAction('Edit Fee Calculation.', () => {
                this.feeCalculation = editedFeeCalculation;
                this.feeCalculationDetailed!.feeCalculation = editedFeeCalculation;      
                // this.isLoadingFeeCalc = false;
            });
            return this.feeCalculation;

        } catch (error) {
            console.log(error);
            runInAction(() => {
                // this.isLoadingFeeCalc = false;
            });
        } finally {
            runInAction(() => {
                // this.isLoadingFeeCalc = false;
            });
        }
    }

    @action syncFeeCalculation = async (feeCalculationDetailed: FeeCalculationDetailed) => {
        this.isLoadingFeeCalc = true;
        try {
            const newFeeCalc = await api.FeeCalculationMethods.syncFeeCalculation(feeCalculationDetailed);

            runInAction('Sync Fee Calculation.', () => {
                this.isLoadingFeeCalc = false;
            });
            return newFeeCalc;

        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoadingFeeCalc = false;
            });
        } finally {
            runInAction(() => {
                this.isLoadingFeeCalc = false;
            });
        }
    }

    @action deleteTasks = async (taskIds: Array<number>) => {
        let deletedTasks: Array<any> = []
        if (taskIds.length > 0) {
            await Promise.all(taskIds.map(async (taskId) => {
                try {
                    let deleted: any = await api.FeeCalculationMethods.deleteTask(taskId);
                    deletedTasks.push(deleted)

                } catch (error) {

                }
            }))
        }
        return deletedTasks // return without waiting for process of 
    }

    @action deleteFeeCalculation = async (feeCalculationId: string | undefined) => {
        if (feeCalculationId) {
            try {
                let deleted: any = await api.FeeCalculationMethods.deleteFeeCalculation(feeCalculationId);
            } catch (error) {

            } 
        }
    }

    @action deleteStaffCosts = async (staffCostIds: Array<number>) => {
        let deletedStaff: Array<any> = []
        if (staffCostIds.length > 0) {
            await Promise.all(staffCostIds.map(async (staffCostId) => {
                try {
                    let deleted: any = await api.FeeCalculationMethods.deleteStaffCost(staffCostId);
                    deletedStaff.push(deleted)

                } catch (error) {

                }
            }))
        }
        return deletedStaff // return without waiting for process of 
    }

    @action deleteFixedCosts = async (fixedCostIds: Array<number>) => {
        let deletedFixedCosts: Array<any> = []
        if (fixedCostIds.length > 0) {
            await Promise.all(fixedCostIds.map(async (fixedCostId) => {
                try {
                    let deleted: any = await api.FeeCalculationMethods.deleteFixedCost(fixedCostId);
                    deletedFixedCosts.push(deleted)

                } catch (error) {

                }
            }))
        }
        return deletedFixedCosts // return without waiting for process of 
    }

    @action exportFeeCalculationExcel = async (feeCalculationId: string | undefined) => {
        if (feeCalculationId) {
            try {
                window.open(Config.API_URL + `/Fee-Calculation/export-fee-calculation/${feeCalculationId}`);
            } catch (error) {
                console.log(error);
            } finally {

            }
        }
    }

    @action exportFeeCalculationCreateContract = async (feeCalculationId: string | undefined) => {
        if (feeCalculationId) {
            try {
                const response : ExportContractResponse = await api.FeeCalculationMethods.exportFeeCalculationCreateContract(feeCalculationId)
                if(response.projectUrl && response.projectId){
                    window.open(response.projectUrl)
                }
            } catch (error) {
                console.log(error);
            } finally {

            }
        }
    }

    @action duplicateFeeCalculation = async (feeCalculationId: string | undefined, projectId: string | undefined) => {
        if(feeCalculationId && projectId){
            this.isLoadingFeeCalc = true;
            try {
                const duplicatedFeeCalc : any = await api.FeeCalculationMethods.duplicate(feeCalculationId, projectId);
                runInAction('Create Fee Calculation.', () => {
                    this.isLoadingFeeCalc = false;
                });
                return duplicatedFeeCalc
            } catch (error) {
                console.log(error);
                runInAction(() => {
                    this.isLoadingFeeCalc = false;
                });
            } finally {
                runInAction(() => {
                    this.isLoadingFeeCalc = false;
                });
            }
        }
    }

    @action setIsDirtyForm = (isDirty: boolean) => {
        runInAction(() => {
            this.isDirtyForm = isDirty;
        });
    }


}