export const ProjectStatuses: Array<string> = ['Prospect','Active','Complete','On Hold']

type FeeCalculationState = {
  id: string;
  name: string;
}

export const MemberAccessLevels = {
  ADMIN: "Admin",
  STAFF: "Staff",
  NON_USER: "NonUser"
}

export const FeeCalculationStates : Array<FeeCalculationState> = [{ id: 'Draft', name: 'Draft'}, { id: 'Approved', name: 'Approved'}, { id: 'Accepted', name: 'Client accepted'}, { id: 'Declined', name: 'Client declined'}];

export const DomesticCategories = ['One-off new house', 'House extension, conversion or alteration','Other private housing','Other domestic'];

export const CommercialCategories: Array<string> = ['Public housing (including social housing)','Offices','Retail','Sport and leisure','Culture and entertainment','Industrial','Health','Schools and colleges','Universities','Transport','Mixed use','Other commercial']

export const RibaStages = [
    {
      id: 1,
      name: 'Strategic Definition',
      stageId: 0,
      colour:'#f99c34'
    },
    {
      id: 2,
      name: 'Preparation and Brief',
      stageId: 1,
      colour:'#eca9cd'
    },
    {
      id: 3,
      name: 'Concept Design',
      stageId: 2,
      colour:'#fddd0e'
    },
    {
      id: 4,
      name: 'Spatial Coordination',
      stageId: 3,
      colour:'#7accd3'
    },
    {
      id: 5,
      name: 'Technical Design',
      stageId: 4,
      colour:'#8fc0a4'
    },
    {
      id: 6,
      name: 'Manufacturing and Construction',
      stageId: 5,
      colour:'#aab0d2'
    },
    {
      id: 7,
      name: 'Handover',
      stageId: 6,
      colour:'#edd99f'
    },
    {
      id: 8,
      name: 'Use',
      stageId: 7,
      colour:'#8770d7'
    }
  ];

  export const CustomAuthErrors = [471, 472, 473];

  export const NonEditableStates = ['Accepted', 'Declined'];
