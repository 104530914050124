import { RibaStages } from '../utils/Constants';
import { FeeCalculationStage } from './feeCalculationStage';
export class FeeCalculation {
    id?: number = 0;
    version?: string = '';
    state: string = '';
    created?: Date = new Date();
    createdBy?: number = 0;
    projectId: number = 0
    lastModifiedBy?: string = '';
    lastModified: Date = new Date();
    inSync? : boolean = true;
    feeCalculationStages?: Array<FeeCalculationStage> = [];
    totalProjectFee?: number = 0;
    totalProjectCost?: number = 0;
    constructionCost?: number = 0;
    name?: string = '';

    constructor(init?: FeeCalculation) {
        if (init) {
            this.feeCalculationStages = populateDefaultStages();
            Object.assign(this, init);
        }
    }
}

export class FeeCalculationDetailed {
    acceptUpdates: boolean = false;
    inSync: boolean = true;
    newFeeCalculation?: FeeCalculation = new FeeCalculation();
    feeCalculation: FeeCalculation = new FeeCalculation();
}

const populateDefaultStages = (): Array<FeeCalculationStage> => {
    const newStages: Array<FeeCalculationStage> = [];
    RibaStages.forEach((stage, i) => {
        let newStage = new FeeCalculationStage();
        newStage.stage.id= RibaStages[i].id;
        newStage.stage.stageId = RibaStages[i].stageId;
        newStage.stage.name = RibaStages[i].name;
        newStages.push(newStage);
    })
    return newStages;
}

