import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import FormInput from '../molecules/FormInput';
import { AddEditProjectFormValidation } from '../../utils/validation/AddEditProjectFormValidation';
import Alert from '../molecules/Alert';
import FormLabel from '../molecules/FormLabel';
import { Formik, Form, Field, FormikProps } from "formik";
import { observer } from 'mobx-react-lite';
import { Project } from '../../types/project';
import { Client } from '../../types/client';
import { useLocation } from 'react-router-dom';
import { DomesticCategories, CommercialCategories, ProjectStatuses } from '../../utils/Constants'
import FormTextAreaInput from '../molecules/FormTextAreaInput';
import PromptIfDirty from '../molecules/PromptIfDirty';
import ReactTooltip from 'react-tooltip';
import { runInAction } from 'mobx';

type AddEditProjectFormProps = {
    handleSubmit: (projectId: number) => any,
    handleBack: (projectId: number) => any,
    project: Project | null,
    confirmButtonText: string,
    cancelButtonText: string
}

const AddEditProjectForm: React.FC<AddEditProjectFormProps> = observer((props) => {
    const { project, handleBack, handleSubmit, confirmButtonText, cancelButtonText } = props;
    const rootStore = useContext(RootStoreContext);
    const { client, clients, clearLoadedClient, getClient, editClient, getClients, createClient } = rootStore.clientStore;
    const { editProject, createProject, projects } = rootStore.projectStore;
    const { practice, getPractice } = rootStore.practiceStore;
    const { member } = rootStore.memberStore;
    const { pathname } = useLocation();
    const [subCategories, setSubCategories] = useState(DomesticCategories);
    const [saveProjectError, setSaveProjectError] = useState<string>('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isDuplicateName, setIsDuplicateName] = useState(false);
    const [isDuplicateRef, setIsDuplicateRef] = useState(false);
    const [isDirtyForm, setIsDirtyForm] = useState(false);

    useEffect(() => {
        clearLoadedClient();
        getClients().then(() => {
            if (!clients || clients.length <= 0) {
                if (project) {
                    project.existingClient = 'No';
                }
            }

            getPractice().finally(() => {
            })
        });
    }, [clearLoadedClient, getClients, getPractice, project]);

    const changeSubCategories = (category: string) => {
        if (category === 'Domestic') {
            setSubCategories(DomesticCategories)
        } else {
            setSubCategories(CommercialCategories)
        }
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const newClientToggle = (flag: boolean, projectForm: FormikProps<Project>) => {
        if (!flag) {
            clearLoadedClient();
            projectForm.setFieldValue('clientName', ''); 
            projectForm.setFieldValue('contactEmail', ''); 
            projectForm.setFieldValue('contactName', ''); 
            projectForm.setFieldValue('clientId', '');
            projectForm.setFieldValue('existingClientId', '');
        }
    }

    const GetClient = (projectForm: FormikProps<Project>) => {
        if(project && project.id != 0 && projectForm.values.existingClientId != project.clientId){
            getClient(project.clientId).then((response) => { 
                projectForm.setFieldValue('clientName', response!.clientName); 
                projectForm.setFieldValue('contactEmail', response!.contactEmail); 
                projectForm.setFieldValue('contactName', response!.contactName); 
                projectForm.setFieldValue('clientId', response!.id);
                projectForm.setFieldValue('existingClientId', response!.id);
            });
        }
    }

    const checkForDuplicateProjectName = (e: any) => {
        let value = e.currentTarget.value;

        let dupe = projects?.filter(proj => (proj.name.toLowerCase() === value.toLowerCase()) && proj.id !== project?.id);

        if (dupe && dupe?.length > 0) {
            // we have at least one duplicate. set to true
            setIsDuplicateName(true);
        } else {
            setIsDuplicateName(false);
        }     
    }

    const checkForDuplicateProjectRef = (e: any) => {
        let value = e.currentTarget.value;

        let dupe = projects?.filter(proj => (proj.number.toLowerCase() === value.toLowerCase()) && proj.id !== project?.id);

        if (dupe && dupe?.length > 0) {
            // we have at least one duplicate. set to true
            setIsDuplicateRef(true);
        } else {
            setIsDuplicateRef(false);
        }     
    }

    const saveClient = (project: any) => {
        setSaveProjectError('');
        setIsDirtyForm(false);
        if (project.existingClient === 'No') {
            createClient(new Client({
                clientName: project.clientName,
                contactName: project.contactName,
                contactEmail: project.contactEmail,
                practiceId: practice?.id ?? 0
            })).then(newClient => {
                if (newClient?.id) {
                    project.clientId = newClient?.id;
                    saveProject(project);
                } else {
                    setSaveProjectError('There was a problem saving creating the project, please try again later');
                }
            });
        }
        else {
            if (client?.clientName !== project.clientName || client?.contactName !== project.contactName || client?.contactEmail !== project.contactEmail) {
                
                runInAction(() => {
                    client!.clientName = project.clientName;
                    client!.contactName = project.contactName;
                    client!.contactEmail = project.contactEmail;

                    editClient(client!);
                });
            }

            saveProject(project);
        }
    }

    const saveProject = (project: any) => {
        project.practiceId = practice?.id ?? 0;
        project.memberId = member?.id ?? 0;
        project.clientId = Number(project.clientId);
        setIsDirtyForm(false);
        if (project.id > 0) {
            editProject(project).finally(() => {
                handleSubmit(project.id);
            });
        } else if (project.id === 0) {
            createProject(project).then((createdProject: any) => {
                if (createdProject && createdProject.id) {
                    handleSubmit(createdProject.id);
                } else {
                    //Project creation errors
                }
            });
        }
    }

    return (
        <>

            {project && (
                <Formik
                    initialValues={project}
                    enableReinitialize={true}
                    validationSchema={AddEditProjectFormValidation}
                    validateOnChange={true}
                    validateOnBlur={false}
                    isInitialValid={false}
                    onSubmit={(values) => {
                        saveClient(values)
                    }}>
                    {(projectForm) => (
                        <Form onChange={() => { setFormSubmitted(false);  GetClient(projectForm)}}>
                            <PromptIfDirty isDirtyForm={isDirtyForm} setIsDirtyForm={setIsDirtyForm}  />
                            { project.id === 0 && (
                                <div className="join-riba-form-content__section">
                                    <div className="form-heading ">
                                        <h2 className="form-heading__title">Client details </h2>
                                        <div className="divider divider--dark"></div>
                                    </div>

                                    <div className="narrow-content-container ml-0 mb-40 mt-20" >
                                        <div className="form-input-container ">
                                            <div className="form-input-container__label">
                                                <FormLabel labelText={'Is this an existing client?'} />
                                            </div>
                                            <div className="form-input-container__input">
                                                <div className="radio-button-group">

                                                    <label className="radio-button__group ">
                                                        <Field type="radio" 
                                                            value="Yes" 
                                                            name="existingClient" 
                                                            className="radio-button__input disabled" 
                                                            disabled={clients === null || clients.length <= 0 ? 'disabled' : ''}
                                                            checked={projectForm.values.existingClient === 'Yes' ? 'checked' : ''} />
                                                        <i className="radio-button__radio"></i>
                                                        <div className="radio-button__label">
                                                            <p className="radio-button__text">Yes</p>
                                                        </div>
                                                    </label>

                                                    <label className="radio-button__group ">
                                                        <Field 
                                                            type="radio" 
                                                            onChange={(e: any) => { newClientToggle(false, projectForm); projectForm.setFieldValue('existingClient', 'No'); }} 
                                                            value="No" 
                                                            name="existingClient" 
                                                            className="radio-button__input"
                                                            checked={projectForm.values.existingClient === 'No' ? 'checked' : ''} />
                                                        <i className="radio-button__radio"></i>
                                                        <div className="radio-button__label">
                                                            <p className="radio-button__text">No</p>
                                                        </div>
                                                    </label>

                                                    {clients === null || clients.length <= 0 && <p className="validation-summary-errors">You don't have any saved clients.</p>}
                                                </div>
                                            </div>
                                        </div>

                                        {projectForm.values.existingClient === 'Yes' && client === null ? (
                                            <>
                                                <div className="form-input-container mt-20 mb-10">
                                                    <div className="form-input-container__label">
                                                        <div className="form-label">
                                                            <div className="form-label__title">Select existing client:</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <div className="input-wrapper">
                                                            <Field as="select" id="clientId" name="clientId" 
                                                                   onChange={(e: any) => { 
                                                                        getClient(e.target.value).then((response) => { 
                                                                            projectForm.setFieldValue('clientName', response!.clientName); 
                                                                            projectForm.setFieldValue('contactEmail', response!.contactEmail); 
                                                                            projectForm.setFieldValue('contactName', response!.contactName); 
                                                                            projectForm.setFieldValue('clientId', response!.id);
                                                                            projectForm.setFieldValue('existingClientId', response!.id);
                                                                        });
                                                                    }}
                                                                   className={(projectForm.errors.clientId && projectForm.touched.clientId) ? 'dropdown-input dropdown-input--error' : 'dropdown-input'}>
                                                                <option value="">Select</option>
                                                                {clients?.slice().sort((a, b) => (a.clientName > b.clientName ? 1 : -1)).map((client: Client, index) => (
                                                                    <option key={index} value={client.id}>{client.clientName}</option>
                                                                ))}

                                                            </Field>
                                                            {(projectForm.errors.clientId && projectForm.touched.clientId) && <div className="validation-summary-errors">
                                                                <ul>
                                                                    <li>{projectForm.errors.clientId}</li>
                                                                </ul>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                                <>
                                                    <div className="form-input-container">
                                                        <div className="form-input-container__label">
                                                            <FormLabel labelText={'Client name:'} />
                                                            <p>Required field</p>
                                                        </div>
                                                        <div className="form-input-container__input">
                                                            <Field component={FormInput} id="existingClientId" onChange={(e: any) => { projectForm.handleChange(e) }} type="hidden" name="existingClientId" />
                                                            <Field component={FormInput} id="clientName" onChange={(e: any) => { projectForm.handleChange(e) }} type="text" name="clientName" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div className="form-input-container">
                                                        <div className="form-input-container__label">
                                                            <FormLabel labelText={'Contact name:'} />
                                                        </div>
                                                        <div className="form-input-container__input">
                                                            <Field component={FormInput} id="contactName" onChange={(e: any) => { projectForm.handleChange(e) }} type="text" name="contactName" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div className="form-input-container">
                                                        <div className="form-input-container__label">
                                                            <FormLabel labelText={'Contact email:'} />
                                                        </div>
                                                        <div className="form-input-container__input">
                                                            <Field component={FormInput} id="contactEmail" onChange={(e: any) => { projectForm.handleChange(e) }} type="text" name="contactEmail" placeholder="" />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                </div>
                            )}

                            <div className="join-riba-form-content__section">
                                <div className="form-heading ">
                                    <h2 className="form-heading__title">Project details</h2>
                                    <div className="divider divider--dark"></div>
                                </div>

                                <div className="narrow-content-container ml-0 mb-40 mt-20" >
                                    <div className="form-input-container ">
                                        <div className="form-input-container__label">
                                            <FormLabel labelText={'Project name:'} />
                                            <p>Required field</p>
                                        </div>
                                        <div className="form-input-container__input">
                                            <Field 
                                                component={FormInput} 
                                                id="name" 
                                                onChange={(e: any) => { projectForm.handleChange(e) }}
                                                onBlur={(e:any) => { checkForDuplicateProjectName(e) }}
                                                className={(isDuplicateName) ? 'singleline-text-input singleline-text-input--full-width singleline-text-input--error' : 'singleline-text-input singleline-text-input--full-width'}
                                                type="text" 
                                                name="name" 
                                                placeholder="" />
                                                {isDuplicateName && (<div className="validation-summary-errors"><ul><li>This project name already exists.</li></ul></div>) }
                                        </div>
                                    </div>

                                    <div className="form-input-container">
                                        <div className="form-input-container__label">
                                            <div className="form-label">
                                                <div className="form-label__title">Project reference: <div className="field-help tooltip-trigger" data-tip="React-tooltip" data-for="projectNumber">
                                                <div className="field-help__icon" >
                                                    <span className="material-icons info-icon ">info</span>
                                                </div>
                                            </div>
                                            <ReactTooltip
                                                type="light"
                                                place="right"
                                                effect="solid"
                                                className="react-tooltip"
                                                border={true}
                                                id='projectNumber'
                                                textColor='#333f48' backgroundColor='#f9f9f9' borderColor='#f2f2f2'>
                                                <strong>Project reference</strong><br />
                                                If you use a numbering system in your own office, enter that same number here
                                                </ReactTooltip></div>
                                            </div>
                                            <p>Required field</p>
                                        </div>
                                        <div className="form-input-container__input">
                                            <Field 
                                                component={FormInput} 
                                                id="number" 
                                                onChange={(e: any) => { projectForm.handleChange(e) }} 
                                                onBlur={(e:any) => { checkForDuplicateProjectRef(e) }}
                                                className={(isDuplicateRef) ? 'singleline-text-input singleline-text-input--full-width singleline-text-input--error' : 'singleline-text-input singleline-text-input--full-width'}
                                                type="text" 
                                                name="number" 
                                                placeholder="" />
                                                {isDuplicateRef && (<div className="validation-summary-errors"><ul><li>This project reference number already exists.</li></ul></div>) }
                                        </div>
                                    </div>

                                    <div className="form-input-container mt-20 mb-10">
                                        <div className="form-input-container__label">
                                            <div className="form-label">
                                                <div className="form-label__title">Project status:</div>
                                            </div>
                                        </div>
                                        <div className="form-input-container__input">
                                            <div className="input-wrapper">
                                                <Field as="select" id="status" onChange={(e: any) => { projectForm.handleChange(e) }} name="status" className={projectForm.errors.status ? 'dropdown-input dropdown-input--error' : 'dropdown-input'}>
                                                    {ProjectStatuses?.map((projectStatus: string, index) => (
                                                        <option key={index} value={projectStatus}>{projectStatus}</option>
                                                    ))}

                                                </Field>
                                                {projectForm.touched.status &&
                                                    projectForm.errors.status && <div className="validation-summary-errors">
                                                        <ul>
                                                            <li>{projectForm.errors.status}</li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-input-container ">
                                        <div className="form-input-container__label">
                                            <FormLabel labelText={'Select project category:'} />
                                        </div>
                                        <div className="form-input-container__input">
                                            <div className="radio-button-group">

                                                <label className="radio-button__group ">
                                                    <Field onChange={(e: any) => { changeSubCategories(e.target.value); projectForm.handleChange(e) }} type="radio" value="Domestic" name="category" className="radio-button__input" />
                                                    <i className="radio-button__radio"></i>
                                                    <div className="radio-button__label">
                                                        <p className="radio-button__text">Domestic</p>
                                                    </div>
                                                </label>
                                                <label className="radio-button__group ">
                                                    <Field onChange={(e: any) => { changeSubCategories(e.target.value); projectForm.handleChange(e) }} type="radio" value="Commercial" name="category" className="radio-button__input" />
                                                    <i className="radio-button__radio"></i>
                                                    <div className="radio-button__label">
                                                        <p className="radio-button__text">Commercial</p>
                                                    </div>
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-input-container">
                                        <div className="form-input-container__label">
                                            <FormLabel labelText={'Sub-category:'} />
                                            <p>Required field</p>
                                        </div>
                                        <div className="form-input-container__input">
                                            <div className="input-wrapper">
                                                <Field as="select" onChange={(e: any) => { projectForm.handleChange(e) }} id="subCategory" name="subCategory" className={(projectForm.errors.subCategory && projectForm.touched.subCategory) ? 'dropdown-input dropdown-input--error' : 'dropdown-input'}>
                                                    <option value="">Select</option>
                                                    {subCategories?.map((subCategory: string, index) => (
                                                        <option key={index} value={subCategory}>{subCategory}</option>
                                                    ))}

                                                </Field>
                                                {(projectForm.errors.subCategory && projectForm.touched.subCategory) && <div className="validation-summary-errors">
                                                    <ul>
                                                        <li>{projectForm.errors.subCategory}</li>
                                                    </ul>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-input-container">
                                        <div className="form-input-container__label">
                                            <FormLabel labelText={'Other category:'} />
                                        </div>
                                        <div className="form-input-container__input">
                                            <Field component={FormInput} id="tertiaryCategory" onChange={(e: any) => { projectForm.handleChange(e) }} type="text" name="tertiaryCategory" placeholder="" />
                                        </div>
                                    </div>


                                    <div className="form-input-container">
                                        <div className="form-input-container__label">
                                            <FormLabel labelText={'Site address:'} />
                                            <p>Required field</p>
                                        </div>
                                        <div className="form-input-container__input">
                                            <Field component={FormTextAreaInput} id="siteAddress" onChange={(e: any) => { projectForm.handleChange(e) }} name="siteAddress" placeholder="" />
                                        </div>
                                    </div>

                                    <div className="form-input-container">
                                        <div className="form-input-container__label">
                                            <FormLabel labelText={'Brief project description:'} />
                                        </div>
                                        <div className="form-input-container__input">
                                            <Field component={FormTextAreaInput} id="description" onChange={(e: any) => { projectForm.handleChange(e) }} name="description" placeholder="" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {Object.keys(projectForm.errors).length > 0 &&
                                Object.keys(projectForm.touched).length > 0 &&
                                formSubmitted && (
                                    <Alert text='Please correct the errors highlighted above' styleClass='alert alert--error' title='Error' />
                                )}

                            {saveProjectError && (
                                <Alert text={saveProjectError} styleClass='alert alert--error' title='Error' />
                            )}

                            <div className="join-riba-form-content__actions">
                                <button className="button" onClick={() => { projectForm.resetForm(); handleBack(0)}}>{cancelButtonText}</button>
                                <button type="button" disabled={!projectForm.isValid || (isDuplicateName || isDuplicateRef)} onClick={() => { projectForm.submitForm(); setFormSubmitted(true) }} className="button button--opaque ">{confirmButtonText}</button>
                            </div>
                        </Form>

                    )}
                </Formik>
            )}
        </>

    )
});

export default AddEditProjectForm;