import { FeeCalculationStageTask } from './feeCalculationStageTask';
import { Stage } from './stage';
import { StageActivity } from './stageActivity';

export class FeeCalculationStage {
  id: number = 0;
  feeCalculationId: number = 0;
  stage: Stage = new Stage();
  feeCalculationStageTasks: Array<FeeCalculationStageTask> = [];
  totalStageCost: number = 0;
  practiceMargin: number = 0;
  clientStageFee: number = 0;
  enabled: boolean = false;
  stageActivities: Array<StageActivity> = [];

  constructor(init?: FeeCalculationStage) {
    if (init) {
        Object.assign(this, init);
    }
  }

};
