import React, { useEffect, useContext } from 'react';
import SetupFormSteps from '../../organisms/SetupFormSteps';
import { RootStoreContext } from '../../../stores/rootStore';
import PracticeOverheadsForm from '../../organisms/PracticeOverheadsForm';
import { observer } from 'mobx-react-lite';
import { history } from '../../../';

const Step2: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { getPractice, practice } = rootStore.practiceStore;

    const handleSubmit = () => {
        history.push('/setup/step3');
        window.scrollTo(0, 0);
    }

    const handleBack = () => {
        history.push('/setup/step1');
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        let isLoaded = false;
        getPractice().finally(() => {
            // isLoaded(true);
        })

        return () => {
            isLoaded = true;   // clean up function
        };

    }, []);

    return (
        <>
            <div className="large-content-container mt-20">
                <h1>RIBA Fee Calculator setup</h1>
            </div>
            <div className="narrow-content-container ">

                <SetupFormSteps practice={practice} currentStep={2} />
                <PracticeOverheadsForm 
                title='Add your annual company overheads'
                handleBack={handleBack} 
                handleSubmit={handleSubmit} >
                    <p>Your annual overhead costs are the annual ongoing costs of running your business. You only need to enter this once to set up your practice on the RIBA Fee Calculator, but you can edit or add to it any time by managing your settings.</p>
                    <p>What you enter here will be used in your fee calculations, so make sure you use accurate information. </p>
                    <p>Many practices submit these details each year for the RIBA Business Benchmarking, our yearly survey tracking the business of doing architecture among RIBA Chartered Practices.</p>
                </PracticeOverheadsForm>
            </div>
        </>
    )

})

export default Step2;