import React from 'react';

type FormLabelProps =  {
    labelText?: string,
    subText?: string
}

const FormLabel: React.FC<FormLabelProps> = (props) => {

    const { labelText, subText } = props;
    
    return (
        <>
        {labelText && (
            <div className="form-label">
            <div className="form-label__title">{labelText || ''}</div>
            <div className="form-label__subtext">{subText || ''}</div>
        </div>
        )}
        </>
    )
}

export default FormLabel;