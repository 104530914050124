import React, { useEffect, useContext } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import ConfirmPracticeDetailsForm from '../../organisms/ConfirmPracticeDetailsForm';
import { observer } from 'mobx-react-lite';
import SettingsSideNavigation from '../../organisms/SettingsSideNavigation';
import Loading from '../../organisms/Loading';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';

const PracticeDetails: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { getPractice, isLoadingPractice } = rootStore.practiceStore;

    useEffect(() => {
        let isLoaded = false;
        getPractice().finally(() => {
        })
        return () => {
            isLoaded = true;
        };
    }, [getPractice]);

    return (
        <>

            <div className="dashboard-container">
                <div className="dashboard-content">
                    <SideNavigationMobile />
                    <SettingsSideNavigation currentPage={1} />
                    {isLoadingPractice ? (
                        <Loading />
                    ) : (
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30">
                                            <ConfirmPracticeDetailsForm
                                                title='Practice details'
                                            >
                                            </ConfirmPracticeDetailsForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>

        </>
    )

})

export default PracticeDetails;