import React, { useEffect, useContext } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import SetupFormSteps from '../../organisms/SetupFormSteps';
import ConfirmPracticeDetailsForm from '../../organisms/ConfirmPracticeDetailsForm';
import { observer } from 'mobx-react-lite';
import { history } from '../../../';

const Step1: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { getPractice, practice } = rootStore.practiceStore;

    const handleSubmit = () =>{
        history.push('/setup/step2');
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        let isLoading = true;
        getPractice().finally(() => {
        })

        return () => {
            isLoading = false;   // clean up function
        };

    }, []);

    return (
        <>
            <div className="large-content-container mt-20">
                <h1>RIBA Fee Calculator setup</h1>
            </div>
            <div className="narrow-content-container ">

                <SetupFormSteps practice={practice} currentStep={1} />
                <ConfirmPracticeDetailsForm title='Check practice details' handleSubmit={handleSubmit}>
                    <p>These are the practice details we have for you:</p>
                </ConfirmPracticeDetailsForm>
            </div>
        </>
    )

})

export default Step1;