import React, { Fragment, useContext, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, Redirect, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { browserName, browserVersion, CustomView } from 'react-device-detect';
import { RootStoreContext } from './stores/rootStore';
import { observer } from "mobx-react-lite";
import AcceptTerms from './components/pages/Setup/AcceptTermsAdmin';
import AcceptTermsUser from './components/pages/Setup/AcceptTermsUser';
import AddEditMember from './components/pages/Setup/AddEditMember';
import Step1 from './components/pages/Setup/Step1';
import Step2 from './components/pages/Setup/Step2';
import Step3 from './components/pages/Setup/Step3';
import Step4 from './components/pages/Setup/Step4';
import NotFound from './components/pages/NotFound';
import Error from './components/pages/Error';
import Projects from './components/pages/Projects/Projects';
import Settings from './components/pages/Settings';
import MobileLanding from './components/pages/MobileLanding';
import UnsupportedBrowserLanding from './components/pages/UnsupportedBrowserLanding';
import Header from './components/organisms/Header';
import Footer from './components/organisms/Footer';
import InfoBar from './components/molecules/InfoBar';
import { history } from './.';
import PracticeDetails from './components/pages/Settings/PracticeDetails';
import AnnualOverheads from './components/pages/Settings/AnnualOverheads';
import StaffMembers from './components/pages/Settings/StaffMembers';
import AddEditMemberSettings from './components/pages/Settings/AddEditMemberSettings';
import EditPracticeSettings from './components/pages/Settings/EditPracticeSettings';
import AddEditProject from './components/pages/Projects/AddEditProject';
import ProjectDetails from './components/pages/Projects/ProjectDetails';
import FeeCalculationDetails from './components/pages/FeeCaculation/FeeCalculationDetails';
import FeeCalculationStages from './components/pages/FeeCaculation/FeeCalculationStages';
import DuplicateFeeCalculationProject from './components/pages/Projects/DuplicateFeeCalculationProject';
import CookieBanner from './components/molecules/CookieBanner';
import FeeCalculationOverview from './components/pages/Help/FeeCalculationOverview';
import GeneralInformation from './components/pages/Help/GeneralInformation';
import TutorialAndArticles from './components/pages/Help/TutorialAndArticles';
import Loading from './components/organisms/Loading';

const App: React.FC<RouteComponentProps> = observer(({ location }) => {

  ReactGA.initialize('UA-10963311-4');

  const rootStore = useContext(RootStoreContext);
  const { login, getMember, authenticateMember, member } = rootStore.memberStore;
  const { validateMemberMFA } = rootStore.mfaStore;
  const { getPractice } = rootStore.practiceStore;

  const [showBetaBanner, setBannerVisibility] = useState(true);
  const [showCookieBanner, setCookieBannerVisibility] = useState(true);

  useEffect(() => {
    let betaBoolean= true;
    let bannerBoolean= true;

    if (window.localStorage.getItem('showBetaBanner') && window.localStorage.getItem('showBetaBanner') === 'false') {
      betaBoolean = false;
    }

    if (window.localStorage.getItem('showCookieBanner') && window.localStorage.getItem('showCookieBanner') === 'false') {
      bannerBoolean = false;
    }
    
    setBannerVisibility(betaBoolean);
    setCookieBannerVisibility(bannerBoolean);
    
    authenticateMember((auth: any) => {
      //Is user authenticated and onboarded?
      if (auth && auth.authenticated) {
        getMember().then((loadedMember: any) => {
          if(loadedMember) {
            validateMemberMFA(() => {
              getPractice().then((loadedPractice: any) => {
                //If not a help page
                if (window.location.href.indexOf("help") < 0) {
                  //Has the practice been onboarded?
                  if (loadedPractice && !loadedPractice.onboarded) {
                    history.push('/setup/accept-terms')
                  } else if (loadedMember && !loadedMember.acceptedTermsAndConditions) {
                    history.push('/setup/accept-terms-user')
                  }

                  if (window.location.pathname === '/') {
                    history.push('/projects');
                  }
                }
              })
            }, loadedMember.cubeMemberId, loadedMember.email);
          }
        });
      } else if (auth && auth.status) {
        // If user has custom auth status
        // console.log(auth.status);
      } else {
        // user not authenticated, boot 'em
        login();
      }
      
    })
  }, [showBetaBanner, showCookieBanner]);

  useEffect(() => {
    if (window.innerWidth < 900) {
      history.push('/mobile-landing');
    }
  }, []);

  useEffect(() => {
    var redirectIfVersLower = (version:Number) => {
      if(Number(browserVersion) < version){
        history.push('/unsupported-browser-landing');
      }
    }
    switch(browserName) {
        case 'Chrome': {
          redirectIfVersLower(79);
          break;
        }
        case 'Edge': {
          redirectIfVersLower(83);
          break;
        }
        case 'Firefox': {
          redirectIfVersLower(72);
          break;
        }
        case 'IE': {
          history.push('/unsupported-browser-landing');
          break;
        }
    }
  })


  
  const closeBetaBar = () => {
    window.localStorage.setItem('showBetaBanner', 'false');
    setBannerVisibility(false);
  }

  const closeCookieBanner = () => {
    window.localStorage.setItem('showCookieBanner', 'false');
    setCookieBannerVisibility(false);
  }

  return (
    <Fragment>
      {showBetaBanner && (<InfoBar handleInfoBarClose={closeBetaBar} />)}
      <Header member={member} />
      <>
        <Route exact path="/" render={() => (
          <Loading />
        )} />
        <Route
          path={'/(.+)'}
          render={() => (
            <Switch>
              <Route exact path='/setup/accept-terms' component={AcceptTerms} />
              <Route exact path='/setup/accept-terms-user' component={AcceptTermsUser} />
              <Route exact path='/setup/step1' component={Step1} />
              <Route exact path='/setup/step2' component={Step2} />
              <Route exact path='/setup/step3' component={Step3} />
              <Route exact path='/setup/step4' component={Step4} />
              <Route path={['/setup/step3/:id', '/setup/new-member']} component={AddEditMember} />
              <Route exact path='/settings/practice-details' component={PracticeDetails} />
              <Route exact path='/settings/annual-overheads' component={AnnualOverheads} />
              <Route exact path='/settings/staff-members' component={StaffMembers} />
              <Route path={['/settings/staff-members/:id', '/settings/new-member']} component={AddEditMemberSettings} />
              <Route path={['/settings/edit-practice']} component={EditPracticeSettings} />
              <Route exact path='/projects' component={Projects} />
              <Route path={['/projects/new-project', '/projects/edit/:id']} component={AddEditProject} />
              <Route exact path={['/projects/:id']} component={ProjectDetails} />
              <Route exact path={['/duplicate-fee-calculation/:feeCalculationId']} component={DuplicateFeeCalculationProject} />
              <Route exact path={['/projects/:id/fee-calculation/:feeCalculationId']} component={FeeCalculationDetails} />
              <Route exact path={['/projects/:id/fee-calculation-stages/:feeCalculationId']} component={FeeCalculationStages} />
              <Route path='/mobile-landing' component={MobileLanding} />
              <Route path='/unsupported-browser-landing' component={UnsupportedBrowserLanding} />
              <Route path='/settings' component={Settings} />
              <Route path={['/error/:errorId']} component={Error} />
              <Route exact path='/help/fee-calculation-overview' component={FeeCalculationOverview} />
              <Route exact path='/help/tutorials-and-articles' component={TutorialAndArticles} />
              <Route path='/help' component={GeneralInformation} />
              <Route component={NotFound} />
            </Switch>
          )} />
      </>
      <Footer />
      {
        showCookieBanner && (<CookieBanner handleCookieBannerClose={closeCookieBanner} />)
      }
    </Fragment>
  );


});

export default withRouter(App);
