import { RootStore } from "./rootStore";
import { action, observable, reaction ,runInAction } from "mobx";

export default class CommonStore {
    rootStore: RootStore;
    @observable isAppLoaded = false;
    @observable onBoardingStep = 0;
    @observable isMobileMenuOpen = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action setOnBoardingStep = (stepNumber : number) =>{
        if(stepNumber){
            runInAction(()=>{
                this.onBoardingStep = stepNumber;
            })
        }
    }

    @action setIsAppLoaded = () => { this.isAppLoaded = true; }

    @action toggleMobileMenuOpen = () => {
        runInAction(()=>{
            this.isMobileMenuOpen = !this.isMobileMenuOpen
        })
    }


}