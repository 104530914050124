import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';

type TermsAndConditionsFormProps = {
    handleSubmit: () => any
}


const TermsAndConditionsUserForm: React.FC<TermsAndConditionsFormProps> = observer((props) => {
    const { handleSubmit } = props;
    const rootStore = useContext(RootStoreContext);
    const { member, acceptTermsAndConditions } = rootStore.memberStore;
    const [checkedTerms, setChecked] = useState(false);
    const toggleTermsChecked = () => setChecked(!checkedTerms);

    useEffect(() => {
        if (member?.acceptedTermsAndConditions) {
            handleSubmit();
        }
    });

    const handleTermsFormSubmit = () => {
        acceptTermsAndConditions().finally(() => {
            //Is loading false
            handleSubmit();
        });
    }

    return (
        <>
            <div className="large-content-container mt-20">
                <h1>RIBA Fee Calculator setup</h1>
            </div>
            <div className="heading-two">
                <h2 className="heading-two__heading">Setting up the RIBA Free Calculator </h2>
            </div>
            <div className="narrow-content-container">
                <article>
                    <div className="content-container">
                        <p><strong>The RIBA Fee Calculator will help you develop resource-based fee calculations for architecture work.</strong></p>
                        <div className="heading-two">
                            <h2>How does it work? </h2>
                        </div>
                        <ul>
                            <li>Create detailed, unique <strong>resource-based fees</strong> for each project or client </li>
                            <li>Produce calculations aligned with the <strong>RIBA Plan of Work</strong> </li>
                            <li>Download and import into your own standard fee proposals, or into the RIBA standard forms of appointment using <strong>RIBA Contracts Digital</strong> </li>

                        </ul>
                        <div className="heading-two">
                            <h2>Before you start</h2>
                        </div>
                        <p>Your system administrator has already entered your <strong>company overhead costs</strong> (including, for example, any premises, utilities, travel, marketing, financial and legal costs) and <strong>staff salary details</strong> (base salary, hourly rates, employer National Insurance contributions). Your company, financial and salary information <strong>can only be accessed by ‘administrator’ users</strong> in Settings.</p>
                        <p>You must accept our <a target="_BLANK" href="https://www.architecture.com/riba-fee-calculator/terms-and-conditions">terms and conditions</a> and accept our <a target="_BLANK" href="https://www.architecture.com/riba-fee-calculator/privacy-policy">privacy policy</a> to ensure GDPR compliance, as you will be using confidential company information. </p>
                        <div className="checkbox-group mt-40">

                            <label className="checkbox__group">
                                <input type="checkbox" onClick={toggleTermsChecked} onChange={() => { }} checked={checkedTerms} className="checkbox__input" />
                                <i className="checkbox__check"></i>
                                <p className="checkbox__text">I accept the terms and conditions of use and agree to the privacy policy</p>
                            </label>
                        </div>
                        <div className="content-container center mb-50 mt-20" >
                            <button className="mt-40 center button button--opaque" disabled={!checkedTerms} onClick={handleTermsFormSubmit}>Start</button>
                        </div>
                        <div className="form-heading  mt-40 mb-50">
                            <h2 className="form-heading__title">Useful links</h2>
                            <div className="divider divider--dark"></div>
                            <ul>
                                <li><a target="_BLANK" href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/riba-plan-of-work">RIBA Plan of Work</a> </li>
                                <li><a target="_BLANK" href="https://www.architecture.com/riba-contracts">RIBA Contracts</a> </li>
                                <li><a target="_BLANK" href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/business-benchmarking-1">RIBA Benchmarking Survey</a> </li>
                            </ul>
                        </div>
                    </div>
                </article>
            </div>


        </>
    )
});

export default TermsAndConditionsUserForm;