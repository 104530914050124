import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import AddEditMemberForm from '../../organisms/AddEditMemberForm';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../organisms/Loading';
import { history } from '../../../';
import { Member } from '../../../types/member';
import SetupFormSteps from '../../organisms/SetupFormSteps';

interface SetupStaffProps { id: string }

const AddEditMember: React.FC<RouteComponentProps<SetupStaffProps>> = observer(({ match }) => {

    const rootStore = useContext(RootStoreContext);
    const { practice, practiceMember, loadPracticeMember, isLoadingPractice , clearPracticeMemberErrors} = rootStore.practiceStore;

    useEffect(() => {
        clearPracticeMemberErrors()
        if (match.params.id) {
            loadPracticeMember(practice?.members.filter((member) => member.id.toString() === match.params.id)[0])
        } else {
            loadPracticeMember(new Member())
        }

    }, [practice, loadPracticeMember, match.params.id]);

    const handleSubmit = () => {
        history.push('/setup/step3')
    }

    const handleBack = () => {
        history.push('/setup/step3')
    }

    return (
        <>
            <div className="large-content-container mt-20">
                <h1>RIBA Fee Calculator setup</h1>
            </div>
            <div className="narrow-content-container ">

                <SetupFormSteps practice={practice} currentStep={3} />

                {isLoadingPractice ? (
                    <Loading />
                ) : (
                        <AddEditMemberForm 
                        confirmButtonText="Save"
                        cancelButtonText="Cancel" 
                        practiceMember={practiceMember} 
                        handleSubmit={handleSubmit} 
                        handleBack={handleBack} />
                    )}
            </div>
        </>

    )

})

export default AddEditMember;