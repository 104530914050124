import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Loading: React.FC = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);

    return (
        <div className="narrow-content-container narrow-content-container__loading mt-50">
            <div className="load-container loader--rectangles">
                <div className="loader">Loading...</div>
            </div>
        </div>
);
}

export default Loading;
