import React, { FC } from "react";
import { FieldProps } from "formik";

interface FormInputProps {
    type?: string,
    handleChange: (e: any) => any,
    helptext?: string,
    haserror? : string
}

const FormInputTaskName: FC<FormInputProps & FieldProps> = ({
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue, setFieldTouched, handleChange, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    type = "text",
    ...props
}) => (
    <div className="input-wrapper">
        <input type={type} {...field} { ...props }
            onChange={e => {
                setFieldValue(field.name, e.target.value);
                setTimeout(() => setFieldTouched(field.name, true));
                handleChange(e)
            }} className={props.haserror ? 'singleline-text-input singleline-text-input--full-width singleline-text-input--error ' : 'singleline-text-input singleline-text-input--full-width'} />
        {props.helptext && (
            <div className="input-wrapper__tip">{props.helptext}</div>
        )}
        {props.haserror ? (
            <div className="validation-summary-errors">
                <ul>
                    <li>{props.haserror}</li>
                </ul>
            </div>
        ) : null
        }
    </div>
);

export default FormInputTaskName;


