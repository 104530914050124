
import mfa from '../api/mfa';
import { RootStore } from './rootStore';
import { action, observable, runInAction } from 'mobx';

export default class MfaStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable isLoadingMember = false;
    @observable mfa: any | null = null;

    // Implemented a callback instead of an await async function because FireFox did not respect our wishes.
    @action validateMemberMFA = (callback: any, cubeMemberId: number, email: string) => {
        this.isLoadingMember = true;
        
        try {
            let deviceId = '';
            let deviceIdCookie = document.cookie.split('; ').find(row => row.startsWith('deviceId='))
            if(deviceIdCookie) {
                deviceId = deviceIdCookie.split('=')[1];
            }

            // let deviceId = window.localStorage.getItem('deviceId')?.toString() || '';
            // deviceId = deviceId === 'undefined' ? '' : deviceId;
            // console.log("device id: " + deviceId);
            
            
            mfa.ValidateMethods.validate(cubeMemberId, deviceId).then((mfa) => {
                // console.log(mfa.action);
                if(mfa.action) {
                    document.cookie = 'deviceId=' + mfa.deviceId.toString() + '; max-age=2592000;';
                    if(mfa.url){
                        window.location.replace(`${mfa.url}?serviceId=1&deviceId=${mfa.deviceId}`);
                    }
                    return callback();
                }
            });
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.isLoadingMember = false;
            });
        }
    }
}