import React, { useEffect, useState, useContext } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import { history } from '../../..';
import { Project } from '../../../types/project';
import { NavLink } from 'react-router-dom';
import Loading from '../../organisms/Loading';
import { observer } from 'mobx-react-lite';
import { FeeCalculation } from '../../../types/feeCalculation';
import Alert from '../../molecules/Alert';
import { getFeeCalculationStatusName, canEditFeeCalculation, isFeeCalculationOwnerOrAdmin } from '../../../utils/Helpers';
import FeeCalculationActionsDropdown from '../../molecules/FeeCalculationActionsDropdown';
import ProjectActionsDropdown from '../../molecules/ProjectActionsDropdown';
import projectStore from '../../../stores/projectStore';
import { action, observable, runInAction } from 'mobx';
import Pagination from '../../molecules/Pagination';
import { resolve } from 'url';

const Projects: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { isLoadingPractice, getPractice } = rootStore.practiceStore;
    const { project, projects, getProject, getProjects, isLoading, editProject, getProjectCount, projectCount} = rootStore.projectStore;
    const { editFeeCalculation } = rootStore.feeCalculationStore;
    const { validateMemberMFA } = rootStore.mfaStore;
    const { auth } = rootStore.memberStore;
    const { member, getMember, isLoadingMember } = rootStore.memberStore;
    const [expandProject, setExpandedProject] = useState(0);
    const [editProjectName, setEditProjectName] = useState(0);
    const [newProjectName, setNewProjectName] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState<Project[]>([]);
    const [gettingProject, setGettingProject] = useState<boolean>(false);
    const [feeCalculations, setFeeCalculations] = useState<FeeCalculation[]>([]);
    const [searchResultsCount, setSearchResultsCount] = useState<number>(20);
    const [editFeeCalcName, setEditFeeCalcName] = useState(0);
    const [newFeeCalcName, setNewFeeCalcName] = useState<string>("");
    const [showLive, setShowLive] = useState<boolean>(true);
    const [showArchived, setShowArchived] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    let PageSize = 10;

    useEffect(() => {        
        if (auth) {
            getMember().then((loadedMember: any) => {
                if(loadedMember) {
                    
                if (loadedMember && !loadedMember.acceptedTermsAndConditions) {
                    if(loadedMember.accessLevel ==='Admin') {
                        history.push('/setup/accept-terms')
                    }else if(loadedMember.accessLevel ==='Staff'){
                        history.push('/setup/accept-terms-user')
                    }
                }
                
                getPractice().then((localPractice) => {
                    
                    if (localPractice && localPractice.onboarded) {
                        getProjectCount(true, false);
                        getProjects(true, true, false);
                    } else {
                        history.push('/setup/accept-terms')
                    }
                });
                }
            });
        }
    }, [auth, getMember, getPractice, getProjects, validateMemberMFA]);

    // shows live and/ or archived projects
    useEffect(() => {        
        if (auth) {
            getProjectCount(showLive, showArchived).then( res => {
                if(currentPage > Math.ceil(res /PageSize)){
                    if(res == 0){
                        setCurrentPage(1);
                    } else {
                        setCurrentPage(Math.ceil(res /PageSize));
                    }
                }
    
                getProjects(true, showLive, showArchived);
            });
        }
    }, [auth, showLive, showArchived]);

    //Search projects and fee calcs
    useEffect(() => {
        let results: Project[] = [];
        let archive = "Archived";
        if (projects) {
            let lowerSearchTerm = searchTerm.toLowerCase();
            results = projects.filter((project: Project) =>
                project.name.toLowerCase().includes(lowerSearchTerm) ||
                project.number.toLowerCase().includes(lowerSearchTerm) ||
                project.status.toLowerCase().includes(lowerSearchTerm) ||
                (project.archived == true && archive.toLowerCase().includes(lowerSearchTerm)) ||
                project.siteAddress.toLowerCase().includes(lowerSearchTerm) ||
                project.feeCalculationSummaries.filter((feeCalculation: FeeCalculation) => {
                    return (feeCalculation.state?.toLowerCase().includes(lowerSearchTerm) || feeCalculation.lastModifiedBy?.toLowerCase().includes(lowerSearchTerm));
                }).length > 0
            ).slice().sort((b, a) => (a.updated > b.updated ? 1 : -1));
        }
        setSearchResultsCount(results.length)
        setSearchResults(results.slice(PageSize * (currentPage - 1), PageSize * currentPage));
        
    }, [searchTerm, searchResultsCount, projects, currentPage]);


    useEffect(() => {
        if(projects && expandProject > 0 && (!project || project.id != expandProject)){
            setGettingProject(true);
            getProject(expandProject.toString(), true);
        } 
    }, [expandProject]);

    useEffect(() => {
        if(project && project.id > 0){
            setFeeCalculations(project.feeCalculationSummaries);
            setGettingProject(false);
        }
    }, [project]);

    const toggleProjectExpand = (projectId: number) => {
        setExpandedProject(projectId !== expandProject ? projectId : 0);
    }

    const toggleEditProjectName = (projectId: number) => {
        setEditProjectName(projectId !== editProjectName ? projectId : 0);
        if(projectId !== editProjectName){
            setNewProjectName(searchResults.filter(p => p.id === projectId)[0].name)
        }
        else {
            setNewProjectName("");
        }
    }

    
    const toggleEditFeeCalcName = (feeCalcId: number) => {
        setEditFeeCalcName(feeCalcId !== editFeeCalcName ? feeCalcId : 0);
        if(feeCalcId !== editFeeCalcName){
            var feeCalc:FeeCalculation = feeCalculations.filter(f => f.id == feeCalcId)[0];
            var newName = feeCalc.name ?? `${getFeeCalculationStatusName(feeCalc.state)}_${feeCalc.version}`
            setNewFeeCalcName(newName)
        }
        else {
            setNewFeeCalcName("");
        }
    }
    
    const saveNewProjectName = (projectId: number) => {
        if(newProjectName && projectId && projectId > 0) {
            runInAction(() => {
                    var updateProj = searchResults.filter(p => p.id === projectId)[0];
                    updateProj.name = newProjectName;
                    editProject(updateProj)
                        .then(() => {
                            setGettingProject(true);
                            getProject(projectId.toString(), true);
                            setEditProjectName(0);
                            setNewProjectName("");
                        });
                }
            )
        }
    }

    
    const saveNewFeeCalcName = (feeCalcId: number) => {
        if(feeCalcId && feeCalcId > 0) {
            runInAction(() => {
                    var updateFee = feeCalculations.filter(f => f.id === feeCalcId)[0];
                    updateFee.name = newFeeCalcName;
                    editFeeCalculation(updateFee)
                        .then(() => {
                            setGettingProject(true);
                            getProject(expandProject.toString(), true);
                            setEditFeeCalcName(0);
                            setNewFeeCalcName("");
                        });
                }
            )
        }
    }

    const loadMoreResults = () => {
        setSearchResultsCount(searchResultsCount + 20);
    }

    const handleChange = (event: any) => {
        setCurrentPage(1);
        setSearchTerm(event.target.value);
    };

    const handleFreeCalcRefresh = () => {
        getProjectCount(showLive, showArchived).then( res => {
            if(currentPage > Math.ceil(res /PageSize)){
                if(res == 0){
                    setCurrentPage(1);
                } else {
                    setCurrentPage(Math.ceil(res /PageSize));
                }
            }

            getProjects(true, showLive, showArchived);
        });
        
    }

    return (
        <div className="content-container">
            {(isLoadingMember || isLoading || isLoadingPractice || !auth) ? (
                <Loading />
            ) : (
                    <>
                        {member && (
                            <>
                                <div className="form-heading mt-20">
                                    <h2 className="form-heading__title">Projects and fee calculations</h2>
                                    {projects && projects.length > 0 ? (
                                        <p>The table below shows all the fee calculations your practice has prepared for the specific projects and clients. You can see all proposals but can only edit the ones you created. In order to create a new fee calculation for a new client or a new project, you will need to create a new project first.</p>
                                    ) : (
                                            <p>You have no projects yet. Click the button below to get started.</p>
                                        )}
                                </div>
                                <button onClick={() => { history.push('projects/new-project') }} className="button button--opaque mt-20 mb-20">Create a new project</button>

                                <div className="dashboard__panel mt-40 mb-50">

                                    <div className="narrow-content-container mb-50">

                                        <div className="search-bar ">
                                            <input id="search"
                                                value={searchTerm}
                                                onChange={handleChange}
                                                className="search-bar__input"
                                                name=""
                                                placeholder="Search by project name, number, address or status" />
                                            <label>
                                                <i className="search-bar__search-icon">search</i>
                                                <button type="submit" className="search-bar__button">Submit</button>
                                            </label>
                                        </div>

                                    </div>

                                    {searchResults.length === 0 && searchTerm && (
                                        <h2 className="pl-50">Sorry no results found</h2>
                                    )}
                                    <div className="container project-table-checkboxes">
                                        <label className="checkbox-inline mb-20">
                                            <input  type="checkbox" checked={showLive} name="showLive" onChange={(e) => setShowLive(e.target.checked)} className="checkbox__input" />
                                            <i className="checkbox__check"></i>
                                            <p className="checkbox__text">Show live projects</p>
                                        </label>

                                        <label className="checkbox-inline mb-20">
                                            <input  type="checkbox" checked={showArchived} name="showArchived" onChange={(e) => setShowArchived(e.target.checked)} className="checkbox__input" />
                                            <i className="checkbox__check"></i>
                                            <p className="checkbox__text">Include archived projects</p>
                                        </label>
                                    </div>
                                    

                                    {searchResults.length > 0 && searchResults.map((project: Project, projectIndex) => (
                                        <div key={projectIndex} className="content-accordion__option">
                                            <input type="checkbox" aria-controls={project.id.toString()} aria-expanded={project.id === expandProject} onChange={(e) => { }} className="content-accordion__toggle" checked={project.id === expandProject} />
                                            <label id={project.id.toString()} className="content-accordion__title">
                                                <div className="project-table-header">
                                                    <table className="" id="table1" width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td className="project-table-name-col">
                                                                    <span className="project-table-header__greytext">Project name</span>
                                                                    {project.id !== editProjectName ? 
                                                                     (
                                                                        <>
                                                                            <div>
                                                                                <NavLink to={`/projects/${project.id}`}><strong>{project.name}</strong></NavLink>
                                                                                <a className="action-item" onClick={() => { toggleEditProjectName(project.id) }}><i className="action-item__block material-icons project-table-edit">edit</i></a>
                                                                            </div>
                                                                        </>
                                                                        ) : (
                                                                            <> 
                                                                                <div className="pr-20">
                                                                                    <div className="input-wrapper">
                                                                                        <input type="text" value={newProjectName} onChange={(e) => setNewProjectName(e.target.value)}/>  
                                                                                    </div>
                                                                                    <div className="mt-10 project-table-name-actions">
                                                                                        <a className="action-item" onClick={() => { toggleEditProjectName(project.id) }}><i className="action-item__block material-icons project-table-edit">close</i></a>
                                                                                        <a className="action-item" onClick={() => { saveNewProjectName(project.id) }}><i className="action-item__block material-icons project-table-edit">done</i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td onClick={() => { toggleProjectExpand(project.id) }}>
                                                                    <span className="project-table-header__greytext">Project reference</span>
                                                                    <strong>{project.number}</strong>
                                                                </td>
                                                                <td onClick={() => { toggleProjectExpand(project.id) }}>
                                                                    <span className="project-table-header__greytext">Created on</span>
                                                                    <strong>  {new Intl.DateTimeFormat("en-GB", {
                                                                        year: "numeric",
                                                                        month: "2-digit",
                                                                        day: "2-digit"
                                                                    }).format(new Date(project.created))}</strong>
                                                                </td>
                                                                <td onClick={() => { toggleProjectExpand(project.id) }}>
                                                                    <span className="project-table-header__greytext">Project status</span>
                                                                    <strong>{project.archived ? "Archived": project.status}</strong>
                                                                </td>
                                                                <td className="project-table-dropdown">
                                                                    <ProjectActionsDropdown
                                                                        project={project}
                                                                        handleSelection={() => { handleFreeCalcRefresh() }}
                                                                    />
                                                                </td>
                                                                <td className="project-table-toggle" onClick={() => { toggleProjectExpand(project.id) }} title={`expand project ${project.name}`} >
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </label>
                                            <div className="content-accordion__content">
                                                <div className="my-purchases__content mt-20 mb-20">
                                                    <div className="dashboard-data-table">
                                                        {!feeCalculations?.length ? (
                                                             !gettingProject ? (
                                                                <p>There are no fee calculations for this project</p>
                                                            ) : (<p></p>)
                                                        ) : (
                                                                <>
                                                                    {feeCalculations.some((feeCalculation: FeeCalculation) => {
                                                                        return (feeCalculation.inSync === false)
                                                                    }) ? (
                                                                            <tr>
                                                                                <td colSpan={6}>
                                                                                    <Alert text={'Recent updates in your practice overheads or staff salaries may have affected some calculations.'} styleClass='alert alert--information' title='Information' />
                                                                                </td>
                                                                            </tr>
                                                                        ) : null}
                                                                    <table className="fee-calculation-table" id="table1" width="100%">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: '55px' }}></th>
                                                                                <th scope="col">Fee calculations</th>
                                                                                <th scope="col">Edited by</th>
                                                                                <th scope="col">Edited on</th>
                                                                                <th scope="col">Fee</th>
                                                                                <th scope="col">Status</th>
                                                                                <th style={{ width: '310px' }} scope="col">Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {feeCalculations.slice().sort((b, a) => (a?.lastModified > b?.lastModified ? 1 : -1)).map((feeCalculation, feeCalcIndex) => (
                                                                                <tr key={feeCalculation.id}>
                                                                                    <td>{!feeCalculation.inSync && (
                                                                                        <span className="material-icons">
                                                                                            report_problem
                                                                                        </span>
                                                                                    )}</td>
                                                                                    {feeCalculation.id != editFeeCalcName ? 
                                                                                    (
                                                                                        <>
                                                                                            <div className="pt-10">
                                                                                                <NavLink to={`/projects/${project.id}/fee-calculation/${feeCalculation.id}`}>
                                                                                                    {feeCalculation.name != "" ? feeCalculation.name : `${getFeeCalculationStatusName(feeCalculation.state)}_${feeCalculation.version}`}
                                                                                                </NavLink>
                                                                                                <a className="action-item" onClick={() => { toggleEditFeeCalcName(feeCalculation.id as number) }}><i className="action-item__block material-icons project-table-edit">edit</i></a>
                                                                                            </div>
                                                                                        </>
                                                                                        ) : (
                                                                                            <> 
                                                                                                <div className="pt-10 pb-10 pr-20">
                                                                                                    <div className="input-wrapper">
                                                                                                        <input type="text" value={newFeeCalcName} onChange={(e) => setNewFeeCalcName(e.target.value)}/>  
                                                                                                    </div>
                                                                                                    <div className="mt-10 fee-calculation-name-actions">
                                                                                                        <a className="action-item" onClick={() => { toggleEditFeeCalcName(feeCalculation.id as number) }}><i className="action-item__block material-icons project-table-edit">close</i></a>
                                                                                                        <a className="action-item" onClick={() => { saveNewFeeCalcName(feeCalculation.id as number) }}><i className="action-item__block material-icons project-table-edit">done</i></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    <td>{feeCalculation?.lastModifiedBy}</td>
                                                                                    <td>{feeCalculation?.lastModified ? (

                                                                                        new Intl.DateTimeFormat("en-GB", {
                                                                                            year: "numeric",
                                                                                            month: "2-digit",
                                                                                            day: "2-digit"
                                                                                        }).format(new Date(feeCalculation?.lastModified))

                                                                                    ) : '-'}</td>
                                                                                    <td><strong>£{feeCalculation?.totalProjectFee?.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</strong>
                                                                                        <span className="data-table__greytext">excl. VAT</span>
                                                                                    </td>
                                                                                    <td>{feeCalculation?.state}</td>
                                                                                    <td><FeeCalculationActionsDropdown
                                                                                        feeCalculation={feeCalculation}
                                                                                        handleSelection={() => { handleFreeCalcRefresh() }}
                                                                                        projectName={project.name}
                                                                                        canEdit={canEditFeeCalculation(feeCalculation, member)}
                                                                                        isOwnerOrAdmin={isFeeCalculationOwnerOrAdmin(feeCalculation, member)}
                                                                                    /></td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {(Math.ceil(searchResultsCount / PageSize) > 1)&&(
                                        <div className="center mt-20 mb-50">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={searchResultsCount}
                                                pageSize={PageSize}
                                                onPageChange={(page: number) => setCurrentPage(page)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
        </div>
    )
});

export default Projects;
