import React, { useContext, useLayoutEffect } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { NavLink, useLocation } from 'react-router-dom';
import ContactUs from '../organisms/ContactUs'

type ConfirmPracticeDetailsFormsProps = {
    handleSubmit?: () => any,
    title?: string,
    summary?: string
}

const ConfirmPracticeDetailsForm: React.FC<ConfirmPracticeDetailsFormsProps> = (props) => {
    const rootStore = useContext(RootStoreContext);
    const { practice } = rootStore.practiceStore;
    const { handleSubmit, title, summary } = props;
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <span className="mt-20 mb-20">
                <NavLink to="/settings/edit-practice" className="mt-20 mb-40 button button--opaque">Edit Practice Details</NavLink>
            </span>

            <div className="form-heading">
                {title && (
                    <h2 className="form-heading__title">{title} </h2>
                )}
                {props.children}
            </div>

            <div className="dashboard-data-table mt-50">
                <table className="data-table" width="100%">
                    <thead>
                        <tr>
                            <th scope="col">Practice name</th>
                            <th scope="col">Practice address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{practice?.name}</td>
                            <td>{practice?.addressLineOne}{practice?.addressLineTwo ? `, ${practice?.addressLineTwo}` : ""}{practice?.addressLineThree ? `, ${practice?.addressLineThree}` : ""}{practice?.postcode ? `, ${practice?.postcode}` : ""}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {handleSubmit && (
                <div className="join-riba-form-content__actions">
                    <button onClick={() => { handleSubmit() }} className="button button--opaque ">Confirm and continue</button>
                </div>
            )}

                <ContactUs/>

        </>
    )
};

export default ConfirmPracticeDetailsForm;