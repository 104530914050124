import { FeeCalculation } from "../types/feeCalculation";
import { Member } from "../types/member";
import { FeeCalculationStates } from "./Constants";

//TODO DELETE?
export const getFeeCalculationStatusName = (feeCalculationState: string | undefined) => {
    if (feeCalculationState) {
        return FeeCalculationStates.filter(state => state.id === feeCalculationState)[0] !== undefined ? FeeCalculationStates.filter(state => state.id === feeCalculationState)[0].name : '';
    }
}

export const canEditFeeCalculation = (feeCalculation: FeeCalculation, member: Member): boolean => {
    let canEdit = false;
    if (member) {
        if (member.accessLevel === 'Admin' && feeCalculation.state === 'Draft') { canEdit = true };
        if (member.id === feeCalculation.createdBy && feeCalculation.state === 'Draft') { canEdit = true };
    }
    return canEdit
}

export const isFeeCalculationOwnerOrAdmin = (feeCalculation: FeeCalculation, member: Member): boolean => {
    return (feeCalculation.createdBy === member.id || member.accessLevel === 'Admin')
}