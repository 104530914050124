import React, { useState } from 'react';
import FormInputTaskName from '../molecules/FormInputTaskName';
import { observer } from 'mobx-react-lite';
import { Field, getIn, FieldArray } from "formik";
import { FeeCalculationStageTask } from '../../types/feeCalculationStageTask';
import { FeeCalculationStage } from '../../types/feeCalculationStage';
import { runInAction } from 'mobx';
import FeeCalculationStaffCostForm from './FeeCalculationStaffCostForm';
import { TaskMember } from '../../types/member';
import { FixedCost } from '../../types/fixedCost';
import FeeCalculationFixedCostForm from './FeeCalculationFixedCostForm';
import { StageActivity } from '../../types/stageActivity';

type FeeCalculationStageTaskFormProps = {
    values: any;
    handleChange: (e: any) => any;
    handleSubmit: () => any;
    addTaskToDelete: (taskId: any) => any,
    addStaffCostToDelete: (staffCostId: any) => any,
    addFixedCostToDelete: (fixedCostId: any) => any,
    removeTask: (e: any) => any,
    feeCalculationForm: any,
    feeCalculationStageForm: any,
    feeCalculationStage: FeeCalculationStage;
    stageIndex: number;
    feeCalculationStageTask: FeeCalculationStageTask;
    taskIndex: number;
    canEdit: boolean;
    formInSync?: boolean
}




const FeeCalculationStageTaskForm: React.FC<FeeCalculationStageTaskFormProps> = observer((props) => {

    
    const { values, feeCalculationStage, feeCalculationStageForm, feeCalculationStageTask, stageIndex, taskIndex, feeCalculationForm, addTaskToDelete, addStaffCostToDelete, addFixedCostToDelete, removeTask, handleChange, handleSubmit, canEdit } = props;
    const [showTextBox, setShowTextBox] = useState<boolean>(!!feeCalculationStageTask.name);
    return (
        <div className="fee-calculator-task-form">
            <h3>{feeCalculationStage.stage.stageId}.{taskIndex + 1} Task:</h3>
            <div className="form-input-container form-input-container--task ml-20 mr-25 pt-10">
                <div className="form-input-container__input--full-width">
                    {(!showTextBox && feeCalculationStage.stageActivities.length > 0) ? 
                        (
                            <select className="dropdown-input word-wrap" onChange={(e) => { runInAction(() => {feeCalculationStageTask.name = feeCalculationStage.stageActivities[Number(e.target.value)].name})}}>
                                <option className="word-wrap"  value="">Select an activity</option>
                                {feeCalculationStage.stageActivities.map((activity : StageActivity, activityIndex) => (
                                    <option className="word-wrap" value={activityIndex} >{activity.name}</option>
                                ))}
                            </select>
                        ) : (
                            <Field disabled={!canEdit} component={FormInputTaskName} haserror={getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].name`)} name={`feeCalculationStageTasks[${taskIndex}].name`} id={`feeCalculationStageTasks[${taskIndex}].name`} type="text" value={feeCalculationStageTask.name} placeholder="" />
                        )
                    }   
                </div>

                <div className="fee-calculator-task-buttons">
                    <button type="button" title="edit task" onClick={() => { showTextBox ? setShowTextBox(false) : setShowTextBox(true) }} className={feeCalculationStage.stageActivities.length > 0 ? "show" : "hide"}><i className="material-icons">{showTextBox ? "close" : "edit"}</i></button>
                    {canEdit && (<button type="button" title="delete task" onClick={() => { runInAction(() => { removeTask(taskIndex); if (feeCalculationStageTask.id > 0) { addTaskToDelete(feeCalculationStageTask.id) }; handleSubmit() }) }}><i className="material-icons">delete</i></button>)}
                </div>
            </div>
            <h3>Add staff cost</h3>
            <FieldArray name={`feeCalculationStageTasks[${taskIndex}].staffCosts`}>{({ push, remove }) => (
                <>
                    {feeCalculationStageTask.staffCosts && feeCalculationStageTask.staffCosts.map((staffCost : TaskMember, staffCostIndex) => (
                        <FeeCalculationStaffCostForm
                            feeCalculationForm={feeCalculationForm}
                            feeCalculationStageForm={feeCalculationStageForm}
                            key={staffCostIndex}
                            addStaffCostToDelete={addStaffCostToDelete}
                            values={values}
                            handleChange={(e) => {
                                handleChange(e);
                            }}
                            handleSubmit={handleSubmit}
                            feeCalculationStage={feeCalculationStage}
                            stageIndex={stageIndex}
                            feeCalculationStageTask={feeCalculationStageTask}
                            taskIndex={taskIndex}
                            feeCalculationStaffCost={staffCost}
                            staffCostIndex={staffCostIndex}
                            canEdit={canEdit}
                            removeStaffCost={(staffCostId: number) => { remove(staffCostId); handleSubmit() }}
                            formInSync={props.formInSync}
                        ></FeeCalculationStaffCostForm>
                    ))}

                    {canEdit && (<button type="button" className="button ml-20 mt-10 mb-40" onClick={() => { push(new TaskMember()); handleSubmit() }}>Add {feeCalculationStageTask.staffCosts.length > 0 && ('more ')}staff</button>)}
                </>
            )}
            </FieldArray>
            <h3 className="mt-40">Add fixed cost</h3>
            <FieldArray name={`feeCalculationStageTasks[${taskIndex}].fixedCosts`}>{({ push, remove }) => (
                <>
                    {feeCalculationStageTask.fixedCosts && feeCalculationStageTask.fixedCosts.map((fixedCost, fixedCostIndex) => (

                        <FeeCalculationFixedCostForm
                            feeCalculationForm={feeCalculationForm}
                            feeCalculationStageForm={feeCalculationStageForm}
                            key={fixedCostIndex}
                            addFixedCostToDelete={addFixedCostToDelete}
                            handleSubmit={handleSubmit}
                            taskIndex={taskIndex}
                            stageIndex={stageIndex}
                            feeCalculationFixedCost={fixedCost}
                            fixedCostIndex={fixedCostIndex}
                            canEdit={canEdit}
                            removeFixedCost={(fixedCostId: number) => { remove(fixedCostId); handleSubmit() }}
                        ></FeeCalculationFixedCostForm>
                    ))}
                    {canEdit && (<button type="button" className="button ml-20 mt-10 mb-40" onClick={() => { push(new FixedCost()); handleSubmit() }}>Add {feeCalculationStageTask.fixedCosts.length > 0 ? ('more costs') : ('fixed cost')}</button>)}
                </>
            )}
            </FieldArray>
        </div>


    )
});

export default FeeCalculationStageTaskForm;