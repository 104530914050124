import React, { useContext } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Field, FieldArray, getIn } from "formik";
import { FeeCalculationStageTask } from '../../types/feeCalculationStageTask';
import { FeeCalculationStage } from '../../types/feeCalculationStage';
import { runInAction } from 'mobx';
import { Member, TaskMember } from '../../types/member';
import NumberFormat from "react-number-format";
import ReactTooltip from 'react-tooltip';

type FeeCalculationStageTaskFormProps = {
    values: any;
    feeCalculationForm: any;
    feeCalculationStageForm: any;
    handleChange: (e: any) => any;
    handleSubmit: () => any;
    addStaffCostToDelete: (taskId: any) => any,
    removeStaffCost: (e: any) => any,
    feeCalculationStage: FeeCalculationStage;
    feeCalculationStageTask: FeeCalculationStageTask;
    feeCalculationStaffCost: TaskMember;
    stageIndex: number;
    taskIndex: number;
    staffCostIndex: number,
    canEdit: boolean,
    formInSync?: boolean,
}


const FeeCalculationStaffCostForm: React.FC<FeeCalculationStageTaskFormProps> = observer((props) => {
    const { feeCalculationForm, handleChange, handleSubmit, addStaffCostToDelete, removeStaffCost, feeCalculationStaffCost, stageIndex, taskIndex, staffCostIndex, canEdit } = props;
    const rootStore = useContext(RootStoreContext);
    const { practice } = rootStore.practiceStore;

    return (
        <>
            <div className="fee-calculator-staff-cost__form ml-10 mr-25">
                <div className="form-input-container form-input-container--job-title">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">Job title:</div>
                            <input disabled={!canEdit} type="hidden" name={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].id`} id={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].id`} onChange={handleChange} value={feeCalculationStaffCost.id} />
                            <input disabled={!canEdit} type="hidden" name={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].costRatePerHour`} id={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].costRatePerHour`} onChange={handleChange} value={feeCalculationStaffCost.costRatePerHour} />
                        </div>
                    </div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            <FieldArray name={`feeCalculationStageTasks[${taskIndex}].staffCosts`}>{({ replace }) => (
                                <>
                                    <Field disabled={!canEdit} as="select"
                                        id={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].jobTitle`}
                                        className="dropdown-input"
                                        name={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].jobTitle`}
                                        onChange={(e: any) => {
                                            const jobTitle = e.target.value;
                                            const hours = feeCalculationStaffCost?.hours | 0;
                                            const id: number = Number(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex].id);
                                            replace(staffCostIndex, (Object.assign(new TaskMember()), { member: new TaskMember(), jobTitle, hours, id, }));
                                        }}>
                                        <option value="">Select</option>
                                        {
                                            Array.from(new Set(practice?.members.filter((member: Member) => { return member.billableStatus === "Billable" && member.active })
                                                .slice()
                                                .sort((a, b) => (a.jobTitle > b.jobTitle ? 1 : -1))
                                                .map((member: Member) => member.jobTitle))).map((jobTitle: string) => {
                                                    return (
                                                        <option key={jobTitle} value={jobTitle}>{jobTitle}</option>

                                                    )
                                                })
                                        }
                                    </Field>
                                </>
                            )}
                            </FieldArray>
                        </div>
                    </div>
                </div>
                <div className="form-input-container form-input-container--staff-name">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">Staff name:</div>
                        </div>
                    </div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            <FieldArray name={`feeCalculationStageTasks[${taskIndex}].staffCosts`}>{({ replace }) => (
                                <>
                                    <Field disabled={!canEdit} as="select"
                                        id={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].member.id`}
                                        className={getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].member.id`) ? 'dropdown-input dropdown-input--error' : 'dropdown-input'}
                                        name={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].member.id`}
                                        onChange={(e: any) => {
                                            runInAction(() => {
                                                const selectedStaffMember = practice?.members.filter((member: Member) => { return member.id === Number(e.target.value) })[0];
                                                const hours = feeCalculationStaffCost?.hours | 0;
                                                const costRatePerHour = selectedStaffMember?.costRatePerHour;
                                                const id: number = Number(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex].id);
                                                const jobTitle = selectedStaffMember?.jobTitle;
                                                replace(staffCostIndex, (Object.assign(new TaskMember()), { member: selectedStaffMember, hours, id, jobTitle, costRatePerHour }));
                                                // handleChange(e);
                                            })
                                        }}>
                                        <option value="">Select</option>
                                        {practice?.members.filter((member: Member) => {
                                            if (feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.jobTitle) {
                                                return (member.billableStatus === "Billable" && member.active && member.jobTitle === (feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.jobTitle || ''))
                                            } else {
                                                return (member.billableStatus === "Billable" && member.active)
                                            }
                                        })
                                            .slice()
                                            .sort((a, b) => (a.firstName + a.lastName).toLowerCase() > (b.firstName + b.lastName).toLowerCase() ? 1 : -1)
                                            .map((member: Member) => {
                                                return (
                                                    <option key={member.id} value={member.id}>{member.firstName} {member.lastName}</option>

                                                )
                                            })}
                                    </Field>
                                    {getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].member.id`) && <div className="validation-summary-errors">
                                        <ul>
                                            <li>{getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].member.id`)}</li>
                                        </ul>
                                    </div>
                                    }
                                </>
                            )}
                            </FieldArray>
                        </div>
                    </div>
                </div>
                <div className="form-input-container  form-input-container--hours">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">
                                <div className="field-help tooltip-trigger" data-tip="React-tooltip" data-for="staffCostHours">
                                    <div className="field-help__icon" >
                                        <span className="material-icons info-icon ">info</span>
                                    </div>
                                </div>
                                Hour:
                                </div>
                            <ReactTooltip
                                type="light"
                                place="right"
                                effect="solid"
                                className="react-tooltip"
                                border={true}
                                id='staffCostHours'
                                textColor='#333f48' backgroundColor='#f9f9f9' borderColor='#f2f2f2'>
                                <strong>Staff Cost Hours</strong><br />
                                Time spent on tasks must be recorded as full or half hours
                            </ReactTooltip>
                        </div>
</div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            <div className="input-wrapper">
                                <FieldArray name={`feeCalculationStageTasks[${taskIndex}].staffCosts`}>{({ replace }) => (
                                    <input disabled={!canEdit} type="number"
                                        min={0}
                                        step={0.5}
                                        id={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].hours`}
                                        name={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].hours`}
                                        value={feeCalculationStaffCost?.hours > 0 ? feeCalculationStaffCost?.hours : ''}
                                        onKeyDown={(e: any) => {
                                            if (e.keyCode === 189) {
                                                e.preventDefault()
                                            }
                                        }}
                                        onChange={(e: any) => {
                                            if(e.target.value % 1 != 0){
                                                e.target.value = (Math.round(e.target.value / 0.5) * 0.5).toLocaleString(navigator.language, { minimumFractionDigits: 1 })
                                            }
                                            runInAction(() => {
                                                const hours = Number(e.target.value);
                                                const costRatePerHour = feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.costRatePerHour;
                                                const staffCost = Math.round(Number(Math.round(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.member?.costRatePerHour) * e.target.value));
                                                const id: number = Number(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex].id);
                                                const jobTitle = feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.jobTitle;
                                                replace(staffCostIndex, (Object.assign(new TaskMember()), { member: feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.member, hours, staffCost, id, jobTitle, costRatePerHour }));
                                                handleChange(e)
                                            })
                                        }} className="singleline-text-input singleline-text-input" />
                                )}
                                </FieldArray>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="form-input-container  form-input-container--staff-cost-total">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">Staff cost:</div>
                        </div>
                    </div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            <NumberFormat thousandSeparator
                                style={{ textAlign: 'right' }}
                                allowEmptyFormatting={true}
                                prefix='£'
                                disabled={true}
                                id={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].staffCost`}
                                name={`feeCalculationStageTasks[${taskIndex}].staffCosts[${staffCostIndex}].staffCost`}
                                value={Math.round(Number(Math.round(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.costRatePerHour) * (feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.hours)))}
                                className={`singleline-text-input ${!props.formInSync
                                    && canEdit 
                                    && feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.costRatePerHour !== (practice?.members.find(x => x.id === feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.member.id)?.costRatePerHour ? practice?.members.find(x => x.id === feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks?.[taskIndex].staffCosts?.[staffCostIndex]?.member.id)?.costRatePerHour : 0) ? 
                                    "singleline-text-input--error" : null}`} />
                        </div>
                    </div>
                </div>
                <div className="form-input-container form-input-container--remove-item">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">&nbsp;</div>
                        </div>
                    </div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            {canEdit && (<button title="delete staff cost" onClick={() => { runInAction(() => { removeStaffCost(staffCostIndex); if (feeCalculationStaffCost.id > 0) { addStaffCostToDelete(feeCalculationStaffCost.id) }; handleSubmit() }) }}><i className="material-icons">delete</i></button>)}
                        </div>
                    </div>

                </div>

            </div>

        </>



    )
});

export default FeeCalculationStaffCostForm;