import React, { useContext } from 'react';
import { Link, NavLink, Route, Redirect } from 'react-router-dom';
import { RootStoreContext } from '../../stores/rootStore';
import { Practice } from '../../types/practice';
import { Member } from '../../types/member';

type StepsProps = {
    currentStep: number,
    practice: any
}

const SetupFormSteps: React.FC<StepsProps> = (props) => {

    const { currentStep, practice } = props;


    return (
        <div className="form-step">
            <div className="form-step__holder">

                <div className="form-step-item">
                    <div className={`step-circle  ${currentStep > 0 ? "step-circle--current" : "" || practice?.members.some((member: Member) => member.totalSalaryPackage > 0)}`}>
                        {currentStep === 1 && (
                            <div className="step-circle__content">
                                1
                            </div>
                        )}
                        {currentStep > 1 && (
                            <NavLink to='/setup/step1' className="step-circle__content">
                                <i className="material-icons">done</i>
                            </NavLink>
                        )}


                    </div>
                    <div className="step-description step-description___setup">
                        <h3 className="step-description__title">
                            Check practice details
                    </h3>
                        <div className="step-description__content">
                            &nbsp;
                    </div>
                    </div>
                </div>

                <div className="form-step__divider">
                    <div className="divider "></div>
                </div>
            </div>
            <div className="form-step__holder">

                <div className="form-step-item">
                    <div className={`step-circle  ${currentStep > 1 ? "step-circle--current" : ""}`}>

                        {currentStep === 2 && (
                            <div className="step-circle__content">
                                2
                            </div>
                        )}
                        {currentStep > 2 || practice?.totalAnnualOverheadCosts > 0 ? (
                            <NavLink to='/setup/step2' className="step-circle__content">
                                <i className="material-icons">done</i>
                            </NavLink>
                        ) : (
                                <div className="step-circle__content">
                                    2
                                </div>
                            )}

                    </div>
                    <div className="step-description step-description___setup">
                        <h3 className="step-description__title">
                            Add company overheads
                    </h3>
                        <div className="step-description__content">
                            &nbsp;
                    </div>
                    </div>
                </div>

                <div className="form-step__divider">
                    <div className="divider "></div>
                </div>
            </div>
            <div className="form-step__holder">

                <div className="form-step-item">
                    <div className={`step-circle  ${currentStep > 2 ? "step-circle--current" : "" || practice?.members.some((member: Member) => member.totalSalaryPackage > 0)}`}>

                        {currentStep === 3 && (
                            <div className="step-circle__content">
                                3
                            </div>
                        )}
                        {currentStep > 3 || practice?.members.some((member: Member) => member.totalSalaryPackage > 0) ? (
                            <NavLink to='/setup/step3' className="step-circle__content">
                                <i className="material-icons">done</i>
                            </NavLink>
                        ) : (
                                <div className="step-circle__content">
                                    3
                                </div>
                            )}

                    </div>
                    <div className="step-description step-description___setup">
                        <h3 className="step-description__title">
                            Add staff members' salary details
                    </h3>
                        <div className="step-description__content">
                            &nbsp;
                    </div>
                    </div>
                </div>

                <div className="form-step__divider">
                    <div className="divider "></div>
                </div>
            </div>
            <div className="form-step__holder">

                <div className="form-step-item">
                    <div className={`step-circle  ${currentStep > 3 ? "step-circle--current" : ""}`}>

                        <div className="step-circle__content">
                            {currentStep > 4 || practice?.members.some((member: Member) => member.totalSalaryPackage > 0) ? (
                                <NavLink to='/setup/step4' className="step-circle__content">
                                    <>4</>
                                </NavLink>
                            ) : (
                                    <>4</>
                                )
                            }
                        </div>

                    </div>
                    <div className="step-description step-description___setup">
                        <h3 className="step-description__title">
                            Confirm and complete
                    </h3>
                        <div className="step-description__content">
                            &nbsp;
                    </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SetupFormSteps;
