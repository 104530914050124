import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { Project } from '../../types/project';
import Modal from 'react-modal';
import Alert from './Alert';
import { runInAction } from 'mobx';
import { NonEditableStates } from '../../utils/Constants';
import { history } from '../..';
import { FormikProps } from 'formik';


type ProjectActionsDropdownProps = {
    project: Project,
    handleSelection: () => any,
}

const ProjectActionsDropdown: React.FC<ProjectActionsDropdownProps> = (props) => {

    const { project, handleSelection, } = props;
    const rootStore = useContext(RootStoreContext);
    const { isLoading, getProject, editProject, deleteProject, getProjects } = rootStore.projectStore;
    const [value, setValue] = useState('');

    //modals
    const [loading, setLoading] = useState(false);
    const [archiveModelIsOpen, setArchiveModelIsOpen] = useState(false);
    const [deleteModelIsOpen, setDeleteModelIsOpen] = useState(false);
    
    const [state, setState] = useState('');

    const handleChange = (e: any) => {
        switch (e.target.value) {
            case "EDIT_PROJECT":
                history.push(`/projects/${project.id}`)
                break;
            case "ARCHIVE_PROJECT":
                setArchiveModelIsOpen(true);
                break;
            case "RESTORE_PROJECT":
                restoreProject();
                break;
            case "DELETE_PROJECT":
                setDeleteModelIsOpen(true);
                break;
        }
        setValue('')
    }


    const archiveProject = () => {
        runInAction(() => {
            project.archived = true;
            editProject(project)
            .then(() => handleSelection());
        })
    }

    const restoreProject = () => {
        runInAction(() => {
            project.archived = false;
            editProject(project)
            .then(() => handleSelection());
        })
    }

    return (
        <>
            <div className="input-wrapper">
                <select value={value} onChange={handleChange} className="dropdown-input ">
                    <option value="" >Select an action</option>
                    <option value="EDIT_PROJECT" className={project.archived ? "hide" : "show"}>Open project details</option>
                    <option value="ARCHIVE_PROJECT" className={project.archived ? "hide" : "show"}>Archive project</option>
                    <option value="RESTORE_PROJECT" className={!project.archived ? "hide" : "show"}>Restore project</option>
                    <option value="DELETE_PROJECT" className={!project.archived ? "hide" : "show"}>Delete project</option>
                </select>
            </div>

            <Modal
                isOpen={archiveModelIsOpen}
                onRequestClose={() => setArchiveModelIsOpen(false)}
                overlayClassName="Overlay"
                contentLabel="Archive project"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    {loading ? (
                        <div className="load-container loader--rectangles">
                            <div className="loader">Loading...</div>
                        </div>
                    ) : (
                            <>
                                <span onClick={() => setArchiveModelIsOpen(false)} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                <h1 className="dashboard-modal__title">Archive project</h1>
                                <div className="whitespace whitespace--single"></div>
                                <Alert title='Warning' styleClass='alert alert--warning' text='Archiving a project will remove the project and its fee calculations from the projects list. The projects data can be recovered by contacting RIBA support' />
                                <p className="dashboard-modal__subtext">You are about to archive <strong>{project.name}</strong>. Are you sure you would like to proceed?</p>
                                <div className="whitespace whitespace--single"></div>
                                <div className="cta-band__buttons">
                                    <button onClick={() => setArchiveModelIsOpen(false)} className="button  cta-band__button">Cancel</button>
                                    <button onClick={() => archiveProject()} className="button button--opaque cta-band__button">Confirm archive</button>
                                </div>
                            </>
                        )}
                </div>
            </Modal>
            <Modal
                isOpen={deleteModelIsOpen}
                onRequestClose={() => setDeleteModelIsOpen(false)}
                overlayClassName="Overlay"
                contentLabel="Permanently delete this project"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    {loading ? (
                        <div className="load-container loader--rectangles">
                            <div className="loader">Loading...</div>
                        </div>
                    ) : (
                            <>
                                <span onClick={() => setDeleteModelIsOpen(false)} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                <h1 className="dashboard-modal__title">Permanently delete this project</h1>
                                <div className="whitespace whitespace--single"></div>
                                <Alert title='Warning' styleClass='alert alert--warning' text='Archiving a project will remove the project and its fee calculations from the projects list. The projects data can be recovered by contacting RIBA support' />
                                <p className="dashboard-modal__subtext">You are about to delete this archived project. You will not be able to undo this.</p>
                                <div className="whitespace whitespace--single"></div>
                                <div className="cta-band__buttons">
                                    <button onClick={() => setDeleteModelIsOpen(false)} className="button  cta-band__button">Cancel</button>
                                    <button onClick={() => { deleteProject(project.id) }} className="button button--opaque cta-band__button">Delete</button>
                                </div>
                            </>
                        )}
                </div>
            </Modal>
        </>
    );
}

export default ProjectActionsDropdown;
