import React from 'react';
import { NavLink } from 'react-router-dom';

type HelpSideNavigationProps = {
    currentPage: number
}

const HelpSideNavigation: React.FC<HelpSideNavigationProps> = (props) => {

    const { currentPage } = props;

    return (
        <div className="sidebar">
            <div className="sidebar__content">
                <div className="sidebar__navigation">
                    <ul className="sidebar__accordion">
                        <ul>
                            <li className={currentPage === 1 ? 'sidebar--active' : ''}><NavLink to='/help'><span>General information</span></NavLink></li>
                            <li className={currentPage === 2 ? 'sidebar--active' : ''}><NavLink to='/help/fee-calculation-overview'><span>Fee calculation overview</span></NavLink></li>
                            <li className={currentPage === 3 ? 'sidebar--active' : ''}><NavLink to='/help/tutorials-and-articles'><span>Tutorials and articles</span></NavLink></li>
                        </ul>
                    </ul>
                </div >
            </div >
        </div >
    )
}

export default HelpSideNavigation;
