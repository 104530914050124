import React, { useEffect, useContext } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import PracticeMembersList from '../../organisms/PracticeMembersList';
import { observer } from 'mobx-react-lite';
import SettingsSideNavigation from '../../organisms/SettingsSideNavigation';
import Loading from '../../organisms/Loading';
import { history } from '../../../';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';
import { MemberAccessLevels } from '../../../utils/Constants';

const StaffMembers: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { getPractice, isLoadingPractice, unsetPractice } = rootStore.practiceStore;
    const { member } = rootStore.memberStore

    useEffect(() => {
        let isLoaded = false;
        
        if (member?.accessLevel !== MemberAccessLevels.ADMIN) { 
            history.push('/projects');
        }

        return () => {
            isLoaded = true;   // clean up function
        };

    }, [member]);

    useEffect(() => {
        let isLoaded = false;
        getPractice().finally(() => {
        })
    }, [getPractice]);

    useEffect(() => {
        return () => {
            unsetPractice();
        };

    }, [unsetPractice]);

    const handleReload = () => {
        getPractice();
    }

    return (
        <>
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <SideNavigationMobile />
                    <SettingsSideNavigation currentPage={3} />
                    {isLoadingPractice ? (
                        <Loading />
                    ) : (
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30">
                                            <div className="form-heading">
                                                <h2 className="form-heading__title">Staff members</h2>
                                                <p>Add or edit staff details, salary information, working hours and rate. This will let you calculate hourly and daily staff costs when making fee calculations. Check our guidance notes for help calculating billable time.</p><p><strong>Please make sure you provide salary details for all staff members (billable and non-billable). You won’t be able to add staff members marked as ‘incomplete’ to your fee calculations.</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-30 mr-30">
                                        <PracticeMembersList
                                            isSettingsPage={true}
                                            title="Staff members"
                                            handleBack={() => { }}
                                            handleSubmit={() => {}}
                                            handleReload={handleReload}>
                                        </ PracticeMembersList>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )

})



export default StaffMembers;