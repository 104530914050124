import api from '../api/api';
import { RootStore } from './rootStore';
import { Project } from '../types/project';
import { action, observable, runInAction } from 'mobx';
import { number } from 'yup';

export default class projectStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable project: Project | null = null;
    @observable projects: Array<Project> | null = [];
    @observable projectCount: number = 0;
    @observable isLoading = false;

    @action getProjectCount = async (live: boolean = true, archived: boolean = false) => {
        this.isLoading = true;
        try {
            const projectCount: number = await api.ProjectMethods.getProjectCount(live, archived);
            runInAction(() => {
                this.projectCount = projectCount;
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
            });
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
            return this.projectCount;
        }
    } 

    @action getProjects = async (slim: boolean = false, live: boolean = true, archived: boolean = false) => {
        this.isLoading = true;
        try {
            const projects: Array<Project> = await api.ProjectMethods.getProjects(slim, live, archived);
            runInAction(() => {
                this.projects = projects;
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
            });
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
            return this.projects;
        }
    }

    @action getProject = async (projectId: string, suppressLoading: boolean = false) => {
        if (!suppressLoading) this.isLoading = true; 
        if (projectId) {
            try {
                const project: Project = await api.ProjectMethods.getProject(projectId);
                runInAction(() => {
                    // TODO: REMOVE THIS CONSTRUCTOR DEMO
                    this.project = new Project(project);
                    if (!suppressLoading) this.isLoading = false; 
                });
            } catch (error) {
                runInAction(() => {
                    if (!suppressLoading) this.isLoading = false; 
                });
                console.log(error);
            } finally {
                runInAction(() => {
                    if (!suppressLoading) this.isLoading = false; 
                });
            }
        } else {
            const project = new Project();
            runInAction(() => {
                this.project = project;
                if (!suppressLoading) this.isLoading = false; 
            });
        }
    }


    @action createProject = async (project: Project) => {
        if (project) {
            this.isLoading = true;
            try {
                const createdProject: Project = await api.ProjectMethods.create(project);
                runInAction('Create Project.', () => {
                    this.project = createdProject;
                    this.isLoading = false;
                });

                return this.project;
            } catch (error) {
                console.log(error);
                runInAction(() => {
                    this.isLoading = false;
                });
            } finally {
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        }
    }

    @action editProject = async (project: Project) => {
        this.isLoading = true;
        try {
            await api.ProjectMethods.edit(project);

            runInAction('Edit practice.', () => {
                this.project = project;
                this.isLoading = false;
            });

        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action deleteProject = async (projectId: number | undefined) => {
        if (projectId) {
            this.isLoading = true;
            try {
                await api.ProjectMethods.delete(projectId).then(async () => {
                        const projects: Array<Project> = await api.ProjectMethods.getProjects(false, false, true);
                        runInAction(() => {
                            this.projects = projects;
                            this.isLoading = false;
                        });
                    }
                )
                
            } catch (error) {
                console.log(error);
                runInAction(() => {
                    this.isLoading = false;
                    });
            } finally {
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        }
    }


}