import React from 'react';
import { NavLink } from 'react-router-dom';

type SettingsSideNavigationProps = {
    currentPage: number
}

const SettingsSideNavigation: React.FC<SettingsSideNavigationProps> = (props) => {

    const { currentPage } = props;

    return (
        <div className="sidebar">
            <div className="sidebar__content">
                <div className="sidebar__navigation">
                    <ul className="sidebar__accordion">
                        <ul>
                            <li className={currentPage === 1 ? 'sidebar--active' : ''}><NavLink to='/settings/practice-details'><span>Practice details</span></NavLink></li>
                            <li className={currentPage === 2 ? 'sidebar--active' : ''}><NavLink to='/settings/annual-overheads'><span>Annual overhead costs</span></NavLink></li>
                            <li className={currentPage === 3 ? 'sidebar--active' : ''}><NavLink to='/settings/staff-members'><span>Staff members</span></NavLink></li>
                        </ul>
                    </ul>
                </div >
            </div >
        </div >
    )
}

export default SettingsSideNavigation;
