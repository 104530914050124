import React, { useState, useEffect } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import FeeCalculationDetailsForm from './FeeCalculationDetailsForm';



const SideNavigationMobile: React.FC = () => {

    const [ isMobileMenuOpen, toggleMobileMenuOpen ] = useState(false);

    useEffect(()=>{
        if(isMobileMenuOpen){
            document.body.classList.add('dashboard-mobile-active');
        }else{
            document.body.classList.remove('dashboard-mobile-active');
        }
    }, [isMobileMenuOpen])


    return (
        <div className="dashboard-mobile">

            <div className="dashboard-mobile__navbar">

                <div className="dashboard-mobile__user">

                    <a href="#" onClick={()=>{toggleMobileMenuOpen(!isMobileMenuOpen)}} className="dashboard-mobile-active-toggle">

                        <div className="dashboard-mobile__user__details">

                            <div className="dashboard-mobile__user__name">Menu</div>

                        </div>

                        <i className="dashboard-mobile__caret"></i>

                    </a>

                </div>

            </div>

        </div>
    )
}

export default SideNavigationMobile;
