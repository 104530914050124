import React from 'react';
import HelpSideNavigation from '../../organisms/HelpSideNavigation';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';


const GeneralInformation: React.FC = () => {

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <SideNavigationMobile />
                <HelpSideNavigation currentPage={1} />
                <div className="dashboard-content-wrapper">
                    <div className="dashboard-body-content dashboard-content-wrapper__white">
                        <div className="dashboard-body-content__form">
                            <div className="dashboard-panel mt-40 ml-30 mr-50">
                                <h1>Help and information about the RIBA Fee Calculator</h1>
                                <p><strong>The RIBA Fee Calculator will help you develop resource-based fee calculations for your projects.</strong></p>
                                <br />
                                <h3>How does the RIBA Fee Calculator work?</h3>
                                <ol>
                                    <li>Get set up by entering practice details, overheads, and staff details</li>
                                    <li>If you participate in the RIBA Business Benchmarking Survey you can use the same data for this</li>
                                    <li>Produce detailed, unique resource-based fees for each project or client</li>
                                    <li>See how your costs and calculations align with the RIBA Plan of Work</li>
                                    <li>Download and import into your fee proposal or into a RIBA Professional Services Contract using RIBA Contracts Digital, or as an Excel spreadsheet.</li>
                                </ol>
                                <br />
                                <h3>Getting started with the RIBA Fee Calculator</h3>
                                <p>It’s important to enter accurate company costs in Settings. This will make sure your fee calculations reflect the true cost to your business.</p>
                                <p><strong>The data you enter includes:</strong></p>
                                <ol>
                                    <li><strong>Annual company overhead costs,</strong> including any premises, utilities, travel, marketing, financial and legal costs as well as employer National Insurance contributions and pension costs</li>
                                    <li><strong>Staff salary details</strong> (billable status, base salary, hourly rates) </li>
                                </ol>
                                <br />
                                <p>If you do not have this information to hand, an accountant or financial adviser may be able to help. </p>
                                <p>If you do not use an accountant or financial adviser and need support: </p>
                                <ul>
                                    <li><a href="https://www.architecture.com/riba-business/accounts-and-payroll-services">Find out if our finance business partner Whitemoor Davis can help</a>, or</li>
                                    <li><a href="mailto:business@riba.org?subject=Fee Calculator support needed">Contact the RIBA Business Team by email</a> or telephone on <a href="tel:+44(0)2073075355">+44 (0)20 7307 5355</a> </li>
                                </ul>
                                <p>Your company, financial and salary information can only be accessed by users with admin permissions. We recommend assigning two admin users for the RIBA Fee Calculator. </p>
                                <p>Once you’ve completed setup, you can edit your settings to control the access and permissions of your staff, and who can use the RIBA Fee Calculator.</p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default GeneralInformation;