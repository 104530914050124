import React from 'react';

type FormReadonlyCurrencyProps =  {
    title?: string,
    value?: number,
}

const FormReadonlyCurrency: React.FC<FormReadonlyCurrencyProps> = (props) => {

    var { title, value } = props;
    if(!value) { value = 0; }
    return (
        <>
        {title && (
            <div className="form-value-container">
                <div className="form-value-container__label">
                    <div className="form-label">
                        <div className="form-label__title">
                            {title}
                        </div>
                    </div>
                </div>
                <div className="form-value-container__value">
                    <div className="form-value">
                        £{value.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default FormReadonlyCurrency;