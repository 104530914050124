import api from '../api/api';
import { RootStore } from './rootStore';
import { Member } from '../types/member';
import { Authenticate } from '../types/authenticate';
import Config from '../config';
import { action, computed, observable, runInAction, toJS } from 'mobx';

export default class MemberStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable member: Member | null = null;
    @observable editMember: Member | null = null;
    @observable auth: Authenticate | null = null;
    @observable isLoadingMember = false;
    @observable isAuthenticated : boolean = false;


    // Implemented a callback instead of an await async function because FireFox did not respect our wishes.
    @action authenticateMember = (callback: any) => {
        this.isLoadingMember = true;
        try {
            api.AuthenticateMethods.current().then((auth) => {
                if (auth) {
                    runInAction(() => {
                        this.auth = auth;
                        if (auth.authenticated) {
                            this.isAuthenticated = true;
                        } else {
                            this.isAuthenticated = false;
                        }
                    });
                    callback(auth)
                }
            });
        } catch (error) {
            runInAction(() => {
                this.isLoadingMember = false;
            });
            console.log(error)
        } finally {
            runInAction(() => {
                this.isLoadingMember = false;
            });
        }
    }

    @action getMember = async () => {
        this.isLoadingMember = true;
        try {
            const member = await api.UserMethods.current();
            runInAction(() => {
                this.member = member;
            });
            return this.member;
        } catch (error) {
            runInAction(() => {
                this.isLoadingMember = false;
            });
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoadingMember = false;
            });
        }
    }

    @action login = () => {
        window.location.replace(Config.AUTH_URL + 'login?ReturnUrl=' + Config.CLIENT_APP_URL)
    }

    @action logout = () => {
        this.member = null;
        window.location.replace(Config.AUTH_URL + 'api/sitecore/Security/Logout?ReturnUrl='+ Config.AUTH_URL + 'login?ReturnUrl=' + Config.CLIENT_APP_URL)
    }

    @action acceptTermsAndConditions = async () => {
        this.isLoadingMember = true;
        try {
            await api.UserMethods.acceptTerms();
            runInAction('Accepting Terms', () => {
                if (this.member) {
                    this.member.acceptedTermsAndConditions = true;
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoadingMember = false;
            });
        }
    }
}