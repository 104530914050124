import React from 'react';
import { NavLink } from 'react-router-dom';

type ButtonProps = {
    labelText?: string,
    linkTo?: string, 
    styleClass?: string,
    externalLink?: boolean,
}

const Button: React.FC<ButtonProps> = (props) => {

    const { styleClass, labelText, linkTo, externalLink } = props;
    if(externalLink){
        return (
            <a className={styleClass} href={linkTo}>
                {labelText}
            </a>
        );
    }else{
        return (
            <NavLink className={styleClass} to={{pathname: linkTo}}>
                {labelText}
            </NavLink>
        );
    }
}

export default Button;
