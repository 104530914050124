import * as Yup from 'yup';

export const AddEditProjectFormValidation = Yup.object().shape({
    name: Yup.string()
        .required('Please complete this mandatory field.')
        .min(5, 'The value you entered is too short (minimum is 5 characters).'),
    number: Yup.string()
        .required('Please complete this mandatory field.'),
    siteAddress: Yup.string()
        .required('Please complete this mandatory field.')
        .min(10, 'The value you entered is too short (minimum is 10 characters).')
        .max(500, 'The value you entered is too long (maximum is 500 characters).'),
    description: Yup.string()
        .max(2500, 'The value you entered is too long (maximum is 2500 characters).'),
    existingClient: Yup.string()
        .required(''),
    clientId: Yup.number()
        .when('existingClient', {
            is: 'Yes',
            then: Yup.number()
                .required('Please complete this mandatory field.')
                .moreThan(0, 'Please complete this mandatory field.')
        }),
    clientName: Yup.string()
        .when('existingClient', {
            is: 'No',
            then: Yup.string()
                .required('Please complete this mandatory field.')
                .min(5, 'The value you entered is too short (minimum is 5 characters).')
        }),
    contactEmail: Yup.string()
        .email('Please enter a valid email address.'),
    subCategory: Yup.string()
        .required('Please complete this mandatory field.')

});