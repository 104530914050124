import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import Alert from '../molecules/Alert';
import Button from '../atoms/Button';
import { observer } from 'mobx-react-lite';

type TermsAndConditionsFormProps = {
    handleSubmit: () => any
}


const TermsAndConditionsAdminForm: React.FC<TermsAndConditionsFormProps> = observer((props) => {
    const { handleSubmit } = props;
    const rootStore = useContext(RootStoreContext);
    const { member, login, acceptTermsAndConditions } = rootStore.memberStore;
    const { practice, isOnboarded } = rootStore.practiceStore;
    const [checkedTerms, setChecked] = useState(false);
    const [checkedAuthorised, setCheckAuthorised] = useState(false);
    const toggleTermsChecked = () => setChecked(!checkedTerms);
    const toggleAuthorisedChecked = () => setCheckAuthorised(!checkedAuthorised);

    useEffect(() => {
        if (member?.acceptedTermsAndConditions) {
            handleSubmit();
        }
    }, []);

    const handleStep0Submit = () => {
        acceptTermsAndConditions().finally(() => {
            //Is loading false
            handleSubmit();
        });
    }

    return (
        <>
            <div className="large-content-container mt-20">
                <h1>RIBA Fee Calculator setup</h1>
            </div>
            <div className="heading-two">
                <h2 className="heading-two__heading">Setting up the RIBA Fee Calculator </h2>
            </div>
            <div className="narrow-content-container">
                <article>
                    <div className="content-container">
                        <p><strong>The RIBA Fee Calculator will help you prepare resource-based fee calculations your projects and clients. Before you can prepare a fee calculation you need to enter your company overheads and staff salary details. You only have to complete this process once, but will be able to edit or update the information any time by managing your Settings. </strong></p>
                        <div className="heading-two">
                            <h2>How does it work? </h2>
                        </div>
                        <ul>
                            <li>Start by entering your practice details, overhead costs and staff details</li>
                            <li>You can use the same data you may have provided as part of the annual RIBA Business Benchmarking Survey</li>
                            <li>You can then create detailed, resource-based fee calculations for your projects, aligned with the stages of the RIBA Plan of Work</li>
                            <li>Save, edit or access any fee calculations or details at any time</li>
                            <li>Download a calculation or export it  into your standard fee proposals, or into an RIBA professional services contract using RIBA Contracts Digital</li>

                        </ul>
                        <div className="heading-two">
                            <h2>Before you start</h2>
                        </div>
                        <p>It is important that you enter accurate company costs when asked. This will make sure your fee calculations accurately reflect the true cost to your business.</p>
                        <p>Only people you designate to have ‘admin’ access rights are able to add, edit or see company and employee information or salary details. Admin users can grant access to others to use RIBA Fee Calculator and generate fee calculations for projects. Please make sure you choose and assign the correct access rights for your staff.</p>
                        <p><strong>Have the following information ready:</strong></p>
                        <ol>
                            <li><strong>Annual company overhead costs</strong> (including, for example, any premises, utilities, travel, marketing, financial and legal costs)</li>
                            <li><strong>Staff salary details</strong> (including salaries, hours worked each year, percentage of billable hours for each member of staff)</li>
                        </ol>
                        <p>You may find it useful check our <a target="_BLANK"  href="/help">guidance notes</a> to help you prepare this information.</p>
                        <p>If you use an accountant or financial adviser, they may be able to help you prepare this information.</p>
                        <p>If you do not use an accountant or financial adviser and need support:</p>
                        <ul>
                            <li>our finance business partner Whitemoor Davis may be able to help,</li>
                            <li>or contact the RIBA Business Team for advice by telephone on +44 (0)20 7307 3737</li>
                        </ul>
                        <p>Your company, financial and salary information can only be accessed by ‘administrator’ users. We recommend having two administrators for the RIBA Fee Calculator. After you have completed setup, you can edit your settings to control the access and permissions of your staff, and who can use the RIBA Fee Calculator.</p>
                        <p>You must accept our <a target="_BLANK" href="https://www.architecture.com/riba-fee-calculator/terms-and-conditions">terms and conditions</a> and accept our <a target="_BLANK" href="https://www.architecture.com/riba-fee-calculator/privacy-policy">privacy policy</a> to ensure GDPR compliance, as you will be using confidential company information. </p>
                        <div className="mt-40 checkbox-group">

                            <label className="checkbox__group">
                                <input type="checkbox" onClick={toggleTermsChecked} onChange={() => { }} checked={checkedTerms} className="checkbox__input" />
                                <i className="checkbox__check"></i>
                                <p className="checkbox__text">I accept the terms and conditions of use and agree to the privacy policy</p>
                            </label>

                            <label className="checkbox__group">
                                <input type="checkbox" onClick={toggleAuthorisedChecked} onChange={() => { }} checked={checkedAuthorised} className="checkbox__input" />
                                <i className="checkbox__check"></i>
                                <p className="checkbox__text">I am authorised to handle sensitive and confidential company overhead and staff salary data for my company</p>
                            </label>
                        </div>
                        <div className="narrow-content-container center mb-50 mt-20" >
                            <button className="mt-40 center button button--opaque" disabled={!checkedTerms || !checkedAuthorised} onClick={handleStep0Submit}>Start</button>
                        </div>
                        <div className="form-heading mt-40 mb-50">
                            <h2 className="form-heading__title">Useful links</h2>
                            <div className="divider divider--dark"></div>
                            <ul>
                                <li><a target="_BLANK" href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/riba-plan-of-work">RIBA Plan of Work</a> </li>
                                <li><a target="_BLANK" href="https://www.architecture.com/riba-contracts">RIBA Contracts</a> </li>
                                <li><a target="_BLANK" href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/business-benchmarking-1">RIBA Benchmarking Survey</a> </li>
                            </ul>
                        </div>
                    </div>
                </article>
            </div>

        </>
    )
});

export default TermsAndConditionsAdminForm;