export class Stage {
    id: number = 0;
    stageId: number = 0;
    name: string = "";
  
    constructor(init?: Stage) {
      if (init) {
          Object.assign(this, init);
      }
    }
  
  };