
export class FixedCost {
  id: number = 0;
  description: string = '';
  fixedCost: number = 0;

  constructor(init?: FixedCost) {
    if (init) {
        Object.assign(this, init);
    }
  }
}