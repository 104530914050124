import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FeeCalculation } from '../../types/feeCalculation';
import { Project } from '../../types/project';

type ProjectSideNavigationProps = {
    currentPage: number,
    project: Project | null,
    feeCalcId?: number;
}

const ProjectSideNavigation: React.FC<ProjectSideNavigationProps> = observer((props) => {
    const [expandedFeeCalcMenuItem, setExpandedItem] = useState(0);
    const { currentPage, project, feeCalcId } = props;

    return (<div className="sidebar">
            <div className="sidebar__content">
                <div className="sidebar__navigation">
                    <ul className="sidebar__accordion">
                        <ul>
                            <li><NavLink to='/projects'><span>Projects and fee calculations</span></NavLink></li>
                            <li><h3 className="ml-20">{project?.name ? (project?.name) : 'New project'}</h3></li>
                            {project?.feeCalculationSummaries && (
                                <li className={currentPage === 1 ? 'sidebar--active' : ''}>
                                    {project.id > 0 ? (<NavLink to={`/projects/${project.id}`}><span>Project details</span></NavLink>) : (<NavLink to={`#`}><span>Project details</span></NavLink>)}
                                    <ul>
                                        {project?.feeCalculationSummaries.map((feeCalculation: FeeCalculation, index: any) => (
                                            <li key={index} className={expandedFeeCalcMenuItem === feeCalculation.id ? 'sidebar--active' : ''}>
                                                <a onClick={() => { setExpandedItem(feeCalculation.id!) }} className="sidebar--has-ul">
                                                    {feeCalculation.name && feeCalculation.name != "" ? 
                                                        (
                                                            <>{feeCalculation.name}</>
                                                        ) : 
                                                        (
                                                            <>{feeCalculation.state}_{feeCalculation.version}</>
                                                        )
                                                    }
                                                </a>
                                                <ul className="sidebar--hidden-ul" style={{
                                                    display: expandedFeeCalcMenuItem === feeCalculation.id || feeCalcId === feeCalculation.id ? 'block' : 'none',
                                                }} >
                                                    <li className={(feeCalcId === feeCalculation.id && currentPage === 2) ? 'active' : ''}><NavLink to={`/projects/${project.id}/fee-calculation-stages/${feeCalculation.id}`}>RIBA stages</NavLink></li>
                                                    <li className={(feeCalcId === feeCalculation.id && currentPage === 3) ? 'active' : ''}><NavLink to={`/projects/${project.id}/fee-calculation/${feeCalculation.id}`}>Fee calculation</NavLink></li>
                                                </ul>
                                            </li>

                                        ))}
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </ul>
                </div>
            </div>
        </div>
    )
})

export default ProjectSideNavigation;
