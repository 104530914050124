import { FeeCalculation } from './feeCalculation';
export class Project {
    id: number = 0;
    memberId = 0;
    number: string = '';
    name: string = '';
    existingClient: string = 'Yes';
    status: string = 'Prospect';
    category: string = 'Domestic';
    subCategory: string = '';
    siteAddress: string = '';
    description: string = '';
    tertiaryCategory?: string = '';
    clientId: number = 0;
    existingClientId: number = -1;
    created: Date = new Date();
    updated: Date = new Date();
    archived: boolean = false;
    feeCalculationSummaries: Array<FeeCalculation> = [];

    //TODO ADD
    totalProjectFee: number = 0;

    constructor(init?: Project) {
        if (init) {
            Object.assign(this, init);
        }
    }
}

