export class Client {
    id: number = 0;
    practiceId: number = 0;
    clientName: string = '';
    contactName: string = '';
    contactEmail: string = '';

    public constructor(init?: Partial<Client>) {
        Object.assign(this, init);
    }
}

