import { Member } from './member';
export class Practice {
    id: number = 0;
    cubePracticeId: number = 0;
    onboarded: boolean = false;
    members: Array<Member> = [];
    name: string = '';
    addressLineOne: string = '';
    addressLineTwo: string = '';
    addressLineThree: string = '';
    city: string  = '';
    county: string = '';
    postcode: string = '';

    //overheads
    rentAndServiceCharge: number = 0;
    utilities: number = 0;
    softwareAndIT: number = 0;
    officeITAndCommunications: number = 0;
    marketingCosts: number = 0;
    companyCarFuelAllowance: number = 0;
    travelCost: number = 0;
    auditFeesAndAccountancy: number = 0;
    bankChargesAndInterest: number = 0;
    professionalIndemnityInsurance: number = 0;
    otherInsuranceCosts: number = 0;
    legalCosts: number = 0;
    depreciation: number = 0;
    badDebts: number = 0;
    pensionCosts: number = 0;
    nationalInsuranceContribution: number = 0;
    otherAnnualCosts: number = 0;

    totalAnnualOverheadCosts: number = 0;

    public constructor(init?: Partial<Practice>) {
        Object.assign(this, init);
    }
}

