import React from 'react';
import Button from '../atoms/Button';

const MobileLanding: React.FC = () => {
    return (
        <div className="narror-content-container">
            <div className="content-container">
                <div className="heading-one">
                    <h1 className="heading-one__heading">LOG IN TO THE RIBA FEE CALCULATOR</h1>
                </div>
                <div className="subtitle">
                    <h2 className="subtitle__title">Sorry, this service is only available from desktop</h2>
                </div>
                <p>Thank you for your interest in usig the RIBA Fee Calculator. Unfortunately, this service is only available from desktop at the moment. Please try and login from your computer or laptop.</p>
                <Button linkTo='https://www.architecture.com' externalLink={true} labelText='Back to architecture.com' styleClass='button button--opaque mt-20 mb-30' />
                <p></p>
            </div>
        </div>
    )
}

export default MobileLanding;