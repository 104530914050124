import React, { useEffect, useContext, useState } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import FeeCalculationDetailsForm from '../../organisms/FeeCalculationDetailsForm';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../organisms/Loading';
import Alert from '../../molecules/Alert';
import ProjectSideNavigation from '../../organisms/ProjectSideNavigation';
import { canEditFeeCalculation, isFeeCalculationOwnerOrAdmin } from '../../../utils/Helpers';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';

interface FeeCalculationDetailsProps { id: string, feeCalculationId: string }

const FeeCalculationDetails: React.FC<RouteComponentProps<FeeCalculationDetailsProps>> = observer(({ match, location }) => {

    const rootStore = useContext(RootStoreContext);
    const { project, getProject, isLoading } = rootStore.projectStore;
    const { member } = rootStore.memberStore;
    const { client, getClient } = rootStore.clientStore;
    const { feeCalculationDetailed, getFeeCalculationDetailed, isLoadingFeeCalc, setIsDirtyForm } = rootStore.feeCalculationStore;
    const [canEdit, setCanEdit] = useState(false);
    const [isOwnerOrAdmin, setIsOwnerOrAdmin] = useState(false);
    const [showDeclineSuccessBanner, setShowDeclineSuccessBanner] = useState(false);
    const [showAcceptSuccessBanner, setShowAcceptSuccessBanner] = useState(false);

    useEffect(() => {
        if (match.params.id && match.params.feeCalculationId) {
            getProject(match.params.id).then(() => {
                getFeeCalculationDetailed(match.params.feeCalculationId)
            })
        }
    }, [getProject, getFeeCalculationDetailed, match.params.feeCalculationId]);

    useEffect(() => {
        if (feeCalculationDetailed && member) {
            setCanEdit(canEditFeeCalculation(feeCalculationDetailed.feeCalculation, member));
            setIsOwnerOrAdmin(isFeeCalculationOwnerOrAdmin(feeCalculationDetailed.feeCalculation, member));
        }
    })
    
    useEffect(() => {
        if ((new URLSearchParams(window.location.search)).get("showDeclineSuccessBanner")) { setShowDeclineSuccessBanner(true); getFeeCalculationDetailed(match.params.feeCalculationId) } else { setShowDeclineSuccessBanner(false) }
        if ((new URLSearchParams(window.location.search)).get("showAcceptSuccessBanner")) { setShowAcceptSuccessBanner(true) } else { setShowAcceptSuccessBanner(false) }
    }, [location])

    useEffect(() => {
        if (project?.clientId) {
            getClient(project?.clientId).finally(() => {
            });
        }
    }, [getClient, project]);

    const handleSubmit = () => {
        setShowDeclineSuccessBanner(false)
        setShowAcceptSuccessBanner(false)
        getFeeCalculationDetailed(match.params.feeCalculationId);
    }

    return (
        <>
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <SideNavigationMobile />
                    <ProjectSideNavigation project={project} feeCalcId={feeCalculationDetailed?.feeCalculation?.id} currentPage={2} />
                    {isLoading || isLoadingFeeCalc ? (
                        <Loading />
                    ) : (
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30 mr-30">
                                            {showDeclineSuccessBanner && (
                                                <Alert title="Success" text="No change has been applied to your calculation. You can carry on with the calculation" styleClass="alert alert--success" />
                                            )}
                                            {showAcceptSuccessBanner && (
                                                <Alert title="Success" text="Changes applied to your calculation. Please check your mark-ups and your client fees and make sure they are correct" styleClass="alert alert--success" />
                                            )}
                                            <FeeCalculationDetailsForm
                                                projectName={project?.name}
                                                handleSubmit={handleSubmit}
                                                canEdit={canEdit}
                                                isOwnerOrAdmin={isOwnerOrAdmin}
                                                feeCalculationDetailed={feeCalculationDetailed}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
})

export default FeeCalculationDetails;