import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { RootStoreContext } from '../../stores/rootStore';
import { Member } from '../../types/member';

type UserMenuProps = {
    member: Member | null
}

const UserMenu: React.FC<UserMenuProps> = observer((props) => {

    const { member } = props;
    const rootStore = useContext(RootStoreContext);
    const { logout } = rootStore.memberStore;
    const { isOnboarded } = rootStore.practiceStore;

    return (
        <ul className="primary-nav--logged-in__items">
            <li className="primary-nav--logged-in-first-level-item" data-js-has-toggle="1">
                <a href="#" className="primary-nav--logged-in-first-level-item__title-link">
                    <i className="material-icons"></i>
                    <span className="primary-nav--logged-in-first-level-item__title-account">My account</span>
                </a>
                <div className="primary-nav-first-level-item__toggle-control" data-js-toggle-control="1">
                    <span data-js-toggle-element="1">arrow_drop_down</span>
                    <span data-js-toggle-element="1" className="js-toggle-hidden">arrow_drop_up</span>
                </div>
                <ul data-js-toggle-element="1" className="js-toggle-hidden primary-nav--logged-in-first-level-item__items">
                    {/* <li className="primary-nav--logged-in-second-level-item"><a href="#" className="primary-nav--logged-in-second-level-item__link"> <i className="material-icons"></i> {member?.firstName} {member?.lastName}</a></li> */}
                    <li className="primary-nav--logged-in-second-level-item">
                        {(isOnboarded && member?.accessLevel === 'Admin') && (
                            <NavLink className="primary-nav--logged-in-second-level-item__link" to='/settings/practice-details'>
                                <i className="material-icons">settings</i> Settings
                            </NavLink>
                        )}
                    </li>
                    <li className="primary-nav--logged-in-second-level-item"><a href="/help" className="primary-nav--logged-in-second-level-item__link" target="_blank"><i className="material-icons">help</i> Help</a></li>
                    <li className="primary-nav--logged-in-second-level-item"><a href="#" onClick={logout} className="primary-nav--logged-in-second-level-item__link"><i className="material-icons"></i> Log out</a></li>
                </ul>
            </li>
        </ul>
    )
})

export default UserMenu;
