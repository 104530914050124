import * as Yup from 'yup';

export const FeeCalculationFormValidation = Yup.object().shape({
    feeCalculationStages: Yup.array()
        .of(Yup.object().shape({
            feeCalculationStageTasks: Yup.array()
                .of(Yup.object().shape({
                    name: Yup.string()
                        .required('Please complete this mandatory field.')
                        .max(250, 'The value you entered is too long (maximum is 250 characters).'),
                    note: Yup.string()
                        .max(1000, 'The value you entered is too long (maximum is 1000 characters).'),
                    fixedCosts: Yup.array()
                        .of(Yup.object().shape({
                            description: Yup.string()
                                .max(250, 'The value you entered is too long (maximum is 250 characters).'),
                        })),
                    staffCosts: Yup.array()
                        .of(
                            Yup.object().shape({
                            member: Yup.object().shape({
                                id : Yup.number().moreThan(0, 'Please select a staff member'),
                            })
                        }))
                }))
        }))
});