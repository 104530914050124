import React, { useEffect, useContext } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import PracticeMembersList from '../../organisms/PracticeMembersList';
import SetupFormSteps from '../../organisms/SetupFormSteps';
import { observer } from 'mobx-react-lite';
import { history } from '../../../';

const Step3: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { practice, getPractice } = rootStore.practiceStore;

    const handleSubmit = () => {
        history.push('/setup/step4');
        window.scrollTo(0, 0);
    }

    const handleBack = () => {
        history.push('/setup/step2');
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        let isLoading = true;
        getPractice().finally(() => {
            // isLoading(true);
        })

        return () => {
            isLoading = false;   // clean up function
        };

    }, []);

    const handleReload = () => {
        getPractice()
    }

    return (
        <>
            <div className="large-content-container mt-20">
                <h1>RIBA Fee Calculator setup</h1>
            </div>
            <div className="narrow-content-container ">
                <SetupFormSteps practice={practice} currentStep={3} />
                <div className="form-heading">
                    <h2 className="form-heading__title">Add staff salary details</h2>
                    <p>Add your staff details, salary information, and working hours using the table below. Use our <a target="_BLANK" href="/help">guidance notes</a> to determine which staff are billable staff (if they actively work on projects) or are non-billable (those providing business support services). You can assign staff to certain projects later in the process when creating fee calculations. Additional consultants or contractors engaged to work on specific projects are added to projects when making fee calculations, rather than here as part of your payroll. You can edit or add staff salary details at any later stage by managing your Settings.</p>
                </div>
            </div>
            <div className="large-content-container mt-20">
                <PracticeMembersList
                    title="Add staff salary details."
                    handleBack={handleBack}
                    handleSubmit={handleSubmit}
                    handleReload={handleReload}>
                </PracticeMembersList>
            </div>

        </>

    )

})

export default Step3;