import React, {useContext} from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import FormReadonlyCurrency from '../molecules/FormReadonlyCurrency';
import SetupFormSteps from './SetupFormSteps';
import { Member } from '../../types/member';
import { runInAction } from 'mobx';
import { NavLink } from 'react-router-dom';

type SetupSummaryProps = {
    handleBack: () => any,
    handleSubmit: () => any
}


const SetupSummary: React.FC<SetupSummaryProps> = (props) => {
    const  {handleSubmit, handleBack} = props;
    const rootStore = useContext(RootStoreContext);
    const { practice, editPractice } = rootStore.practiceStore;

    const savePractice = (practice: any) => {
        runInAction(() => {
            practice.onboarded = true;
        });
        editPractice(practice).finally(() => {
            handleSubmit()
        });
    }

    const goBack = () => {
        handleBack()
    }
    
    return (
        <>
            <div className="large-content-container mt-20">
                <h1>RIBA Fee Calculator setup</h1>
            </div>
            <div className="narrow-content-container ">

                <SetupFormSteps practice={practice} currentStep={4} />

                <div className="form-heading">
                    <h2 className="form-heading__title">Finish set up</h2>
                    <p>Please check through the information below and confirm it is accurate. This information will then be used in your fee calculations. You can edit this information later by changing your settings.  </p>
                </div>
                
                    
                <div className="join-riba-form-content__section" data-js-has-toggle="">
                    <div className="form-heading ">
                        <NavLink to="/setup/step1" className="edit-button form-heading__edit">Edit</NavLink>
                        <h2 className="form-heading__title">Practice details </h2>
                        <div className="divider divider--dark"></div>
                    </div>
                    <div data-js-toggle-element="" className="join-riba-form-content__collapsableSection">
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                            <div className="form-label">
                                <div className="form-label__title">
                                    {practice?.name}
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="form-value-container">
                            <div className="form-value-container__value">
                            <div className="form-value">
                                <div>{practice?.addressLineOne}</div>
                                <div>{practice?.addressLineTwo}</div>
                                <div>{practice?.addressLineThree}</div>
                                <div>{practice?.city}</div>
                                <div>{practice?.postcode}</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="join-riba-form-content__section" data-js-has-toggle="">
                    <div className="form-heading ">
                        <NavLink to="/setup/step2" className="edit-button form-heading__edit">Edit</NavLink>
                        <h2 className="form-heading__title">Overhead costs </h2>
                        <div className="divider divider--dark"></div>
                    </div>
                    <div data-js-toggle-element="" className="join-riba-form-content__collapsableSection">
                        <FormReadonlyCurrency title={"Rent and service charge:"} value={practice?.rentAndServiceCharge} />
                        <FormReadonlyCurrency title={"Utilities:"} value={practice?.utilities} />
                        <FormReadonlyCurrency title={"Architectural and design software and IT:"} value={practice?.softwareAndIT} />
                        <FormReadonlyCurrency title={"Office IT and telecommunications:"} value={practice?.officeITAndCommunications} />
                        <FormReadonlyCurrency title={"Marketing:"} value={practice?.marketingCosts} />
                        <FormReadonlyCurrency title={"Company car and fuel allowance:"} value={practice?.companyCarFuelAllowance} />
                        <FormReadonlyCurrency title={"Additional travel costs:"} value={practice?.travelCost} />
                        <FormReadonlyCurrency title={"Accountancy and auditing:"} value={practice?.auditFeesAndAccountancy} />
                        <FormReadonlyCurrency title={"Banking:"} value={practice?.bankChargesAndInterest} />
                        <FormReadonlyCurrency title={"Professional Indemnity Insurance:"} value={practice?.professionalIndemnityInsurance} />
                        <FormReadonlyCurrency title={"Other insurance costs:"} value={practice?.otherInsuranceCosts} />
                        <FormReadonlyCurrency title={"Legal costs:"} value={practice?.legalCosts} />
                        <FormReadonlyCurrency title={"Depreciation:"} value={practice?.depreciation} />
                        <FormReadonlyCurrency title={"Bad debts:"} value={practice?.badDebts} />
                        <FormReadonlyCurrency title={"Pension costs:"} value={practice?.pensionCosts} />
                        <FormReadonlyCurrency title={"Employer National Insurance contribution:"} value={practice?.nationalInsuranceContribution} />
                        <FormReadonlyCurrency title={"Other annual costs:"} value={practice?.otherAnnualCosts} />
                        <br/>
                        <strong><FormReadonlyCurrency title={"Total Annual Overhead Costs:"} value={practice?.totalAnnualOverheadCosts} /></strong>
                    </div>
                </div>

                
                <div className="join-riba-form-content__section" data-js-has-toggle="">
                    <div className="form-heading ">
                        <NavLink to="/setup/step3" className="edit-button form-heading__edit">Edit</NavLink>
                        <h2 className="form-heading__title">Staff and salaries </h2>
                        <div className="divider divider--dark"></div>
                    </div>
                    <div data-js-toggle-element="" className="join-riba-form-content__collapsableSection">
                       
                <div className="dashboard-data-table">
                    <br />
                    <table className="" id="table1" width="100%">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Job title</th>
                                <th scope="col">Total salary</th>
                                <th scope="col">Billable %</th>
                                <th scope="col">Cost rate (per&nbsp;hour)</th>
                                <th scope="col">Cost rate (per&nbsp;day)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {practice?.members.map((member: Member, index) => (
                                <tr>
                                    <td>{member.firstName} {member.lastName}</td>
                                    <td>{member.jobTitle}</td>
                                    <td>£{member.totalSalaryPackage.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</td>
                                    <td>{member.billableStatus === 'Billable' ? `${member.billableHours}%` : 'Non-billable'}</td>
                                    <td>{member.billableStatus === 'Billable' ? `£${member.costRatePerHour.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}` : '-'}</td>
                                    <td>{member.billableStatus === 'Billable' ? `£${member.costRatePerDay.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}` : '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <strong>Total staff salaries</strong>
                                </td>
                                <td></td>
                                <td>
                                    <strong>£{practice?.members.reduce((total, member) => total = total + member.totalSalaryPackage, 0).toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</strong>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                    </div>
                </div>

                <div className="join-riba-form-content__actions">
                    <button onClick={() => { goBack() }} className="button  ">Back</button>
                    <button onClick={() => { savePractice(practice) }} className="button button--opaque ">Finish set up</button>
                </div>
            </div>
        </>
    )
};

export default SetupSummary;