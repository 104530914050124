import React, { FC } from "react";
import { FieldProps, getIn } from "formik";

interface FormInputProps {
    type?: string,
    // handleChange: (e: any) => any,
    helptext?: string,
    serverErrors?: boolean
}

const FormInput: FC<FormInputProps & FieldProps> = ({
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue, setFieldTouched, handleChange, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    type = "text",
    ...props
}) => (
    <div className="input-wrapper">
        <input type={type} {...field} {...props}
            onChange={e => {                
                setFieldValue(field.name, e.target.value);
                setTimeout(() => setFieldTouched(field.name, true));
                onChange(e)
            }} className={((errors[field.name] && touched[field.name]) || (getIn(errors, field.name) && getIn(touched, field.name)) || props.serverErrors) ? 'singleline-text-input singleline-text-input--full-width singleline-text-input--error ' : 'singleline-text-input singleline-text-input--full-width'} 
            value={(type == 'number' && field?.value == 0) ? '' : field.value}/>
        {props.helptext && (
            <div className="input-wrapper__tip">{props.helptext}</div>
        )}
        {((errors[field.name] && touched[field.name]) || (getIn(errors, field.name) && getIn(touched, field.name))) ? (
            <div className="validation-summary-errors">
                <ul>
                    <li>{errors[field.name] || getIn(errors, field.name)}</li>
                </ul>
            </div>
        ) : null
        }
        {}
    </div>
);

export default FormInput;


