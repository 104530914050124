import React from 'react';
import Button from '../atoms/Button';

const NotFound: React.FC = () => {
    return (
        <div className="narror-content-container">
            <div className="content-container">
                <div className="heading-one">
                    <h1 className="heading-one__heading">Error - 404</h1>
                </div>
                <div className="subtitle">
                    <h2 className="subtitle__title">Sorry - there seems to be a problem accessing the Fee Calculator</h2>
                </div>

                <p><strong>Please get in touch with our Contact Centre &#38; Membership Servicing team:</strong></p>
                email: <a href="mailto:support@riba.org?subject=Fee Calculator support needed">support@riba.org</a><br />
                telephone: <a href="tel:+44(0)2073075355">+44 (0)20 7307 5355</a><br />
                Monday to Friday, 9am to 5pm <br />
                <br />
                <p></p>
        </div>
        </div >
    )
}

export default NotFound;