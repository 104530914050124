import { Field, FieldArray, Form, Formik, FormikErrors, getIn } from "formik";
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import NumberFormat from "react-number-format";
import { NavLink, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { history } from '../..';
import { RootStoreContext } from '../../stores/rootStore';
import { FeeCalculation, FeeCalculationDetailed } from '../../types/feeCalculation';
import { FeeCalculationStage } from '../../types/feeCalculationStage';
import { FeeCalculationStageTask } from '../../types/feeCalculationStageTask';
import { Member, TaskMember } from '../../types/member';
import { Practice } from "../../types/practice";
import { RibaStages } from '../../utils/Constants';
import { getFeeCalculationStatusName } from '../../utils/Helpers';
import { FeeCalculationFormValidation } from '../../utils/validation/FeeCalculationFormValidation';
import Alert from '../molecules/Alert';
import CurrencyFormInput from '../molecules/CurrencyFormInput';
import FeeCalculationActionsDropdown from '../molecules/FeeCalculationActionsDropdown';
import FormTextAreaInputTaskNote from '../molecules/FormInputTaskNote';
import PercentageFormInput from '../molecules/PercentageFormInput';
import PromptIfDirty from '../molecules/PromptIfDirty';
import FeeCalculationStageTaskForm from './FeeCalculationStageTaskForm';

type FeeCalculationStagesFormProps = {
    handleSubmit: () => any,
    feeCalculationDetailed?: FeeCalculationDetailed,
    canEdit: boolean,
    isOwnerOrAdmin: boolean,
    projectName: string | undefined
}

const FeeCalculationDetailsForm: React.FC<FeeCalculationStagesFormProps> = observer((props) => {
    const { feeCalculationDetailed, handleSubmit, canEdit, isOwnerOrAdmin, projectName } = props;
    const rootStore = useContext(RootStoreContext);
    const { editFeeCalculation, syncFeeCalculation, deleteTasks, deleteFixedCosts, deleteStaffCosts, isDirtyForm, setIsDirtyForm } = rootStore.feeCalculationStore;
    const { member } = rootStore.memberStore;
    const { practice, isLoadingPractice, getPractice } = rootStore.practiceStore;
    const { pathname } = useLocation();
    const [expandFeeCalculationStage, toggleFeeCalculationStageExpand] = useState(0)
    // const [showPercent, toggleShowPercent] = useState(false);
    const [showLeftArrow, toggleLeftArrow] = useState(false);
    const [showRightArrow, toggleRightArrow] = useState(false);
    const [tasksDirty, setTasksDirty] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [editingTaskFee, setEditingTaskFee] = useState(false);
    const [tasksToDelete, setTasksToDelete] = useState<number[] | []>([]);
    const [fixedCostsToDelete, setFixedCostsToDelete] = useState<number[] | []>([]);
    const [staffCostsToDelete, setStaffCostsToDelete] = useState<number[] | []>([]);
    const [hasAnyStageCostChange, setHasAnyStageCostChange] = useState(false);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getPractice().then((practice: Practice | null | undefined) => {
            if (practice !== null) {
                anyStageCostChange(practice);
            }
        })
    }, [])
    
    const saveFeeCalculation = (feeCalculation: FeeCalculation, isAutoSave?: boolean, callback?: () => any) => {
        runInAction(() => {
            feeCalculation.feeCalculationStages?.forEach((feeCalculationStage: FeeCalculationStage) => {
                let totalStageCost = Number(calculateStageCost(feeCalculationStage)! || 0);
                let clientStageFee = Number(calculateStageFee(feeCalculationStage)! || 0);
                feeCalculationStage.totalStageCost = totalStageCost;
                feeCalculationStage.clientStageFee = clientStageFee
                feeCalculationStage.practiceMargin = (clientStageFee - totalStageCost);
            })

            feeCalculation.totalProjectFee = calculateProjectFee(feeCalculation);
            feeCalculation.totalProjectCost = calculateProjectCost(feeCalculation);
            feeCalculation.lastModifiedBy = member?.firstName + ' ' + member?.lastName;
            feeCalculation.lastModified = new Date();
            if (tasksToDelete.length > 0 || staffCostsToDelete.length > 0 || fixedCostsToDelete.length > 0) {
                deleteStaffCosts(staffCostsToDelete).then(() => {
                    deleteFixedCosts(fixedCostsToDelete).then(() => {
                        deleteTasks(tasksToDelete).then(() => {
                            setTasksToDelete([]);
                            setFixedCostsToDelete([]);
                            setStaffCostsToDelete([]);
                            editFeeCalculation(feeCalculation).then((feeCalc) => {
                                setIsDirtyForm(false);
                                if (isAutoSave && callback) {
                                    callback();
                                } else {
                                    handleSubmit();
                                }
                            })
                        })
                    })
                })
            } else {
                editFeeCalculation(feeCalculation).then((feeCalc) => {
                    setIsDirtyForm(false);
                    if (isAutoSave && callback) {
                        callback();
                    } else {
                        handleSubmit();
                    }
                })
            }

        })
    }

    const addTaskToDelete = (taskId: number) => {
        setTasksToDelete([
            ...tasksToDelete,
            taskId
        ]);
    }

    const addStaffCostToDelete = (staffCostId: number) => {
        setStaffCostsToDelete([
            ...staffCostsToDelete,
            staffCostId
        ]);
    }

    const addFixedCostToDelete = (fixedCostId: number) => {
        setFixedCostsToDelete([
            ...fixedCostsToDelete,
            fixedCostId
        ]);
    }

    const calculateTaskCost = (feeCalculationStageTask: FeeCalculationStageTask | undefined = undefined): number => {
        let staffCostTotal: number = 0;
        let fixedCostTotal: number = 0;
        if (feeCalculationStageTask) {
            if (feeCalculationStageTask?.fixedCosts) {
                feeCalculationStageTask?.fixedCosts.forEach((fixedCost) => {
                    fixedCostTotal = Number(fixedCostTotal) + Number(fixedCost.fixedCost);
                })
            }
            if (feeCalculationStageTask?.staffCosts.length > 0) {
                feeCalculationStageTask?.staffCosts.forEach((staffCost: TaskMember) => {
                    if (staffCost?.costRatePerHour && staffCost?.hours > 0) {
                        staffCostTotal = Number(staffCostTotal) + Number(staffCost?.hours * Math.round(staffCost?.costRatePerHour));
                    }
                })
            }
        }
        return Number(staffCostTotal + fixedCostTotal);
    }

    const calculateStageCost = (feeCalculationStage: FeeCalculationStage | undefined): number => {
        let stageCost: number = 0;
        if (feeCalculationStage && feeCalculationStage.enabled) {
            feeCalculationStage?.feeCalculationStageTasks.forEach((feeCalculationStageTask: FeeCalculationStageTask) => {
                stageCost = stageCost + Number(feeCalculationStageTask.totalTaskCost);
            })
        }
        return stageCost;
    }

    const calculateStageFee = (feeCalculationStage: FeeCalculationStage | undefined): number => {
        let stageFee: number = 0;
        if (feeCalculationStage && feeCalculationStage.enabled) {
            feeCalculationStage?.feeCalculationStageTasks.forEach((feeCalculationStageTask: FeeCalculationStageTask) => {
                stageFee = stageFee + Number(feeCalculationStageTask.clientTaskFee);
            })
        }
        return stageFee;
    }

    const calculateProjectCost = (feeCalculation: FeeCalculation | undefined): number => {
        let projectCost: number = 0;
        if (feeCalculation) {
            feeCalculation.feeCalculationStages?.forEach((feeCalculationStage: FeeCalculationStage) => {
                projectCost = projectCost + calculateStageCost(feeCalculationStage);
            })
        }
        return projectCost;
    }

    const calculateProjectFee = (feeCalculation: FeeCalculation | undefined): number => {
        let projectFee: number = 0;
        if (feeCalculation) {
            feeCalculation.feeCalculationStages?.forEach((feeCalculationStage: FeeCalculationStage) => {
                projectFee = projectFee + calculateStageFee(feeCalculationStage);
            })
        }
        return projectFee;
    }

    const calculateConstructionPerc = (feeCalculation: FeeCalculation) => {
        let constructPercent = '';
        if (feeCalculation.totalProjectFee && feeCalculation.totalProjectFee > 0 && feeCalculation.constructionCost && feeCalculation.constructionCost > 0) {

            constructPercent = ((Number(feeCalculation.totalProjectFee) / Number(feeCalculation.constructionCost)) * 100).toFixed(1);
        }

        if (Number(constructPercent) > 0) { constructPercent = constructPercent + "%" }

        return constructPercent;
    }

    const calculateTotalMarginPerc = (feeCalculation: FeeCalculation) => {
        let marginPercent = '';
        if (feeCalculation.totalProjectFee && feeCalculation.totalProjectFee > 0 && feeCalculation.totalProjectCost && feeCalculation.totalProjectCost > 0) {
            let margin = Number(feeCalculation.totalProjectFee) - Number(feeCalculation.totalProjectCost);
            marginPercent = ((Number(margin) / Number(feeCalculation.totalProjectFee)) * 100).toFixed(1);
        }

        return marginPercent;
    }

    const dismissSyncChanges = (feeCalculationDetailed: FeeCalculationDetailed) => {
        runInAction(() => {
            feeCalculationDetailed.acceptUpdates = false;
            syncFeeCalculation(feeCalculationDetailed).then(() => {
                history.push(`/projects/${feeCalculationDetailed.feeCalculation.projectId}/fee-calculation/${feeCalculationDetailed.feeCalculation.id}?showDeclineSuccessBanner=true`)
            })
        })
    }

    const acceptSyncChanges = (feeCalculationDetailed: FeeCalculationDetailed) => {
        runInAction(() => {
            feeCalculationDetailed.acceptUpdates = true;
            syncFeeCalculation(feeCalculationDetailed).then((newFeeCalc: any) => {
                if (newFeeCalc.projectId && newFeeCalc.feeCalculationId) {
                    history.push(`/projects/${newFeeCalc.projectId}/fee-calculation/${newFeeCalc.feeCalculationId}?showAcceptSuccessBanner=true`)
                }
            })
        })
    }

    const anyStageCostChange = (practice: Practice | null | undefined) => {
        var allStaffCosts = feeCalculationDetailed?.feeCalculation?.feeCalculationStages?.flatMap((s) => s.feeCalculationStageTasks.flatMap(t => t.staffCosts));
        
        if (allStaffCosts && allStaffCosts?.length > 0) {
            for(var i = 0; i < allStaffCosts.length ; i++) {
                var costs = allStaffCosts[i];
                if(costs?.costRatePerHour != practice?.members.find(x => x.id === costs?.member.id)?.costRatePerHour) {
                    setHasAnyStageCostChange(true);

                    return;
                }
            }
        }
        return () => setHasAnyStageCostChange(false);
    }

    return (
        <>
            { feeCalculationDetailed?.feeCalculation && (
                <>
                    {feeCalculationDetailed?.feeCalculation?.name && feeCalculationDetailed?.feeCalculation?.name != "" ? 
                        (
                            <><h1 className="mb-0">{feeCalculationDetailed?.feeCalculation?.name}</h1></>
                        ) : 
                        (
                            <><h1>{`${getFeeCalculationStatusName(feeCalculationDetailed?.feeCalculation.state)}_${feeCalculationDetailed?.feeCalculation.version}`}</h1></>
                        )
                    }
                    {!feeCalculationDetailed?.feeCalculation.feeCalculationStages?.find((feeCalculationStage: FeeCalculationStage) => feeCalculationStage.enabled === true) ? (
                        <>
                            <Alert text={'You need to select at least one RIBA Stage to create the fee calculation.'} styleClass='alert alert--warning' title='Warning' />
                            <NavLink className="button button--opaque" to={`/projects/${feeCalculationDetailed?.feeCalculation.projectId}/fee-calculation-stages/${feeCalculationDetailed?.feeCalculation.id}`} >Select RIBA stages</NavLink>
                        </>
                    ) : (
                            <>
                                {(!feeCalculationDetailed?.inSync && canEdit && !isLoadingPractice && hasAnyStageCostChange) && (
                                    <>
                                        <Alert text={'The overhead costs for your practice have been updated in Settings. These changes have affected your calculations. Click on the errors below to accept or decline the changes.'} styleClass='alert alert--warning' title='Warning' />
                                        <div className="fee-calculator-sync__actions">
                                            <button type="button" onClick={() => { dismissSyncChanges(feeCalculationDetailed) }} className="button mr-20">Dismiss changes</button>
                                            <button type="button" onClick={() => { acceptSyncChanges(feeCalculationDetailed) }} className="button button--opaque ">Accept and duplicate fee calculation</button>
                                        </div>
                                    </>
                                )}
                                <Formik
                                    initialValues={feeCalculationDetailed?.feeCalculation}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    enableReinitialize={true}
                                    validationSchema={FeeCalculationFormValidation}
                                    onSubmit={async (values) => {
                                        saveFeeCalculation(values, true, (() => { }));
                                    }}>
                                    {(feeCalculationForm) => {
                                        return (
                                            <>
                                                <div className="fee-calculation-actions">
                                                    <FeeCalculationActionsDropdown
                                                        canEdit={canEdit}
                                                        isOwnerOrAdmin={isOwnerOrAdmin}
                                                        feeCalculation={feeCalculationDetailed?.feeCalculation}
                                                        projectName={projectName}
                                                        feeCalculationForm={feeCalculationForm}
                                                        saveFeeCalculation={saveFeeCalculation}
                                                        handleSelection={() => { handleSubmit(); }} />
                                                </div>
                                                {Object.keys(feeCalculationForm.errors).length > 0 &&
                                                    (Object.keys(feeCalculationForm.touched).length > 0 || tasksDirty) &&
                                                    <Alert text='Please correct the errors in the stages highlighted below' styleClass='alert alert--error' title='Error' />
                                                }

                                                {
                                                    (isDirtyForm || tasksDirty) && (
                                                        <Alert text={'Your Fee Calculation has unsaved changes.'} styleClass='alert alert--warning' title='Warning' />
                                                    )
                                                }

                                                {
                                                    feeCalculationForm.values.feeCalculationStages?.map((feeCalculationStage: FeeCalculationStage, stageIndex) => {
                                                        return feeCalculationStage.enabled ? (
                                                            <Formik
                                                                key={stageIndex}
                                                                enableReinitialize={true}
                                                                initialValues={feeCalculationStage}
                                                                validateOnChange={false}
                                                                validateOnBlur={false}
                                                                onSubmit={(values, actions) => {
                                                                    runInAction(() => {
                                                                        //If user is editing client task fee, do  not run recalc on fees
                                                                        if (!editingTaskFee) {
                                                                            values.feeCalculationStageTasks.forEach((feeCalculationStageTask: FeeCalculationStageTask) => {
                                                                                feeCalculationStageTask.clientTaskFee = Math.round((feeCalculationStageTask.totalTaskCost + (feeCalculationStageTask.totalTaskCost * Number(feeCalculationStageTask.marginAsPercent / 100))))
                                                                            })
                                                                        }
                                                                        Object.assign(feeCalculationForm.values.feeCalculationStages?.[stageIndex], { ...values });
                                                                        feeCalculationForm.setValues(feeCalculationForm.values);
                                                                        feeCalculationForm.setFieldValue('totalProjectFee', calculateProjectFee(feeCalculationForm.values))
                                                                        feeCalculationForm.setFieldValue('totalProjectCost', calculateProjectCost(feeCalculationForm.values))
                                                                    })
                                                                }}>
                                                                {(feeCalculationStageForm) => {
                                                                    let stageCost = Number(calculateStageCost(feeCalculationStageForm.values)! || 0);
                                                                    let stageFee = Number(calculateStageFee(feeCalculationStageForm.values)! || 0);
                                                                    let stageMarginPercent = stageCost > 0 ? (((stageFee - Number(stageCost)) / Number(stageCost) * 100).toFixed(1)) : 0;
                                                                    let stageMargin = stageCost > 0 ? Number(((stageFee - Number(stageCost))).toFixed(1)) : 0;
                                                                    let stageHasError: boolean = feeCalculationStageForm.values.feeCalculationStageTasks.some((feeCalculationStageTask: FeeCalculationStageTask, taskIndex) => {
                                                                        return getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].name`)?.length > 0;
                                                                    })
                                                                    return (
                                                                        <Form onSubmit={e => e.preventDefault()} onChange={() => { feeCalculationStageForm.handleSubmit(); setTasksDirty(true); }} onKeyDown={(keyEvent) => {
                                                                            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                                                                keyEvent.preventDefault();
                                                                            }
                                                                        }}>
                                                                            <PromptIfDirty isDirtyForm={isDirtyForm} setIsDirtyForm={setIsDirtyForm}  />
                                                                            <fieldset>
                                                                                <div className="content-accordion__option" >
                                                                                    <input disabled={!canEdit} type="checkbox" onChange={(e) => { }} className="content-accordion__toggle" checked={feeCalculationStage.id === expandFeeCalculationStage} />
                                                                                    <label onClick={() => { 
                                                                                            toggleFeeCalculationStageExpand(feeCalculationStage.id !== expandFeeCalculationStage ? feeCalculationStage.id : 0); 
                                                                                            if (feeCalculationStage.id === expandFeeCalculationStage) { 
                                                                                                window.scrollTo(0, 0); } 
                                                                                            }
                                                                                        } className={`content-accordion__title content-accordion__title--stage ${stageHasError ? 'error' : ''}`}>
                                                                                        <div className="fee-calculator-stage-header">
                                                                                            <div style={{ backgroundColor: `${RibaStages[feeCalculationStage.stage.stageId].colour}` }} className="fee-calculator-stage-header--stage-number">
                                                                                                Stage {feeCalculationStage.stage.stageId}
                                                                                            </div>
                                                                                            <div className="fee-calculator-stage-header--stage-name">
                                                                                                {RibaStages[feeCalculationStage.stage.stageId].name}
                                                                                            </div>
                                                                                            {!feeCalculationDetailed?.inSync && !isLoadingPractice && canEdit && feeCalculationStageForm.values.feeCalculationStageTasks.some((feeCalculationStageTask: FeeCalculationStageTask) => {
                                                                                                return (feeCalculationStageTask.staffCosts.length > 0)
                                                                                                &&      (feeCalculationStageTask.staffCosts.some((staffCost) => {
                                                                                                            return staffCost.costRatePerHour !== ( practice?.members.find(x => x.id === staffCost.member.id)?.costRatePerHour ? 
                                                                                                                practice?.members.find(x => x.id === staffCost.member.id)?.costRatePerHour : 0)}))
                                                                                            }) ?
                                                                                                (
                                                                                                    <div className="fee-calculator-stage-header--stage-sync-icon">
                                                                                                        <span className="material-icons">
                                                                                                            report_problem
                                                                                                    </span>
                                                                                                    </div>
                                                                                                ) : null}
                                                                                            <div className={`fee-calculator-stage-header--stage-totals ${!feeCalculationDetailed?.inSync&& !isLoadingPractice && canEdit && feeCalculationStageForm.values.feeCalculationStageTasks.some((feeCalculationStageTask: FeeCalculationStageTask) => {
                                                                                                return (feeCalculationStageTask.staffCosts.length > 0)
                                                                                                &&      (feeCalculationStageTask.staffCosts.some((staffCost) => {
                                                                                                            return staffCost.costRatePerHour !== ( practice?.members.find(x => x.id === staffCost.member.id)?.costRatePerHour ? 
                                                                                                                practice?.members.find(x => x.id === staffCost.member.id)?.costRatePerHour : 0)}))
                                                                                            }) ? 'sync-error' : ''}`}>
                                                                                                <div className="fee-calculator-stage-header--stage-margin">
                                                                                                    <span className="stage-header-total-label">Practice margin:</span>
                                                                                                    <span className="stage-header-total-amount">{`£${stageMargin.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}`}<span className="stage-header-percent">({stageMarginPercent}%)</span></span>
                                                                                                </div>
                                                                                                <div className="fee-calculator-stage-header--stage-fee">
                                                                                                    <span className="stage-header-total-label">Client stage fee:</span>
                                                                                                    <span className="stage-header-total-amount">{`£${stageFee.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}`}</span>
                                                                                                </div>
                                                                                                <div className="fee-calculator-stage-header--stage-cost">
                                                                                                    <span className="stage-header-total-label">Total stage cost:</span>
                                                                                                    <span className="stage-header-total-amount">{`£${stageCost.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}`}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </label>
                                                                                    <div className={`content-accordion__content ${!feeCalculationDetailed?.inSync && !isLoadingPractice && canEdit && feeCalculationStageForm.values.feeCalculationStageTasks.some((feeCalculationStageTask: FeeCalculationStageTask) => {
                                                                                        return (feeCalculationStageTask.staffCosts.length > 0)
                                                                                        &&      (feeCalculationStageTask.staffCosts.some((staffCost) => {
                                                                                                    return staffCost.costRatePerHour !== ( practice?.members.find(x => x.id === staffCost.member.id)?.costRatePerHour ? 
                                                                                                        practice?.members.find(x => x.id === staffCost.member.id)?.costRatePerHour : 0)}))
                                                                                    }) ? 'sync-error' : ''}`}>
                                                                                        <div className="my-purchases__content mt-20 mb-20">
                                                                                            <FieldArray name={`feeCalculationStageTasks`}>{({ push, remove }) => (
                                                                                                <>

                                                                                                    {feeCalculationStageForm.values.feeCalculationStageTasks.map((feeCalculationStageTask: FeeCalculationStageTask, taskIndex) => {
                                                                                                        let taskCost = Number(calculateTaskCost(feeCalculationStageForm.values.feeCalculationStageTasks[taskIndex]) || 0);
                                                                                                        runInAction(() => {
                                                                                                            feeCalculationStageForm.values.feeCalculationStageTasks[taskIndex].totalTaskCost = taskCost
                                                                                                        })
                                                                                                        return (
                                                                                                            <div key={taskIndex} className="fee-calculator-task" >
                                                                                                                <FeeCalculationStageTaskForm
                                                                                                                    values={feeCalculationStageForm.values}
                                                                                                                    handleChange={(e: any) => {
                                                                                                                        runInAction(() => {
                                                                                                                            feeCalculationStageForm.handleChange(e)
                                                                                                                            setTasksDirty(true);
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    addTaskToDelete={addTaskToDelete}
                                                                                                                    addStaffCostToDelete={addStaffCostToDelete}
                                                                                                                    addFixedCostToDelete={addFixedCostToDelete}
                                                                                                                    removeTask={(taskId: number) => {
                                                                                                                        runInAction(() => {
                                                                                                                            remove(taskId); feeCalculationStageForm.handleSubmit()
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    canEdit={canEdit}
                                                                                                                    feeCalculationForm={feeCalculationForm}
                                                                                                                    feeCalculationStageForm={feeCalculationStageForm}
                                                                                                                    handleSubmit={feeCalculationStageForm.handleSubmit}
                                                                                                                    feeCalculationStage={feeCalculationStage}
                                                                                                                    stageIndex={stageIndex}
                                                                                                                    feeCalculationStageTask={feeCalculationStageTask}
                                                                                                                    taskIndex={taskIndex}
                                                                                                                    formInSync={feeCalculationDetailed?.inSync}
                                                                                                                ></FeeCalculationStageTaskForm>
                                                                                                                {feeCalculationStageTask?.fixedCosts?.length > 0 || feeCalculationStageTask?.staffCosts?.length > 0 ? (
                                                                                                                    <div style={{ border: `1px solid ${RibaStages[feeCalculationStage.stage.stageId].colour}` }} className="fee-calculator-task-summary">
                                                                                                                        <h3 className="fee-calculator-task-summary__title">{feeCalculationStage.stage.stageId}.{taskIndex + 1} Task summary:</h3>
                                                                                                                        <div className="fee-calculator-task-summary__form pr-40">
                                                                                                                            <div className="form-input-container ">
                                                                                                                                <div className="form-input-container__label--label-above">
                                                                                                                                    <div className="form-label">
                                                                                                                                        <div className="form-label__title">
                                                                                                                                            <div className="field-help tooltip-trigger" data-tip="React-tooltip" data-for="practiceMargin">
                                                                                                                                                <div className="field-help__icon" >
                                                                                                                                                    <span className="material-icons info-icon ">info</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            Practice margin:
                                                                                                                                            </div>
                                                                                                                                        <ReactTooltip
                                                                                                                                            type="light"
                                                                                                                                            place="right"
                                                                                                                                            effect="solid"
                                                                                                                                            className="react-tooltip"
                                                                                                                                            border={true}
                                                                                                                                            id='practiceMargin'
                                                                                                                                            textColor='#333f48' backgroundColor='#f9f9f9' borderColor='#f2f2f2'>
                                                                                                                                            <strong>Practice margin</strong><br />
                                                                                                                                            This is the profit that you add to the actual cost of delivering the tasks.
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className={`form-input-container__input--label-above form-input-container__input-focus--${RibaStages[feeCalculationStage.stage.stageId].stageId}`}>
                                                                                                                                    <div className="input-wrapper">
                                                                                                                                        <Field disabled={!canEdit} component={PercentageFormInput}
                                                                                                                                            id={`feeCalculationStageTasks[${taskIndex}].marginAsPercent`}
                                                                                                                                            type="number"
                                                                                                                                            name={`feeCalculationStageTasks[${taskIndex}].marginAsPercent`}
                                                                                                                                            onFocus={() => { toggleRightArrow(false); toggleLeftArrow(true) }}
                                                                                                                                            onChange={(e: any) => {
                                                                                                                                                runInAction(() => {
                                                                                                                                                    let thisTaskCost = Number(calculateTaskCost(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks[taskIndex]) || 0);
                                                                                                                                                    let thisTaskMarginPercent = Number(e.target.value.replace(/[^0-9.-]+/g, ""));
                                                                                                                                                    feeCalculationStageForm.setFieldValue(`feeCalculationStageTasks[${taskIndex}].clientTaskFee`, Math.round((thisTaskCost + (thisTaskCost * Number(thisTaskMarginPercent / 100)))), false);
                                                                                                                                                })
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-input-container__direction--wrapper ">
                                                                                                                                <div className="form-input-container__direction--icon">
                                                                                                                                    {showLeftArrow && (
                                                                                                                                        <i style={{ color: `${RibaStages[feeCalculationStage.stage.stageId].colour}` }} className="material-icons">arrow_forward</i>
                                                                                                                                    )}
                                                                                                                                    {showRightArrow && (
                                                                                                                                        <i style={{ color: `${RibaStages[feeCalculationStage.stage.stageId].colour}` }} className="material-icons">arrow_back</i>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-input-container ">
                                                                                                                                <div className="form-input-container__label--label-above">
                                                                                                                                    <div className="form-label">
                                                                                                                                        <div className="form-label__title">
                                                                                                                                            <div className="field-help tooltip-trigger" data-tip="React-tooltip" data-for="clientTaskFee">
                                                                                                                                                <div className="field-help__icon" >
                                                                                                                                                    <span className="material-icons info-icon ">info</span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            Client task fee:
                                                                                                                                            </div>
                                                                                                                                        <ReactTooltip
                                                                                                                                            type="light"
                                                                                                                                            place="right"
                                                                                                                                            effect="solid"
                                                                                                                                            className="react-tooltip"
                                                                                                                                            border={true}
                                                                                                                                            id='clientTaskFee'
                                                                                                                                            textColor='#333f48' backgroundColor='#f9f9f9' borderColor='#f2f2f2'>
                                                                                                                                            <strong>Client task fee</strong><br />
                                                                                                                                            This the fee that you will be charging your client.
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className={`form-input-container__input--label-above form-input-container__input-focus--${RibaStages[feeCalculationStage.stage.stageId].stageId}`}>
                                                                                                                                    <div className="input-wrapper">
                                                                                                                                        <Field disabled={!canEdit} component={CurrencyFormInput}
                                                                                                                                            id={`feeCalculationStageTasks[${taskIndex}].clientTaskFee`}
                                                                                                                                            type="number"
                                                                                                                                            name={`feeCalculationStageTasks[${taskIndex}].clientTaskFee`}
                                                                                                                                            onFocus={() => { setEditingTaskFee(true); toggleRightArrow(true); toggleLeftArrow(false) }}
                                                                                                                                            onBlur={() => { setEditingTaskFee(false) }}
                                                                                                                                            onKeyDown={(e: any) => {
                                                                                                                                                if (e.key === "." || e.key === "-") {
                                                                                                                                                    e.preventDefault ? e.preventDefault() : e.returnValue = false;
                                                                                                                                                }
                                                                                                                                            }}
                                                                                                                                            value={feeCalculationStageTask.clientTaskFee}
                                                                                                                                            onChange={(e: any) => {
                                                                                                                                                let thisTaskCost = Number(calculateTaskCost(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks[taskIndex]) || 0);
                                                                                                                                                let thisTaskFee = Number(e.target.value.replace(/[^0-9.-]+/g, ""));
                                                                                                                                                feeCalculationStageForm.setFieldValue(`feeCalculationStageTasks[${taskIndex}].marginAsPercent`, Number((thisTaskFee - thisTaskCost) / thisTaskCost * 100), false);
                                                                                                                                            }} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="form-input-container ">
                                                                                                                                <div className="form-input-container__label--label-above">
                                                                                                                                    <div className="form-label">
                                                                                                                                        <div className="form-label__title">Total task cost:</div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="form-input-container__input--label-above">
                                                                                                                                    <div className="input-wrapper">
                                                                                                                                        <NumberFormat thousandSeparator
                                                                                                                                            style={{ textAlign: 'right' }}
                                                                                                                                            allowEmptyFormatting={true}
                                                                                                                                            prefix='£'
                                                                                                                                            disabled={true}
                                                                                                                                            id={`feeCalculationStageTasks[${taskIndex}].totalTaskCost`}
                                                                                                                                            name={`feeCalculationStageTasks[${taskIndex}].totalTaskCost`}
                                                                                                                                            value={taskCost.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}
                                                                                                                                            className="singleline-text-input singleline-text-input" />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fee-calculator-task-note pr-10">
                                                                                                                            {(feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks[taskIndex].note || feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks[taskIndex].showTaskNote) ? (
                                                                                                                                <>
                                                                                                                                    <strong>Notes:</strong>
                                                                                                                                    <div className="form-input-container form-input-container--note">
                                                                                                                                        <div className="form-input-container__input--full-width">
                                                                                                                                            <div className="input-wrapper">
                                                                                                                                                <Field component={FormTextAreaInputTaskNote} hasError={getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].note`)} id={`feeCalculationStageTasks[${taskIndex}].note`} type="number" name={`feeCalculationStageTasks[${taskIndex}].note`} value={feeCalculationForm.values.feeCalculationStages?.[stageIndex].feeCalculationStageTasks[taskIndex].note} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        {canEdit && (<button type="button" title="delete task note" onClick={() => {
                                                                                                                                            feeCalculationStageForm.setFieldValue(`feeCalculationStageTasks[${taskIndex}].note`, '', false);
                                                                                                                                            feeCalculationStageForm.setFieldValue(`feeCalculationStageTasks[${taskIndex}].showTaskNote`, false, false);
                                                                                                                                            feeCalculationStageForm.submitForm();
                                                                                                                                        }}><i className="material-icons">delete</i></button>)}
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                    <>
                                                                                                                                        {canEdit && (<div title="add task note" className="text-link" onClick={() => {
                                                                                                                                            feeCalculationStageForm.setFieldValue(`feeCalculationStageTasks[${taskIndex}].showTaskNote`, true, false);
                                                                                                                                            feeCalculationStageForm.submitForm();
                                                                                                                                        }} ><i className="material-icons">add</i><span>Add a note</span></div>)}
                                                                                                                                    </>
                                                                                                                                )}

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : null}

                                                                                                                <button type="button" className="button button--opaque mt-20" disabled={disableButtons}
                                                                                                                onClick={() => {
                                                                                                                    setDisableButtons(true);
                                                                                                                    feeCalculationForm.validateForm(feeCalculationForm.values).then((errors: FormikErrors<FeeCalculation>) => {
                                                                                                                        setTasksDirty(true);
                                                                                                                        if (!errors || errors !== {}) {
                                                                                                                            feeCalculationForm.handleSubmit()
                                                                                                                            setTasksDirty(false);
                                                                                                                        } else {
                                                                                                                            feeCalculationForm.setErrors(errors);
                                                                                                                        }
                                                                                                                        setTimeout(() =>setDisableButtons(false), 1000)
                                                                                                                    })
                                                                                                                }}>
                                                                                                                <span>Save task</span>
                                                                                                            </button>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                    {canEdit && (<button type="button" className="button button--opaque" onClick={() => { push(new FeeCalculationStageTask()); feeCalculationStageForm.handleSubmit() }}>Add {feeCalculationStageForm.values.feeCalculationStageTasks.length > 0 && ('another ')}task</button>)}
                                                                                                </>
                                                                                            )}
                                                                                            </FieldArray>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>
                                                                        </Form>
                                                                    )
                                                                }}
                                                            </Formik>
                                                        ) : null

                                                    })
                                                }
                                                <div className="fee-calculator-footer">
                                                    <h2 className="form-heading__title">Total project fee calculation:</h2>
                                                    <div className="row">
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-20">
                                                            <div className="form-input-container ">
                                                                <div className="form-input-container__label--label-above">
                                                                    <div className="form-label">
                                                                        <div className="form-label__title text-right">Construction cost:</div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-input-container__input--label-above">
                                                                    <div className="input-wrapper">
                                                                        <Field component={CurrencyFormInput} disabled={!canEdit} value={feeCalculationForm.values.constructionCost} id="constructionCost" type="number" name="constructionCost" placeholder="" />
                                                                    </div>
                                                                </div>
                                                                <p className="text-right">Your fee as a % of construction cost: {calculateConstructionPerc(feeCalculationForm.values)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-20">
                                                            <div className="form-input-container ">
                                                                <div className="form-input-container__label--label-above">
                                                                    <div className="form-label">
                                                                        <div className="form-label__title text-right">Total margin/profit:</div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-input-container__input--label-above">
                                                                    <div className="input-wrapper">
                                                                        <div className="form-value-container__value">
                                                                            <div className="form-value">
                                                                                £{(calculateProjectFee(feeCalculationForm.values) - calculateProjectCost(feeCalculationForm.values)).toLocaleString(navigator.language, { maximumFractionDigits: 0 })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p className="text-right mr-10">Your profit is {calculateTotalMarginPerc(feeCalculationForm.values)}%</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-20">
                                                            <div className="form-input-container ">
                                                                <div className="form-input-container__label--label-above">
                                                                    <div className="form-label">
                                                                        <div className="form-label__title text-right">Total client fee:</div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-input-container__input--label-above">
                                                                    <div className="input-wrapper">
                                                                        <div className="form-value-container__value">
                                                                            <div className="form-value">
                                                                                £{calculateProjectFee(feeCalculationForm.values).toLocaleString(navigator.language, { maximumFractionDigits: 0 })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p className="text-right mr-10">VAT excluded</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-20">
                                                            <div className="form-input-container ">
                                                                <div className="form-input-container__label--label-above">
                                                                    <div className="form-label">
                                                                        <div className="form-label__title text-right">Total practice cost:</div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-input-container__input--label-above">
                                                                    <div className="input-wrapper">
                                                                        <div className="form-value-container__value">
                                                                            <div className="form-value">
                                                                                £{calculateProjectCost(feeCalculationForm.values).toLocaleString(navigator.language, { maximumFractionDigits: 0 })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="join-riba-form-content__actions">
                                                    {canEdit && (<button type="button" disabled={disableButtons} onClick={() => { setDisableButtons(true); feeCalculationForm.handleSubmit(); setTasksDirty(false); setTimeout(() =>setDisableButtons(false), 1000); }} className="button button--opaque">Save</button>)}
                                                </div>
                                            </>


                                        )
                                    }}
                                </Formik>
                            </>
                        )}

                </>
            )}
        </>


    )
});

export default FeeCalculationDetailsForm;