import axios, { AxiosResponse } from 'axios';
import { history } from '..';
import Config from '../config';

var instance = axios.create({
    baseURL: Config.MFA_API_URL,
    timeout: 30000,
    withCredentials: true
});

instance.interceptors.request.use((config) => {
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    setTimeout(() => source.cancel('Timed out after 30s'), 30000);
    return config
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(undefined, error => {
    if (error.message === 'Network Error' && !error.response) {
        history.push('/error')
    }

    const { status } = error.response || 'No error.response';

    if (status === 404) {
        history.push('/error/404')
    }

    throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const validate = '/validate';

const requests = {
    get: (url: string) => instance.get(`${url}`).then(responseBody)
}

const ValidateMethods = {
    validate: (cubeMemberId: number, deviceId: string): Promise<any> => requests.get(`${validate}?deviceId=${deviceId}`),
}

export default { ValidateMethods }