import React, { FC } from "react";
import { FieldProps } from "formik";

interface FormTextAreaInputProps {
    handleChange: () => any,
    helptext?: string,
    hasError? : string
}

const FormTextAreaInputTaskNote: FC<FormTextAreaInputProps & FieldProps> = ({
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue, setFieldTouched, handleChange, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
        <div className="input-wrapper">
            <textarea data-gramm_editor="false" {...field} {...props}
                onChange={e => {
                    setFieldValue(field.name, e.target.value);
                    setTimeout(() => setFieldTouched(field.name, true));
                    handleChange(e)
                }} className={props.hasError ? 'singleline-text-input singleline-text-input--full-width singleline-text-input--error ' : 'singleline-text-input singleline-text-input--full-width'}/>
            {props.helptext && (
                <div className="input-wrapper__tip">{props.helptext}</div>
            )}
            {props.hasError && <div className="validation-summary-errors">
                    <ul>
                        <li>{props.hasError }</li>
                    </ul>
                </div>
            }
        </div>
    );

export default FormTextAreaInputTaskNote;


