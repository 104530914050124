import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import FormInput from '../molecules/FormInput';
import { EditPracticeFormValidation } from '../../utils/validation/EditPracticeFormValidation';
import FormLabel from '../molecules/FormLabel';
import { Formik, Form, Field, useFormikContext, getIn } from "formik";
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import PromptIfDirty from '../molecules/PromptIfDirty';
import { Practice } from '../../types/practice';
import Alert from '../molecules/Alert';

type EditPracticeForm = {
    handleSubmit: () => any,
    handleBack: () => any,
    practice: Practice | null,
    confirmButtonText: string,
    cancelButtonText: string
}

const EditPracticeForm: React.FC<EditPracticeForm> = observer((props) => {
    const { practice, handleBack, handleSubmit, confirmButtonText, cancelButtonText } = props;
    const rootStore = useContext(RootStoreContext);
    const { member } = rootStore.memberStore;
    const { editPractice } = rootStore.practiceStore;
    const { pathname } = useLocation();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isDirtyForm, setIsDirtyForm] = useState(false);
    const [confirmButton] = useState(confirmButtonText);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    

    const editPractice1 = (practice: any) => {
        editPractice(practice).then(() => {
                handleSubmit();
        });
    }

    return (
        <>

            {practice && (
                <>
                    <div className="form-heading">
                        <h2 className="form-heading__title">Edit Practice Details</h2>
                    </div>
                        <div className="content-container"> 
                            <Formik
                                initialValues={practice}
                                enableReinitialize={true}
                                validationSchema={EditPracticeFormValidation}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={(values, formik) => {
                                    editPractice1(values);
                                }}>
                                {(practiceForm) => (
                                    <>
                                        <Form
                                            onKeyDown={(keyEvent) => {
                                                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                                    keyEvent.preventDefault();
                                                }
                                            }}
                                            onSubmit={(e) => { e.preventDefault(); }}
                                            onChange={() => { setFormSubmitted(false) }}>
                                            <PromptIfDirty isDirtyForm={isDirtyForm} setIsDirtyForm={setIsDirtyForm}  />
                                            <div className="join-riba-form-content__section">
                                                <div className="form-heading ">
                                                    <h2 className="form-heading__title">Practice details</h2>
                                                    <div className="divider divider--dark"></div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Name'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="name" onChange={(e: any) => { practiceForm.handleChange(e) }} type="text" name="name" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Address Line One'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="addressLineOne" onChange={(e: any) => { practiceForm.handleChange(e) }} type="text" name="addressLineOne" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Address Line Two'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="addressLineTwo" onChange={(e: any) => { practiceForm.handleChange(e) }} type="text" name="addressLineTwo" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Address Line Three'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="addressLineThree" onChange={(e: any) => { practiceForm.handleChange(e) }} type="text" name="addressLineThree" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Postcode'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="postcode" onChange={(e: any) => { practiceForm.handleChange(e) }} type="text" name="postcode" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>

                                             {/* {JSON.stringify(practiceMemberForm.errors, null, 4)} */}
                                             {Object.keys(practiceForm.errors).length > 0 &&
                                                Object.keys(practiceForm.touched).length > 0 &&
                                                formSubmitted && (
                                                    <Alert text='Please correct the errors highlighted above' styleClass='alert alert--error' title='Error' />
                                                )}

                                            

                                            <div className="join-riba-form-content__actions">
                                                <button className="button" onClick={handleBack}>{cancelButtonText}</button>
                                                <button onClick={() => { setFormSubmitted(true); practiceForm.submitForm() }} className="button button--opaque ">{confirmButton}</button>
                                            </div>
                                        </Form>
                                    </>

                                )}
                            </Formik>
                        </div>
                </>
            )}
        </>

    )
});

export default EditPracticeForm;