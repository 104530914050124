import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import FormInput from '../molecules/FormInput';
import { AddEditMemberFormValidation } from '../../utils/validation/AddEditMemberFormValidation';
import Alert from '../molecules/Alert';
import Modal from 'react-modal';
import FormLabel from '../molecules/FormLabel';
import { Formik, Form, Field, useFormikContext, getIn } from "formik";
import { observer } from 'mobx-react-lite';
import { Member } from '../../types/member';
import { useLocation } from 'react-router-dom';
import { runInAction } from 'mobx';
import CurrencyFormInput from '../molecules/CurrencyFormInput';
import PromptIfDirty from '../molecules/PromptIfDirty';

type AddEditMemberFormProps = {
    handleSubmit: () => any,
    handleBack: () => any,
    practiceMember: Member | null,
    confirmButtonText: string,
    cancelButtonText: string
}

const AddEditMemberForm: React.FC<AddEditMemberFormProps> = observer((props) => {
    const { practiceMember, handleBack, handleSubmit, confirmButtonText, cancelButtonText } = props;
    const rootStore = useContext(RootStoreContext);
    const { member } = rootStore.memberStore;
    const { editPracticeMember, createPracticeMember, getPracticeMember, practiceMemberErrors } = rootStore.practiceStore;
    const { pathname } = useLocation();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [isDirtyForm, setIsDirtyForm] = useState(false);
    const [confirmButton, setConfirmButton] = useState(confirmButtonText);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const savePracticeMember = (practiceMember: any) => {
        setIsDirtyForm(false);
        runInAction(() => {
            practiceMember.active = true;
        })
        if (practiceMember.id > 0) {
            getPracticeMember(practiceMember.id).then((oldMemberData: any) => {
                if ((oldMemberData.accessLevel === 'Staff' || oldMemberData.accessLevel === 'NonUser') && practiceMember.accessLevel === 'Admin') {
                    setShowAdminModal(true);
                } else {
                    editMember(practiceMember);
                }
            })
        } else if (practiceMember.id === 0) {
            createPracticeMember(practiceMember).then(() => {
                if (practiceMemberErrors.length === 0) {
                    handleSubmit();
                }
            });
        }
    }

    const editMember = (practiceMember: any) => {
        editPracticeMember(practiceMember).then(() => {
            if (practiceMemberErrors.length === 0) {
                handleSubmit();
            }
        });
    }

    const handleAccessRightChange = (e:any) => {
        setConfirmButton(e.target.value != 'NonUser' ? (confirmButtonText + ' and invite user') : confirmButtonText)
    }

    const CalculateValuesComponent = () => {
        const { values, setFieldValue } = useFormikContext<any>();
        const fieldValues = [getIn(values, 'annualSalary'), getIn(values, 'otherBenefits')]
        const initialRender = useRef(true);

        useEffect(() => {
            if (!initialRender.current) {
                var val = Number(values?.annualSalary) +
                    Number(values?.otherBenefits);
                setFieldValue('totalSalaryPackage', val);
            } else {
                initialRender.current = false;
            }
        }, fieldValues);

        return null;
    }

    return (
        <>

            {practiceMember && (
                <>
                    {practiceMemberErrors && practiceMemberErrors.map((practiceMemberError, index) => (
                        <Alert key={index} text={practiceMemberError} styleClass='alert alert--error' title='Error saving user' />
                    ))[0]}

                    <div className="form-heading">
                        <h2 className="form-heading__title">Add staff salary details</h2>
                        <p className="mb-40">Add your staff details, salary information, and working hours using the table below. Staff costs will also be included in your fee calculations. You can assign staff to certain projects later in the process. You can edit these details later by changing your settings. </p></div>
                        <div className="content-container"> 
                            <Formik
                                initialValues={practiceMember}
                                enableReinitialize={true}
                                validationSchema={AddEditMemberFormValidation}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={(values, formik) => {
                                    savePracticeMember(values);
                                }}>
                                {(practiceMemberForm) => (
                                    <>
                                        <CalculateValuesComponent />
                                        <Form
                                            onKeyDown={(keyEvent) => {
                                                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                                    keyEvent.preventDefault();
                                                }
                                            }}
                                            onSubmit={(e) => { e.preventDefault(); }}
                                            onChange={() => { setFormSubmitted(false) }}>
                                            <PromptIfDirty isDirtyForm={isDirtyForm} setIsDirtyForm={setIsDirtyForm}  />
                                            <div className="join-riba-form-content__section">
                                                <div className="form-heading ">
                                                    <h2 className="form-heading__title">Personal details</h2>
                                                    <div className="divider divider--dark"></div>
                                                    <p>Admins can see and edit all staff salary details.</p>
                                                </div>

                                                <div className="form-input-container mt-20 mb-10">
                                                    <div className="form-input-container__label">
                                                        <div className="form-label">
                                                            <div className="form-label__title">Title</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <div className="input-wrapper">
                                                            <Field as="select" name="title" className={practiceMemberForm.touched.title && practiceMemberForm.errors.title ? 'dropdown-input dropdown-input--error' : 'dropdown-input'}>
                                                                <option value="">Select</option>
                                                                <option value="Mr">Mr</option>
                                                                <option value="Mrs">Mrs</option>
                                                                <option value="Ms">Ms</option>
                                                                <option value="Dr">Dr</option>
                                                            </Field>
                                                            {practiceMemberForm.touched.title &&
                                                                practiceMemberForm.errors.title && <div className="validation-summary-errors">
                                                                    <ul>
                                                                        <li>{practiceMemberForm.errors.title}</li>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'First name(s)'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="firstName" onChange={(e: any) => { practiceMemberForm.handleChange(e) }} type="text" name="firstName" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Last name'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="lastName" onChange={(e: any) => { practiceMemberForm.handleChange(e) }} type="text" name="lastName" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Job title'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={FormInput} id="jobTitle" onChange={(e: any) => { practiceMemberForm.handleChange(e) }} type="text" name="jobTitle" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Email'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                    <Field disabled={practiceMember.id > 0} component={FormInput} serverErrors={practiceMemberErrors.length > 0} id="email" onChange={(e: any) => { practiceMemberForm.handleChange(e) }} type="text" name="email" placeholder="" />                                                    </div>
                                                </div>
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Access rights'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <div className="input-wrapper">
                                                            <Field as="select" name="accessLevel"
                                                                onChange={(e: any) => { practiceMemberForm.handleChange(e); handleAccessRightChange(e) }} 
                                                                disabled={practiceMember.id === member?.id}
                                                                className={(practiceMemberForm.touched.accessLevel && practiceMemberForm.errors.accessLevel) || practiceMemberErrors.length > 0 ? 'dropdown-input dropdown-input--error' : 'dropdown-input'}>
                                                                <option value="NonUser">None</option>
                                                                <option value="Staff">Staff</option>
                                                                <option value="Admin">Admin</option>
                                                            </Field>
                                                            {(practiceMemberForm.touched.accessLevel && practiceMemberForm.errors.accessLevel) || practiceMemberErrors.length > 0 ? (
                                                                <div className="validation-summary-errors">
                                                                    <ul>
                                                                        <li>{practiceMemberForm.errors.accessLevel}</li>
                                                                    </ul>
                                                                </div>
                                                            ) : null
                                                            }
                                                            <div className="input-wrapper__tip">Admin users can see and edit all staff salary details and overhead costs.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="join-riba-form-content__section">
                                                <div className="form-heading ">
                                                    <h2 className="form-heading__title">Billable status </h2>
                                                    <div className="divider divider--dark"></div>
                                                    <p>Working weeks each year, excluding annual holiday allowance. <a href="/help" target="_BLANK" rel="noreferrer">Read our guidance</a> for help and information on billable time, salaries and benefits.</p>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Is this staff member billable or non-billable?'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <div className="radio-button-group ">

                                                            <label className="radio-button__group ">
                                                                <Field type="radio" value="Billable" name="billableStatus" className="radio-button__input" />
                                                                <i className="radio-button__radio"></i>
                                                                <div className="radio-button__label">
                                                                    <p className="radio-button__text">Billable</p>
                                                                </div>
                                                            </label>

                                                            <label className="radio-button__group ">
                                                                <Field type="radio" value="NonBillable" name="billableStatus" className="radio-button__input" />
                                                                <i className="radio-button__radio"></i>
                                                                <div className="radio-button__label">
                                                                    <p className="radio-button__text">Non-billable</p>
                                                                </div>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>

                                                {practiceMemberForm.values.billableStatus === 'Billable' && (
                                                    <>
                                                        <div className="form-input-container ">
                                                            <div className="form-input-container__label">
                                                                <FormLabel labelText={'Working hours each week'} />
                                                            </div>
                                                            <div className="form-input-container__input">
                                                                <Field component={FormInput} id="workingHoursPerWeek" onChange={(e: any) => { practiceMemberForm.handleChange(e) }} type="number" name="workingHoursPerWeek" placeholder="" />
                                                            </div>
                                                        </div>

                                                        <div className="form-input-container ">
                                                            <div className="form-input-container__label">
                                                                <FormLabel labelText={'Working weeks each year'} />
                                                            </div>
                                                            <div className="form-input-container__input">
                                                                <Field component={FormInput} id="workingWeeksPerYear" onChange={(e: any) => { practiceMemberForm.handleChange(e) }} type="number" name="workingWeeksPerYear" placeholder="" />
                                                            </div>
                                                        </div>

                                                        <div className="form-input-container ">
                                                            <div className="form-input-container__label">
                                                                <FormLabel labelText={'Billable hours (%)'} />
                                                            </div>
                                                            <div className="form-input-container__input">
                                                                <Field component={FormInput} id="billableHours" onChange={(e: any) => { practiceMemberForm.handleChange(e) }} type="number" name="billableHours" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <div className="join-riba-form-content__section">
                                                <div className="form-heading ">
                                                    <h2 className="form-heading__title">Annual salary </h2>
                                                    <div className="divider divider--dark"></div>
                                                    <p>Including this information will help you generate accurate fee calculations. You can edit these details later by adjusting your settings.</p>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Annual salary'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="annualSalary" type="number" name="annualSalary" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Other benefits'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="otherBenefits" type="number" name="otherBenefits" placeholder="" />
                                                    </div>
                                                </div>

                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Total salary'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field disabled={true} component={CurrencyFormInput} id="totalSalaryPackage" type="number" name="totalSalaryPackage" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {JSON.stringify(practiceMemberForm.errors, null, 4)} */}
                                            {Object.keys(practiceMemberForm.errors).length > 0 &&
                                                Object.keys(practiceMemberForm.touched).length > 0 &&
                                                formSubmitted && (
                                                    <Alert text='Please correct the errors highlighted above' styleClass='alert alert--error' title='Error' />
                                                )}

                                            <div className="join-riba-form-content__actions">
                                                <button className="button" onClick={handleBack}>{cancelButtonText}</button>
                                                <button onClick={() => { setFormSubmitted(true); practiceMemberForm.submitForm() }} className="button button--opaque ">{confirmButton}</button>
                                            </div>
                                            <Modal
                                                appElement={document.getElementById('root') as HTMLElement}
                                                isOpen={showAdminModal}
                                                onRequestClose={() => setShowAdminModal(false)}
                                                overlayClassName="Overlay"
                                                contentLabel="Assigning admin access rights to staff members"
                                                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                                                <div className="dashboard-modal">

                                                    <>
                                                        <span onClick={() => setShowAdminModal(false)} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                                        <h1 className="dashboard-modal__title">Assigning admin access rights to staff members</h1>
                                                        <div className="whitespace whitespace--single"></div>
                                                        <Alert title='Warning' styleClass='alert alert--warning' text='Admin users can view and edit staff salary details and company overheads' />
                                                        <p className="dashboard-modal__subtext">{`You are about to assign Admin access to ${practiceMember?.firstName} ${practiceMember?.lastName} `}</p>
                                                        <div className="whitespace whitespace--single"></div>
                                                        <div className="cta-band__buttons">
                                                            <button onClick={() => setShowAdminModal(false)} className="button  cta-band__button">Cancel</button>
                                                            <button onClick={() => { editMember(practiceMemberForm.values) }} className="button button--opaque cta-band__button">Assign Admin access</button>
                                                        </div>
                                                    </>

                                                </div>
                                            </Modal>
                                        </Form>
                                    </>

                                )}
                            </Formik>
                        </div>
                </>
            )}
        </>

    )
});

export default AddEditMemberForm;