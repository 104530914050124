import React from 'react';

type AlertProps = {
    title?: string,
    text: string,
    styleClass: string,
}

const Alert: React.FC<AlertProps> = (props) => {

    const { styleClass, title, text } = props;

    return (
        <div className={styleClass}>
            {title && (
                <div className="alert__header">{title}</div>
            )}
            <div className="alert__content">{text}</div>
        </div>
    );
}

export default Alert;
