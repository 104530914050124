import React, { useEffect, useContext, useState } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import PracticeOverheadsForm from '../../organisms/PracticeOverheadsForm';
import { observer } from 'mobx-react-lite';
import SettingsSideNavigation from '../../organisms/SettingsSideNavigation';
import Loading from '../../organisms/Loading';
import Alert from '../../molecules/Alert';
import { history } from '../../../';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';
import { MemberAccessLevels } from '../../../utils/Constants';

const AnnualOverheads: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { getPractice, isLoadingPractice } = rootStore.practiceStore;
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const { member } = rootStore.memberStore;

    useEffect(() => {
        if (member?.accessLevel !== MemberAccessLevels.ADMIN) { 
            history.push('/projects');
        }

    }, [member]);

    useEffect(() => {
        let isLoaded = false;
        setSuccessAlert(false)
        getPractice().finally(() => {
        })

        return () => {
            isLoaded = true;   // clean up function
        };

    }, [getPractice]);

    const resetOverheads = () => {
        getPractice().finally(() => {
        })
    }

    const handleSubmit = () => {
        setSuccessAlert(true)
    }

    return (
        <>
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <SideNavigationMobile />
                    <SettingsSideNavigation currentPage={2} />
                    {isLoadingPractice ? (
                        <Loading />
                    ) : (
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30">
                                            {showSuccessAlert && (
                                                <Alert title="Success" text="Changes to overhead costs successfully applied. Please note that this might affect your fee calculations and staff cost rates." styleClass="alert alert--success" />
                                            )}
                                            <PracticeOverheadsForm
                                                title='Annual overhead costs'
                                                confirmButtonText="Apply changes"
                                                cancelButtonText="Reset"
                                                handleSubmit={handleSubmit}
                                                handleBack={resetOverheads}
                                                isSettingsPage={true}>
                                                <p>Your annual overhead costs are the annual ongoing costs of running your business. The costs shown below were entered when your practice was set up on the RIBA Fee Calculator.</p>
                                                <p>Anything you add or edit here will impact your fee calculations, so make sure you use accurate information, and refer to our <a target="_BLANK" href="/help">guidance notes</a> for help.</p>
                                            </PracticeOverheadsForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
})

export default AnnualOverheads;