import React, { FC } from "react";
import { FieldProps } from "formik";
import NumberFormat from "react-number-format";

interface CurrencyFormInputProps {
    type?: string,
    handleChange: () => any,
    onFocus?: () => any,
    helptext?: string,
}

const PercentageFormInput: FC<CurrencyFormInputProps & FieldProps> = ({
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue, setFieldTouched, handleChange, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    type = "text",
    ...props
}) => (
        <div className="input-wrapper">
            <NumberFormat 
                style={{textAlign:'right'}}
                {...field} {...props}
                className={touched[field.name] && errors[field.name] ? 'singleline-text-input singleline-text-input--full-width singleline-text-input--error ' : 'singleline-text-input singleline-text-input--full-width'}
                name={field.name}
                thousandSeparator
                allowEmptyFormatting={true}
                suffix='%'
                onFocus={props.onFocus}
                decimalScale={1}
                min={1}
                defaultValue={""}
                value={field.value == 0 ? "" : field.value}
                onValueChange={val =>
                    setFieldValue(field.name, val.floatValue || 0)
                }
            />
            {props.helptext && (
                <div className="input-wrapper__tip">{props.helptext}</div>
            )}
            {touched[field.name] &&
                errors[field.name] && <div className="validation-summary-errors">
                    <ul>
                        <li>{errors[field.name]}</li>
                    </ul>
                </div>
            }
        </div>
    );

export default PercentageFormInput;


