import api from '../api/api';
import { RootStore } from './rootStore';
import { Practice } from '../types/practice';
import { Member } from '../types/member';
import { action, computed, observable, runInAction, toJS } from 'mobx';

export default class PracticeStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable practice: Practice | null = null;
    @observable practiceMember: Member | null = null;
    @observable isLoadingPractice = false;
    @observable practiceMemberErrors: Array<any> = [];

    @computed get isOnboarded() { return !!this.practice?.onboarded };
    
    @action unsetPractice = () => {
        runInAction(() => this.practice = null);
    }

    @action getPractice = async () => {
        this.isLoadingPractice = true;
        try {
            const practice = await api.PracticeMethods.getPractice();
            runInAction(() => {
                this.practice = practice;
                this.isLoadingPractice = false;
            });
            return practice;
        } catch (error) {
            runInAction(() => {
                this.isLoadingPractice = false;
            });
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoadingPractice = false;
            });
        }
    }

    @action loadPracticeMember = (practiceMember: any) => {
        this.isLoadingPractice = true;
        runInAction(() => {
            this.practiceMember = practiceMember;
            this.isLoadingPractice = false;
        })

        return this.practiceMember
    }

    @action getPracticeMember =  async (memberId: number) => {
        try {
            const practice : Practice = await api.PracticeMethods.getPractice();
            return practice.members.filter((member: Member)=> {return member.id === memberId})[0]
        } catch (error) {
            console.log(error);
        } finally {

        }
    }

    @action editPracticeMember = async (practiceMember: any) => {
        if (this.practice) {
            this.isLoadingPractice = true;
            var practice = this.practice;
            runInAction(()=>{
                this.practiceMember = practiceMember;
            });
            try {
                const editedMember: Member = await api.UserMethods.edit(practiceMember);
                runInAction('Edit practice member.', () => {
                    this.practiceMemberErrors = [];
                    this.practiceMember = editedMember;
                    this.isLoadingPractice = false;
                });

            } catch (error) {
                runInAction(() => {
                    Object.values(error.data.errors).forEach((practiceMemberError, index) => {
                        this.practiceMemberErrors.push(practiceMemberError);
                    })
                    this.isLoadingPractice = false;
                });
            } finally {
                runInAction(() => {
                    this.isLoadingPractice = false;
                });
            }
        }
    }

    @action getPracticeMemberErrors = () => {
        return this.practiceMemberErrors
    }

    @action clearPracticeMemberErrors = () => {
        runInAction(()=>{
            this.practiceMemberErrors = []; 
        })
        return this.practiceMemberErrors
    }

    @action createPracticeMember = async (practiceMember: any) => {
        if (this.practice) {
            this.isLoadingPractice = true;
            var practice = this.practice;
            runInAction(()=>{
                this.practiceMember = practiceMember;
            });
            try {
                const createdMember: Member = await api.UserMethods.create(practiceMember);
                runInAction('Edit practice member.', () => {
                    this.practiceMemberErrors = [];
                    this.practiceMember = createdMember;
                    this.isLoadingPractice = false;
                });

            } catch (error) {
                runInAction(() => {
                    Object.values(error.data.errors).forEach((practiceMemberError, index) => {
                        this.practiceMemberErrors.push(practiceMemberError);
                    })
                    this.isLoadingPractice = false;
                });
            } finally {
                runInAction(() => {
                    this.isLoadingPractice = false;
                });
            }
        }
    }

    @action editPractice = async (practice: Practice) => {
        this.isLoadingPractice = true;
        try {
            await api.PracticeMethods.edit(practice);

            runInAction('Edit practice.', () => {
                this.practice = practice;
                this.isLoadingPractice = false;
            });

        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoadingPractice = false;
            });
        } finally {
            runInAction(() => {
                this.isLoadingPractice = false;
            });
        }
    }

    @action deletePracticeMember = async (id: number) => {
        this.isLoadingPractice = true;

        try {
            await api.UserMethods.deleteMember(id);
            const practice = await api.PracticeMethods.getPractice();
            runInAction('Delete practice member.', async () => {
                this.practice = practice;
            });
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoadingPractice = false;
            });
        }
    }
}