
import { configure } from 'mobx';
import { createContext } from 'react';
import CommonStore from './commonStore';
import MemberStore from './memberStore';
import MfaStore from './mfaStore';
import PracticeStore from './practiceStore';
import ProjectStore from './projectStore';
import ClientStore from './clientStore';
import FeeCalculationStore from './feeCalculationStore';

configure({ enforceActions: 'always' });

export class RootStore {
    memberStore: MemberStore;
    mfaStore: MfaStore;
    commonStore: CommonStore
    practiceStore: PracticeStore;
    projectStore: ProjectStore;
    clientStore: ClientStore;
    feeCalculationStore: FeeCalculationStore;
    
    constructor() {
        this.memberStore = new MemberStore(this);
        this.mfaStore = new MfaStore(this);
        this.commonStore = new CommonStore(this);
        this.practiceStore = new PracticeStore(this);
        this.projectStore = new ProjectStore(this);
        this.clientStore = new ClientStore(this);
        this.feeCalculationStore = new FeeCalculationStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());