import React, { useEffect, useContext, useLayoutEffect } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { history } from '../..';
import { observer } from 'mobx-react-lite';
import { Project } from '../../types/project';
import { NavLink, useLocation } from 'react-router-dom';
import { FeeCalculation } from '../../types/feeCalculation';

type AddEditProjectFormProps = {
    handleSubmit: () => any,
    handleBack: () => any,
    project: Project | null,
    confirmButtonText: string,
    cancelButtonText: string
}

const ProjectDetailsForm: React.FC<AddEditProjectFormProps> = observer((props) => {
    const { project } = props;
    const rootStore = useContext(RootStoreContext);
    const { client, getClient } = rootStore.clientStore;
    const { createFeeDraftCalculation } = rootStore.feeCalculationStore;
    const { pathname } = useLocation();

    useEffect(() => {
        if (project?.clientId) {
            getClient(project?.clientId).finally(() => {
            });
        }
    }, [getClient, project]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const createDraftFeeCalculation = () => {
        //TODO: create fee calc draft in DB before navigating
        if(project){
            const feeCalc = new FeeCalculation({
                lastModified: new Date(),
                projectId: project.id,
                state:  'Draft'
            });
            
            createFeeDraftCalculation(feeCalc).then((createdFeeCalc : any) => {
                if(createdFeeCalc.id){
                    history.push(`/projects/${project.id}/fee-calculation-stages/${createdFeeCalc.id}`)
                }
            });
        }
    }


    return (
        <>
            {project && (
                <div className="join-riba-form-content__section">
                    <div className="form-heading ">
                        <h2 className="form-heading__title">{project?.name}</h2>
                        <p>Project status: <strong>{project?.status}</strong></p>
                    </div>
                    <div data-js-toggle-element="" className="join-riba-form-content__collapsableSection">
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Client name:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {client?.clientName}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container mb-40">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Contact name:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {client?.contactName}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Project name:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {project?.name}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Project reference:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {project?.number}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container mb-40">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Project status:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {project?.status}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Category:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {project?.category}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Sub-category:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {project?.subCategory}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container mb-40">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Other category:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                    {project?.tertiaryCategory}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Site address:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {project?.siteAddress}
                                </div>
                            </div>
                        </div>
                        <div className="form-value-container">
                            <div className="form-value-container__label">
                                <div className="form-label">
                                    <div className="form-label__title">
                                        Brief project description:
                                    </div>
                                </div>
                            </div>
                            <div className="form-value-container__value">
                                <div className="form-value">
                                   {project?.description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="project-detail-buttons">
                <NavLink className="button mr-20" to={`/projects/edit/${project?.id}`} title="Click to edit the project details">Edit project details</NavLink>
                <button onClick={()=>{createDraftFeeCalculation()}} className="button button--opaque mt-20 mb-20" title="Click to create a new fee calculation">Create new fee calculation</button>
            </div>
        </>

    )
});

export default ProjectDetailsForm;