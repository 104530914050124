import React, { useContext, useState, useEffect } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import { toJS } from 'mobx';
import Loading from '../../organisms/Loading';
import { history } from '../../..';
import { Project } from '../../../types/project';
import AddEditProjectForm from '../../organisms/AddEditProjectForm';
import ProjectSideNavigation from '../../organisms/ProjectSideNavigation';
import { parseConfigFileTextToJson } from 'typescript';
import Alert from '../../molecules/Alert';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';

interface AddEditProjectProps { feeCalculationId: string }

const DuplicateFeeCalculationProject: React.FC<RouteComponentProps<AddEditProjectProps>> = observer(({ match }) => {

    const rootStore = useContext(RootStoreContext);
    const { projects, project, getProject, isLoading } = rootStore.projectStore;
    const { duplicateFeeCalculation } = rootStore.feeCalculationStore;

    useEffect(() => {
        getProject('')
    }, [getProject]);

    const handleSubmit = (projectId: number) => {
        if (projectId) {
            duplicateFeeCalculation(match.params.feeCalculationId?.toString(), projectId?.toString()).then((feeCalculationCopy: any | undefined) => {
                if (feeCalculationCopy && feeCalculationCopy.feeCalculationId && feeCalculationCopy.projectId) {
                    history.push(`/projects/${feeCalculationCopy.projectId}/fee-calculation/${feeCalculationCopy.feeCalculationId}`)
                }
            })
        }
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                {isLoading ? (
                    <Loading />
                ) : (
                        <>
                            <SideNavigationMobile />
                            <ProjectSideNavigation project={project} currentPage={1} />
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30">
                                            <Alert title='Duplicate fee calculation' styleClass='alert alert--information' text='First you need to provide details for the new project. Then you will access the duplicated fee calculation.' />
                                            <AddEditProjectForm
                                                confirmButtonText={'Submit'}
                                                cancelButtonText={'Cancel'}
                                                project={project}
                                                handleSubmit={handleSubmit}
                                                handleBack={handleSubmit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </div>
    )

})

export default DuplicateFeeCalculationProject;