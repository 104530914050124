import React from 'react';
import Button from '../atoms/Button';

const MobileLanding: React.FC = () => {
    return (
        <div className="narror-content-container">
            <div className="content-container">
                <div className="heading-one">
                    <h1 className="heading-one__heading">Error - unsupported browser</h1>
                </div>
                <div className="subtitle">
                    <h2 className="subtitle__title">This RIBA service can't be used with your current web browser.</h2>
                </div>
                <p>We recommend downloading or switching to <a href="https://www.google.com/intl/en_uk/chrome/">Chrome</a>, <a href="https://www.mozilla.org/en-GB/firefox/new/">Firefox</a>, or <a href="https://www.apple.com/uk/safari/">Safari</a> for the best architecture.com experience</p>
                <p><strong>For help using this service, your online RIBA account, or other RIBA websites:</strong></p>
                <p>
                    Email <a href="mailto:support@riba.org">support@riba.org</a><br/>
                    Phone <a href="tel:+44(0)2073075355">+44 (0)20 7307 5355</a><br/>
                    Monday to Friday, 9am to 5pm<br/>
                </p>
                <br/>
                <p><strong>For questions regarding your membership, contact Membership Services:</strong></p>
                <p>
                    Email <a href="mailto:membership.services@riba.org">membership.services@riba.org</a><br/>
                    Phone <a href="tel:+44(0)2073073800">+44 (0)20 7307 3800</a><br/>
                    Monday to Friday, 9am to 5pm<br/>
                </p>
                <br/>
                <p><strong>For general enquiries about architecture or the RIBA, contact our Information Centre:</strong></p>
                <p>
                    Email <a href="mailto:info@riba.org">info@riba.org</a><br/>
                    Phone <a href="tel:+44(0)2073073600">+44 (0)20 7307 3600</a><br/>
                    Monday to Friday, 10am to 1pm and 2pm to 5pm<br/>
                </p>
                <br/>
                <p>You can also follow <a href="https://twitter.com/RIBA">@RIBA on Twitter</a> for updates.</p>
                <Button linkTo='https://www.architecture.com' externalLink={true} labelText='Back to architecture.com' styleClass='button button--opaque mt-20 mb-30' />
                <p></p>
            </div>
        </div>
    )
}

export default MobileLanding;