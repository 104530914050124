import * as Yup from 'yup';

export const PracticeOverheadsFormValidation = Yup.object().shape({
    rentAndServiceCharge: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    utilities: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    softwareAndIT: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    marketingCosts: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    companyCarFuelAllowance: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    travelCost: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    auditFeesAndAccountancy: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    bankChargesAndInterest: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    professionalIndemnityInsurance: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    otherInsuranceCosts: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    legalCosts: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    depreciation: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    badDebts: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    otherAnnualCosts: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    officeITAndCommunications: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    pensionCosts: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    nationalInsuranceContribution: Yup.number()
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number'),
    totalAnnualOverheadCosts: Yup.number()
        .required('Please complete this mandatory field.')
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        .moreThan(-1, 'Field must be a positive number')
        .moreThan(0, 'Total annual overheads must be greater than zero'),
});