import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import AddEditMemberForm from '../../organisms/AddEditMemberForm';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../organisms/Loading';
import { history } from '../../../';
import { Member } from '../../../types/member';
import SettingsSideNavigation from '../../organisms/SettingsSideNavigation';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';
import { MemberAccessLevels } from '../../../utils/Constants';

interface SetupStaffProps { id: string }

const AddEditMemberSettings: React.FC<RouteComponentProps<SetupStaffProps>> = observer(({ match }) => {

    const rootStore = useContext(RootStoreContext);
    const { practice, getPractice, practiceMember, loadPracticeMember, isLoadingPractice, clearPracticeMemberErrors } = rootStore.practiceStore;
    const { member } = rootStore.memberStore

    useEffect(() => {
        if (member?.accessLevel !== MemberAccessLevels.ADMIN) { 
            history.push('/projects');
        }

        if (practice === null) {
            getPractice();
        }

    }, [member, practice, getPractice]);

    useEffect(() => {
        clearPracticeMemberErrors();
        if (match.params.id) {
            loadPracticeMember(practice?.members.filter((member) => member.id.toString() === match.params.id)[0])
        } else {
            loadPracticeMember(new Member())
        }

    }, [practice, clearPracticeMemberErrors, loadPracticeMember, match.params.id]);

    const handleSubmit = () => {
        history.push('/settings/staff-members')
    }

    const handleBack = () => {
        history.push('/settings/staff-members')
    }

    return (

        <div className="dashboard-container">
            <div className="dashboard-content">
                <SideNavigationMobile />
                <SettingsSideNavigation currentPage={3} />
                {isLoadingPractice ? (
                    <Loading />
                ) : (
                        <div className="dashboard-content-wrapper">
                            <div className="dashboard-body-content dashboard-content-wrapper__white">
                                <div className="dashboard-body-content__form">
                                    <div className="dashboard-panel mt-40 ml-30">
                                        <AddEditMemberForm
                                            confirmButtonText="Save"
                                            cancelButtonText="Cancel" 
                                            practiceMember={practiceMember} 
                                            handleSubmit={handleSubmit} 
                                            handleBack={handleBack} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>

    )

})

export default AddEditMemberSettings;