import React, { useContext, useEffect } from 'react';
import { useFormikContext } from "formik";
import { Prompt } from 'react-router-dom';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';

type PromptIfDirtyProps = {
    isDirtyForm: boolean,
    setIsDirtyForm: (isDirty: boolean) => any,
}

const PromptIfDirty: React.FC<PromptIfDirtyProps> = observer((props) => {
    const { isDirtyForm, setIsDirtyForm } = props;
    const formik = useFormikContext();
    
    useEffect(() => {
        setIsDirtyForm(formik.dirty);
    }, [formik.dirty]);

    return (
        <Prompt
            when={isDirtyForm}
            message="Are you sure you want to navigate away without saving changes? "
        />
    );
});

export default PromptIfDirty;