import React, { FC } from "react";
import { FieldProps } from "formik";

interface FormTextAreaInputProps {
    handleChange: () => any,
    helptext?: string,
}

const FormTextAreaInput: FC<FormTextAreaInputProps & FieldProps> = ({
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue, setFieldTouched, handleChange, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <div className="input-wrapper">
        <textarea data-gramm_editor="false" {...field} {...props}
            onChange={e => {
                setFieldValue(field.name, e.target.value);
                setTimeout(() => setFieldTouched(field.name, true));
                handleChange(e)
            }} className={errors[field.name] && touched[field.name] ? 'singleline-text-input singleline-text-input--full-width singleline-text-input--error ' : 'singleline-text-input singleline-text-input--full-width'} />
        {props.helptext && (
            <div className="input-wrapper__tip">{props.helptext}</div>
        )}
        {errors[field.name] && touched[field.name] ? (<div className="validation-summary-errors">
            <ul>
                <li>{errors[field.name]}</li>
            </ul>
        </div>
        ) : null
        }
    </div>
);

export default FormTextAreaInput;


