import React, { useEffect, useContext } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import SetupSummary from '../../organisms/SetupSummary';
import { observer } from 'mobx-react-lite';
import { history } from '../../../';

const Step4: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { getPractice } = rootStore.practiceStore;

    const handleSubmit = () =>{
        history.push('/projects');
        window.scrollTo(0, 0);
    }

    const handleBack = () =>{
        history.push('/setup/step3');
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        let isLoading = true;
        getPractice().finally(() => {
            // isLoading(true);
        })

        return () => {
            isLoading = false;   // clean up function
        };

    }, []);

    return (
        <SetupSummary handleBack={handleBack} handleSubmit={handleSubmit}/>
    )

})

export default Step4;