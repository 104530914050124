import React from 'react';
import { observer } from 'mobx-react-lite';
import { FeeCalculationStageTask } from '../../types/feeCalculationStageTask';
import { FeeCalculationStage } from '../../types/feeCalculationStage';
import { runInAction } from 'mobx';
import { FixedCost } from '../../types/fixedCost';
import NumberFormat from 'react-number-format';
import FormInputTaskName from '../molecules/FormInputTaskName';
import { Field, getIn } from 'formik';

type FeeCalculationFixedCostFormProps = {

    feeCalculationStageForm: any;
    feeCalculationForm: any;
    handleSubmit: () => any;
    addFixedCostToDelete: (taskId: any) => any,
    removeFixedCost: (e: any) => any,
    feeCalculationFixedCost: FixedCost;
    taskIndex: number;
    stageIndex: number;
    fixedCostIndex: number,
    canEdit: boolean,
}


const FeeCalculationFixedCostForm: React.FC<FeeCalculationFixedCostFormProps> = observer((props) => {

    const {  feeCalculationStageForm, handleSubmit, addFixedCostToDelete, removeFixedCost, feeCalculationFixedCost, taskIndex, fixedCostIndex, canEdit, feeCalculationForm, stageIndex } = props;

    return (

        <>
            <div className="fee-calculator-fixed-cost__form ml-10 mr-25">
                <div className="form-input-container form-input-container--description ">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">Specify cost:</div>
                        </div>
                    </div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            <div className="input-wrapper">
                            <Field disabled={!canEdit} component={FormInputTaskName} 
                            haserror={getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].fixedCosts[${fixedCostIndex}].description`)} 
                            hasError={getIn(feeCalculationForm.errors, `feeCalculationStages[${stageIndex}].feeCalculationStageTasks[${taskIndex}].fixedCosts[${fixedCostIndex}].description`)} 
                            id={`feeCalculationStageTasks[${taskIndex}].fixedCosts[${fixedCostIndex}].description`}
                            name={`feeCalculationStageTasks[${taskIndex}].fixedCosts[${fixedCostIndex}].description`}
                            type="text" 
                            value={feeCalculationFixedCost?.description}
                            placeholder=""
                            onChange={(e: any) => {
                                feeCalculationForm.handleChange(e)
                            }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-input-container form-input-container--cost">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">Fixed cost:</div>
                        </div>
                    </div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            <NumberFormat disabled={!canEdit} thousandSeparator
                                style={{ textAlign: 'right' }}
                                allowEmptyFormatting={true}
                                prefix='£'
                                defaultValue=''
                                onKeyDown={(e: any) => {
                                    if (e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 189 ) {
                                        e.preventDefault()
                                    }
                                }}
                                id={`feeCalculationStageTasks[${taskIndex}].fixedCosts[${fixedCostIndex}].fixedCost`}
                                name={`feeCalculationStageTasks[${taskIndex}].fixedCosts[${fixedCostIndex}].fixedCost`}
                                value={feeCalculationFixedCost?.fixedCost > 0 ? feeCalculationFixedCost?.fixedCost : ''}
                                onValueChange={val => {
                                    feeCalculationStageForm.setFieldValue(`feeCalculationStageTasks[${taskIndex}].fixedCosts[${fixedCostIndex}].fixedCost`, val.floatValue || 0);
                                }}
                                className="singleline-text-input singleline-text-input"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-input-container form-input-container--remove-item">
                    <div className="form-input-container__label--label-above">
                        <div className="form-label">
                            <div className="form-label__title">&nbsp;</div>
                        </div>
                    </div>
                    <div className="form-input-container__input--label-above">
                        <div className="input-wrapper">
                            {canEdit && (<button title="delete fixed task" onClick={() => { runInAction(() => { removeFixedCost(fixedCostIndex); if (feeCalculationFixedCost.id > 0) { addFixedCostToDelete(feeCalculationFixedCost.id) }; handleSubmit() }) }}><i className="material-icons">delete</i></button>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default FeeCalculationFixedCostForm;