import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import EditPracticeForm from '../../organisms/EditPracticeForm';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../organisms/Loading';
import { history } from '../../..';
import { Member } from '../../../types/member';
import SettingsSideNavigation from '../../organisms/SettingsSideNavigation';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';
import { MemberAccessLevels } from '../../../utils/Constants';



const EditPracticeSettings: React.FC<RouteComponentProps> = observer(({ match }) => {

    const rootStore = useContext(RootStoreContext);
    const { practice, getPractice, isLoadingPractice } = rootStore.practiceStore;
    const { member } = rootStore.memberStore

    useEffect(() => {
        if (member?.accessLevel !== MemberAccessLevels.ADMIN) { 
            history.push('/projects');
        }

        if (practice === null) {
            getPractice();
        }

    }, [member, practice, getPractice]);

    

    const handleSubmit = () => {
        history.push('/settings/practice-details')
    }

    const handleBack = () => {
        history.push('/settings/practice-details')
    }

    return (

        <div className="dashboard-container">
            <div className="dashboard-content">
                <SideNavigationMobile />
                <SettingsSideNavigation currentPage={1} />
                {isLoadingPractice ? (
                    <Loading />
                ) : (
                        <div className="dashboard-content-wrapper">
                            <div className="dashboard-body-content dashboard-content-wrapper__white">
                                <div className="dashboard-body-content__form">
                                    <div className="dashboard-panel mt-40 ml-30">
                                        <EditPracticeForm
                                            confirmButtonText="Save"
                                            cancelButtonText="Cancel" 
                                            practice={practice} 
                                            handleSubmit={handleSubmit} 
                                            handleBack={handleBack} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>

    )

})

export default EditPracticeSettings;