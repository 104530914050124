import React, { useContext, useLayoutEffect } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { Member } from '../../types/member';
import { NavLink, useLocation } from 'react-router-dom';
import { runInAction } from 'mobx';
import Modal from 'react-modal';
import Alert from '../molecules/Alert';
import ReactTooltip from 'react-tooltip';

type PracticeMembersListProps = {
    handleBack: () => any,
    handleSubmit: () => any,
    handleReload: () => any,
    title: string,
    isSettingsPage?: boolean
}

const PracticeMembersList: React.FC<PracticeMembersListProps> = (props) => {
    const { handleBack, handleSubmit, handleReload, title, isSettingsPage, children } = props;
    const rootStore = useContext(RootStoreContext);
    const { practice, isLoadingPractice, editPracticeMember, deletePracticeMember } = rootStore.practiceStore;
    const { member } = rootStore.memberStore;
    const { pathname } = useLocation();
    const [memberDeletionModalIsOpen, setMemberDeletionModalIsOpen] = React.useState(false);
    const [memberDeactivationModalIsOpen, setMemberDeactivationModalIsOpen] = React.useState(false);
    const [selectedMember, selectMember] = React.useState(new Member());

    function openMemberDeletionModal(member: Member) {
        setMemberDeletionModalIsOpen(true);
        selectMember(member);
    }

    function openMemberDeactivationModal(member: Member) {
        setMemberDeactivationModalIsOpen(true);
        selectMember(member);
    }

    const confirmMemberDeletion = (member: Member) => {
        deletePracticeMember(member.id).finally(() => {
            closeMemberDeletionModal();
            handleReload();
        })
    }

    const confirmMemberDeactivation = (member: Member) => {
        runInAction(() => {
            member.active = false;
        });
        editPracticeMember(member).finally(() => {
            closeMemberDeactivationModal();
            handleReload();
        });
    }

    function closeMemberDeletionModal() {
        setMemberDeletionModalIsOpen(false);
        selectMember(new Member());
    }

    function closeMemberDeactivationModal() {
        setMemberDeactivationModalIsOpen(false);
        selectMember(new Member());
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <div className="my-purchases__content">
                <div className="dashboard-data-table">
                    {isSettingsPage ? (
                        <>
                            <span className="mt-20 mb-20">
                                <NavLink to="/settings/new-member" className="mt-20 mb-40 button button--opaque">Add a new staff member</NavLink>
                            </span>
                            <table className="mt-20" id="table1" width="100%">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Job title</th>
                                        <th scope="col">Access right</th>
                                        <th scope="col">Total salary package</th>
                                        <th scope="col">% Billable</th>
                                        <th scope="col">
                                            <div className="field-help tooltip-trigger" data-tip="React-tooltip" data-for="costRateHourly">
                                                <div className="field-help__icon" >
                                                    <span className="material-icons info-icon ">info</span>
                                                </div>
                                            </div>
                                            <ReactTooltip
                                                type="light"
                                                place="right"
                                                effect="solid"
                                                className="react-tooltip"
                                                border={true}
                                                id='costRateHourly'
                                                textColor='#333f48' backgroundColor='#f9f9f9' borderColor='#f2f2f2'>
                                                <strong>Cost rate hourly</strong><br />
                                                This is an individual’s cost to the business per hour. <br />
                                                This is calculated by adding an individual’s salary to <br />
                                                their allocation/portion of overheads and then dividing <br />
                                                this figure by their billable hours.
                                        </ReactTooltip> Cost rate (hourly) </th>
                                        <th scope="col">
                                            <div className="field-help tooltip-trigger" data-tip="React-tooltip" data-for="costRateDaily">
                                                <div className="field-help__icon" >
                                                    <span className="material-icons info-icon ">info</span>
                                                </div>
                                            </div>
                                            <ReactTooltip
                                                type="light"
                                                place="right"
                                                effect="solid"
                                                className="react-tooltip"
                                                border={true}
                                                id='costRateDaily'
                                                textColor='#333f48' backgroundColor='#f9f9f9' borderColor='#f2f2f2'>
                                                <strong>Cost rate daily</strong><br />
                                                This is an individual’s cost to the business per day. <br />
                                                This is calculated by simply taking an individual’s  <br />
                                                Cost Rate Per Hour and multiplying it by seven.
                                        </ReactTooltip> Cost rate (daily)</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {practice?.members.slice().sort((a, b) => (a.firstName + a.lastName).toLowerCase() > (b.firstName + b.lastName).toLowerCase() ? 1 : -1).map((staffMember: Member, index) => (
                                        <tr className={!staffMember.active ? 'disabled' : ''} key={index}>
                                            <td><NavLink to={`/settings/staff-members/${staffMember!.id}`}>{staffMember.firstName} {staffMember.lastName}</NavLink></td>
                                            <td>{staffMember.jobTitle}</td>
                                            <td>{staffMember.accessLevel === "NonUser" ? "None" : staffMember.accessLevel}</td>
                                            <td>{!staffMember.totalSalaryPackage ? (
                                                <div className="badge badge--danger">
                                                    <span className="badge__text">Incomplete</span>
                                                </div>
                                            ) : (
                                                    <>£{staffMember.totalSalaryPackage.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</>
                                                )}</td>
                                            <td>{staffMember.billableStatus === 'Billable' ? `${staffMember.billableHours}%` : 'Non-billable'}</td>
                                            <td>{staffMember.billableStatus === 'Billable' ? `£${staffMember.costRatePerHour.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}` : '-'}</td>
                                            <td>{staffMember.billableStatus === 'Billable' ? `£${staffMember.costRatePerDay.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}` : '-'}</td>
                                            <td className="d-flex justify-content-end">
                                                {staffMember.active ? (
                                                    <>
                                                        <NavLink title={`Edit ${staffMember.firstName}`} className="action-item" to={`/settings/staff-members/${staffMember!.id}`}><i className="action-item__block material-icons">edit</i></NavLink>
                                                        {member?.id !== staffMember.id && (
                                                            <span title={`Delete ${staffMember.firstName}`} onClick={() => { openMemberDeactivationModal(staffMember) }}><i className="material-icons">delete</i></span>
                                                        )}
                                                    </>
                                                ) : (
                                                        <NavLink title={`Activate ${staffMember.firstName}`} className="action-item" to={`/settings/staff-members/${staffMember!.id}`}>Activate</NavLink>
                                                    )}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <strong>Salary total:</strong>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <strong>£{practice?.members.filter((member) => member.active).reduce((total, member) => total = total + member.totalSalaryPackage, 0).toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</strong>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </>
                    ) : (
                            <>
                                <table className="" id="table1" width="100%">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Name</th>
                                            <th scope="col" style={{ width: "40%" }}>Email</th>
                                            <th scope="col" style={{ width: "25%" }}>Total annual salary</th>
                                            <th scope="col" style={{ width: "15%" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {practice?.members.slice().sort((a, b) => (a.firstName + a.lastName).toLowerCase() > (b.firstName + b.lastName).toLowerCase() ? 1 : -1).map((staffMember: Member, index) => (
                                            <tr key={index}>
                                                <td><NavLink to={`/setup/step3/${staffMember!.id}`}>{staffMember.firstName} {staffMember.lastName}</NavLink></td>
                                                <td>{staffMember.email}</td>
                                                <td>{!staffMember.totalSalaryPackage ? (
                                                    <div className="badge badge--danger">
                                                        <span className="badge__text">Incomplete</span>
                                                    </div>
                                                ) : (
                                                        <>£{staffMember.totalSalaryPackage.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</>
                                                    )}</td>
                                                <td className="d-flex justify-content-end">
                                                    <NavLink className="action-item" to={`/setup/step3/${staffMember!.id}`}><i className="action-item__block material-icons">edit</i></NavLink>
                                                    {member?.id !== staffMember.id && (
                                                        <span onClick={() => { openMemberDeletionModal(staffMember) }}><i className="material-icons">delete</i></span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                <span className="center mt-20">
                                    <NavLink to="/setup/new-member" className="button">Add a new staff member</NavLink>
                                </span>
                            </>
                        )}
                </div>
            </div>

            {!isSettingsPage && (
                <div className="join-riba-form-content__actions">
                    <button onClick={handleBack} className="button  ">Back</button>
                    <button onClick={handleSubmit} disabled={!practice?.members.some((member: Member) => member.totalSalaryPackage > 0)} className="button button--opaque ">Confirm and continue</button>
                </div>
            )}


            <Modal
                isOpen={memberDeletionModalIsOpen}
                onRequestClose={closeMemberDeletionModal}
                overlayClassName="Overlay"
                contentLabel="Confirm member deletion"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    <span onClick={closeMemberDeletionModal} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                    <h1 className="dashboard-modal__title">Permanently remove staff member from Fee Calculator</h1>
                    <div className="whitespace whitespace--single"></div>
                    <Alert title='Warning' styleClass='alert alert--warning' text='This action cannot be undone' />
                    <p className="dashboard-modal__subtext">You are about to remove <strong>{selectedMember?.firstName} {selectedMember?.lastName}</strong> from the Fee Calculator. All staff details will be removed.</p>
                    <div className="whitespace whitespace--single"></div>
                    <div className="cta-band__buttons">
                        <button onClick={closeMemberDeletionModal} className="button  cta-band__button">Cancel</button>
                        <button onClick={() => { confirmMemberDeletion(selectedMember) }} className="button button--opaque cta-band__button">Remove staff member</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={memberDeactivationModalIsOpen}
                onRequestClose={closeMemberDeactivationModal}
                overlayClassName="Overlay"
                contentLabel="Deactivate staff member"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    <span onClick={closeMemberDeactivationModal} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                    <h1 className="dashboard-modal__title">Deactivate staff member</h1>
                    <div className="whitespace whitespace--single"></div>
                    <p className="dashboard-modal__subtext">You are about to deactivate <strong>{selectedMember.firstName} {selectedMember.lastName}</strong> from the Fee Calculator. Staff details will be included in existing fee calculations. You can activate staff member at any time.</p>
                    <div className="whitespace whitespace--single"></div>
                    <div className="cta-band__buttons">
                        <button onClick={closeMemberDeactivationModal} className="button  cta-band__button">Cancel</button>
                        <button onClick={() => { confirmMemberDeactivation(selectedMember) }} className="button button--opaque cta-band__button">Deactivate staff member</button>
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default PracticeMembersList;