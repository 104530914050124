import React from 'react';

import HelpSideNavigation from '../../organisms/HelpSideNavigation';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';

const FeeCalculationOverview: React.FC = () => {

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <SideNavigationMobile />
                <HelpSideNavigation currentPage={2} />
                <div className="dashboard-content-wrapper">
                    <div className="dashboard-body-content dashboard-content-wrapper__white">
                        <div className="dashboard-body-content__form">
                            <div className="dashboard-panel mt-40 ml-30 mr-50">
                                <h1>Fee calculation overview</h1>
                                <ul>
                                    <li><a href="#annual-overheads">Annual overhead costs</a></li>
                                    <li><a href="#staff">Staff salary details</a></li>
                                    <li><a href="#fee-calculation-statuses">Fee calculation statuses</a></li>
                                    <li><a href="#exporting-fee-calculations">Exporting fee calculations</a></li>
                                </ul>
                                <br />
                                <h2 id="annual-overheads">Annual overhead costs</h2>
                                <p>Annual overhead costs are the ongoing costs of running your business. Many RIBA Chartered Practices already submit information about their operational costs to us when taking part in the annual RIBA Business Benchmarking survey. The survey helps us track the business of doing architecture among practices.  </p>
                                <p><a href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/business-benchmarking">Find out how to participate in the survey.</a></p>
                                <p>Once this data has been added as part of your setup, you can edit it at any time by using the settings tab at the top of the page. You will only be able to amend the overhead costs if you have admin permissions. </p>
                                <p>The data you enter here will be used to generate fees, so make sure you use accurate information. You can enter an itemised list of overheads or provide one total overhead figure. </p>
                                <p>Employer's contributions to staff National Insurance payments need to be included here. The Employer’s National Insurance rate for the 2020/21 tax year is 0% for the first £8,784 of salary, and 13.8% for everything above that.</p>
                                <br />
                                <h2 id="staff">Staff salary details</h2>
                                <p>Use the staff salary details table in Settings to add or edit staff contact details, salary information and working hours.</p>
                                <p>Staff costs will then be included in any fee calculations you make in the tool. Provide salary details for all the staff on your payroll. Additional contractors or consultants you work with be added to each fee calculation as a fixed cost.</p>
                                <br />
                                <h3>Adding new staff</h3>
                                <p>Add a new staff member’s name, job title, email, salary details, working hours and set their RIBA Fee Calculator permission level. You can then calculate each billable member staff’s cost per hour and per day. </p>
                                <p>This cost will then be included in your calculations, whenever a staff member is selected or assigned to work on a particular task of a project.</p>
                                <br />
                                <h3>Permissions and access levels</h3>
                                <p>A staff member's email address will be used to grant access to the RIBA Fee Calculator. There are three levels of access:</p>
                                <ul>
                                    <li><strong>Admin access:</strong> Full user access, full tool functionality, create, view, add and edit financial, business and staff details, create and modify fee calculations. This permission should only be granted to senior staff at the practice/company.</li>
                                    <li><strong>Staff access:</strong> Create and edit fee calculations that the user has created. This access level will not give the user access to financial, business or staff salary details.</li>
                                    <li><strong>No access:</strong> Staff members with no access to the RIBA Fee Calculator tool, but their data (time and staff costs) is used for fee calculations.</li>
                                </ul>
                                <br />
                                <h3>Billable vs Non-billable staff</h3>
                                <p><strong>Billable staff</strong> work on projects, and their time is chargeable to those projects. We recommend that all staff who work on projects, whatever their position in your practice, are added to the fee calculator as billable staff. </p>
                                <p><strong>Non-billable staff</strong> are those who don’t work directly on any projects but provide support to the team or practice. This may be a receptionist or office manager. </p>
                                <br />
                                <h3>Billable hours %</h3>
                                <p>To calculate this percentage, include all the billable hours a staff member spends working on projects, and the time spent on any general ‘office’ duties including administration, training, and attending staff meetings. It is unrealistic to allocate 100% of someone's time to billable hours, only working on projects. </p>
                                <p>It is common for Senior Partners to allocate a lower percentage of their time to billable hours if they undertake general business management duties. A Project Architect will therefor typically allocate a higher percentage of their time to billable projects if they spend less time on general business management duties.   </p>
                                <br />
                                <h3>Working hours each week and each year</h3>
                                <p><strong>Working hours each week:</strong> Either a staff member’s contracted hours per week or their average working hours per week. The more accurate record of hours worked you enter, the more accurate cost rate you’ll see in the tool. Using time recording tools or time sheets may help you with this.</p>
                                <p><strong>Working weeks each year:</strong> Enter the number of weeks each team member works each year. Remember to exclude public holidays and annual leave entitlement. </p>
                                <br />
                                <h3>Hourly and daily cost rates and charge-out rates</h3>
                                <p>The hourly and daily cost rate is the actual cost of an individual to the business. This is the fee that this individual will need to be charged at just to cover their costs. </p>
                                <p><strong>An individual’s cost rate is not their charge-out rate. </strong></p>
                                <p>A charge-out rate is the cost rate plus a margin (mark-up or profit). The RIBA Fee Calculator allows you to apply a margin/profit at a project level, as opposed to an individual level. This allows you to apply different margins/profit to different types of projects, based on their complexity, risk or any other commercial considerations you need to factor in. </p>
                                <p>The hourly cost rate for each billable staff member is calculated by adding their salary to their portion/allocation of the total company overheads, including non-billable staff salaries, and then dividing this by their number of billable hours per year.</p>
                                <p>If a billable staff member’s salary equates to 20% of the total billable staff salaries, that individual will be allocated 20% of the total company overheads.</p>
                                <p>For example, if a practice’s total billable staff salary is £100,000 and their total company overheads, including non-billable staff salaries, is £80,000, then a billable staff member earning £30,000 (30% of the total billable salaries) will be allocated £24,000 of the company overheads (30% x £80,000).</p>
                                <p>The daily cost rate is simply calculated by taking the hourly cost rate and multiplying this by 7 (the average number of working hours in a day) </p>
                                <br />
                                <h2 id="fee-calculation-statuses">Fee calculation statuses</h2>
                                <p>When you start a new fee calculation, the default name will be <strong>Draft_1.</strong></p>
                                <p>‘Draft’ refers to the status of the calculation, and the number refers the version of your calculation. Each new version will be numbered in sequence. </p>
                                <p>You can duplicate fee calculations to make it easy to edit and keep track of different versions, or you can change the status of each calculation to reflect where you are in the process: </p>
                                <p><strong>Draft:</strong> Default status of fee calculations when you start.</p>
                                <p><strong>Approved:</strong> The calculation has been approved internally by the appropriate staff and is now read-only. Can be exported into an Excel spreadsheet or into RIBA Contracts Digital to create a RIBA professional services contract.</p>
                                <p><strong>Client accepted:</strong> The fee calculation has been approved by your client and is read-only. Can be exported into an Excel spreadsheet or into RIBA Contracts Digital to create a RIBA professional services contract.</p>
                                <p><strong>Client declined:</strong> The fee calculation has been declined by the client and the fee calculation is read-only.</p>
                                <br />
                                <h2 id="exporting-fee-calculations">Exporting fee calculations</h2>
                                <p>You can download fee calculations of any status into Excel at any time. When a fee calculation has ‘approved’ or ‘client accepted’ status, you can export the data from the RIBA Fee Calculator into RIBA Contracts Digital and generate your professional services contract, using one of the RIBA standard forms of appointment.</p>
                                <p><a href="https://www.architecture.com/riba-contracts">Find out more about professional service contracts and RIBA Contracts Digital.</a></p>
                                <br/>
                                <h3>More help</h3>
                                <p>If you need more support with the RIBA Fee Calculator, or have questions about your account, access permissions, or our other websites and services, contact our support centre Monday to Friday, 9am to 5pm by telephone on <a href="tel:+44(0)2073075355">+44 (0)20 7307 5355</a> or email <a href="mailto:support@riba.org?subject=Fee Calculator support needed">support@riba.org</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default FeeCalculationOverview;