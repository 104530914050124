import React from 'react';

type CookieBannerProps = {
    handleCookieBannerClose: (dismiss: any) => any,
}

const CookieBanner: React.FC<CookieBannerProps> = (props) => {

    const { handleCookieBannerClose } = props;

    return (
        <div></div>

        // <div className="cookie-banner">
        //     <div className="cookie-banner__large-content-container">
        //         <span className="cookie-banner__info-icon">
        //             info</span>
        //         <div className="cookie-banner__content">
        //             <span className="cookie-banner__title">
        //                 Cookies</span>
        //             <span> This site uses cookies, by continuing to use this site you agree to our <a target="_BLANK" href="https://www.architecture.com/about/cookie-statement">cookie policy</a></span>
        //         </div>

        //         <div className="cookie-banner__close">
        //             <a className="cookie-banner__close-icon" onClick={() => {
        //                 handleCookieBannerClose(true);
        //             }} >
        //                 close
        //             </a>
        //         </div>
        //     </div>
        // </div>
    );
}

export default CookieBanner;
