import axios, { AxiosResponse } from 'axios';
import { history } from '../';
import { Member } from '../types/member';
import { Practice } from '../types/practice'
import { Project } from '../types/project'
import { Client } from '../types/client'
import { Authenticate } from '../types/authenticate';
import Config from '../config';
import { FeeCalculation, FeeCalculationDetailed } from '../types/feeCalculation';
import { ExportContractResponse } from '../types/exportContractResponse';

axios.defaults.baseURL = Config.API_URL;
axios.defaults.withCredentials = true

axios.interceptors.request.use((config) => {
    config.withCredentials = true;
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    setTimeout(() => source.cancel('Timed out after 3m'), 180000);
    return config
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(undefined, error => {
    if (error.message === 'Network Error' && !error.response) {
        //TODO: Error logging
        console.log(error);
        history.push('/error')
    }
    const { status } = error.response || 'No error.response';
    console.log('API error: [' + status + '] ' + error.message)
    if (status === 404) {
        console.log('Not found error')
        console.log(error);
        history.push('/error/404')
    }
    if (status === 400) {
        //TODO: Error logging
        console.log('No cookie found error')
        console.log(error);
        history.push('/error/400')
    }
    if (status === 405) {
        //TODO: Error logging
        console.log(error);
        history.push('/error/405')
    }
    if (status === 500) {
        //TODO: Error logging
        console.log(error);
        history.push('/error/500')
    }
    if (status === 401) {
        //TODO: Error logging
        console.log(error);
        console.error('Member unauthorised');
        if (window.innerWidth < 900) {
            history.push('/mobile-landing');
          } else {
            window.location.replace(Config.AUTH_URL + 'login?ReturnUrl=' + Config.CLIENT_APP_URL)
          }
    }

    // //Member has too many practices
    if (status === 470) {
        //TODO: Error logging
        console.log('Member has too many practices')
        console.log(error);
        history.push('/error/470')
    }
    //Member doesn't have any practices to onboard
    if (status === 471) {
        //TODO: Error logging
        console.log('Member doesnt have any practices to onboard')
        console.log(error);
        history.push('/error/471')
    }
    //Member doesn't exist in cube
    if (status === 472) {
        //TODO: Error logging
        console.log('Member doesnt exist in cube')
        console.log(error);
        history.push('/error/472')
    }
    //Member is not enrolled to fee calc
    if (status === 473) {
        //TODO: Error logging
        console.log('Member is not enrolled to fee calc')
        console.log(error);
        history.push('/error/473')
    }
    //Member is on mobile device
    if (status === 474) {
        //TODO: Error logging
        console.log('Member is on mobile device')
        console.log(error);
        history.push('/mobile-landing')
    }

    throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const sleep = (ms: number) => (response: AxiosResponse) =>
    new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms));

const memberEnd = '/Member';
const memberAcceptTermsEnd = '/Member/accept-terms-and-conditions';
const authEnd = '/Authenticate';
const practiceEnd = '/Practice';
const projectEnd = '/Project';
const clientEnd = '/Client';
const feeCalculationEnd = '/fee-calculation';

const requests = {
    get: (url: string) => axios.get(`${url}${url.includes('?') ? '&' : '?'}timestamp=${new Date().getTime()}`).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
}

const UserMethods = {
    current: (): Promise<Member> => requests.get(memberEnd),
    acceptTerms: () => requests.put(memberAcceptTermsEnd, {}),
    deleteMember: (id: number) => requests.delete(`${memberEnd}/${id}`),
    create: (member: Member): Promise<Member> => requests.post(memberEnd, member),
    edit: (member: Member): Promise<Member> => requests.put(memberEnd, member),
}

const AuthenticateMethods = {
    current: (): Promise<Authenticate> => requests.get(authEnd)
}

const PracticeMethods = {
    getPractice: (): Promise<Practice> => requests.get(practiceEnd),
    edit: (practice: Practice) => requests.put(practiceEnd, practice),
}

const ProjectMethods = {
    getProject: (id: string): Promise<Project> => requests.get(`${projectEnd}/${id}`),
    getProjects: (slim: boolean, live: boolean, archived: boolean): Promise<Array<Project>> => requests.get(`${projectEnd}?slim=${slim}&live=${live}&archived=${archived}`),
    getProjectCount: (live: boolean, archived: boolean): Promise<number> => requests.get(`${projectEnd}/count?live=${live}&archived=${archived}`),
    create: (project: Project): Promise<Project> => requests.post(projectEnd, project),
    edit: (project: Project): Promise<Project> => requests.put(projectEnd, project),
    delete: (id: number) => requests.delete(`${projectEnd}/${id}`),
}

const ClientMethods = {
    getClient: (id: number): Promise<Client> => requests.get(`${clientEnd}/${id}`),
    getClients: (): Promise<Array<Client>> => requests.get(clientEnd),
    create: (client: Client): Promise<Client> => requests.post(clientEnd, client),
    edit: (client: Client): Promise<Client> => requests.put(clientEnd, client),
}

const FeeCalculationMethods = {
    getFeeCalculation: (id: string): Promise<FeeCalculationDetailed> => requests.get(`${feeCalculationEnd}/${id}`),
    deleteFeeCalculation: (id: string) => requests.delete(`${feeCalculationEnd}/${id}`),
    getFeeCalculatios: (): Promise<Array<FeeCalculation>> => requests.get(feeCalculationEnd),
    create: (feeCalculation: FeeCalculation): Promise<FeeCalculation> => requests.post(feeCalculationEnd, feeCalculation),
    edit: (feeCalculation: FeeCalculation): Promise<FeeCalculation> => requests.put(feeCalculationEnd, feeCalculation),
    syncFeeCalculation: (feeCalculationDetailed: FeeCalculationDetailed) => requests.post(`${feeCalculationEnd}/sync-fee-calculation`, feeCalculationDetailed),
    deleteTask: (id: number) => requests.delete(`${feeCalculationEnd}/stage/task/${id}`),
    deleteFixedCost: (id: number) => requests.delete(`${feeCalculationEnd}/stage/task/fixed-cost/${id}`),
    deleteStaffCost: (id: number) => requests.delete(`${feeCalculationEnd}/stage/task/staff-cost/${id}`),
    exportFeeCalculationCreateContract: (id: string): Promise<ExportContractResponse> => requests.post(`${feeCalculationEnd}/create-contract/${id}`, {}),
    duplicate: (feeCalculationId: string, projectId: string): Promise<FeeCalculationDetailed> => requests.get(`${feeCalculationEnd}/duplicate-fee-calculation/${projectId}/${feeCalculationId}`),
}

export default {
    AuthenticateMethods,
    UserMethods,
    PracticeMethods,
    ProjectMethods,
    ClientMethods,
    FeeCalculationMethods,
}