import { Practice } from './practice';
export class Member {
    id: number = 0;
    active: boolean = true;
    accessLevel: string = "NonUser";
    title: string = '';
    email: string = '';
    lastName: string = '';
    jobTitle: string = '';
    acceptedTermsAndConditions: boolean = false;
    firstName: string = '';
    practice: Practice = new Practice();
    billableStatus: string = 'Billable';
    workingHoursPerWeek: number = 0;
    workingWeeksPerYear: number = 0;
    billableHours: number = 0;
    annualSalary: number = 0;
    employerNIContribution: number = 0;
    otherBenefits: number = 0;
    totalSalaryPackage: number = 0;
    costRatePerHour: number = 0;
    costRatePerDay: number = 0;
    invitedToFeeCalculator: boolean = false;
    
    public constructor(init?: Partial<Member>) {
        Object.assign(this, init);
    }
}

export class TaskMember {
    id: number = 0;
    hours: number = 0;
    staffCost: number = 0;
    costRatePerHour: number = 0;
    member: Member = new Member();
    jobTitle: string = ''
    public constructor(init?: Partial<TaskMember>) {
        Object.assign(this, init);
    } 
}
