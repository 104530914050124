import { TaskMember } from './member';
import { FixedCost } from './fixedCost';

export class FeeCalculationStageTask {
  id: number = 0;
  name: string = '';
  staffCosts: Array<TaskMember> = [];
  fixedCosts: Array<FixedCost> = [];
  note: string = '';
  marginAsPercent: number = 0;
  clientTaskFee: number = 0;
  totalTaskCost: number = 0;
  showTaskNote: boolean = false;

  constructor(init?: FeeCalculationStageTask) {
    this.showTaskNote = this.note !== undefined ? true : false
    if (init) {
        Object.assign(this, init);
    }
  }
  
}