import React from 'react';

type InfoBarProps = {
    handleInfoBarClose: (dismiss: any) => any,
}

const InfoBar: React.FC<InfoBarProps> = (props) => {

    const { handleInfoBarClose } = props;

    return (
        <div className="info-bar">
            <div className="info-bar__large-content-container">
                <div className="info-bar__content">
                    <span className="info-bar__beta-label"> BETA </span>
                    <span> <a target="_BLANK" href="https://forms.office.com/Pages/ResponsePage.aspx?id=cQe_qCgiZ0qvNRHG4NbU_L9-T9AkKPRMtb8aTVsvlUlURTNZUkNPUUQ4Nk9XTFE5Wk45OEtMVE40Ui4u" >This service is new to RIBA. We'd appreciate your feedback - fill out our survey.</a></span>
                </div>
                <div className="info-bar__close">
                    <a className="info-bar__close-icon" style={{ cursor: 'pointer' }} onClick={() => {
                        handleInfoBarClose(true);
                    }} >
                        close
                    </a>
                </div>
            </div>
        </div>
    );
}

export default InfoBar;
