import api from '../api/api';
import { RootStore } from './rootStore';
import { Client } from '../types/client';
import { action, observable, runInAction } from 'mobx';

export default class clientStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable client: Client | null = null;
    @observable clients: Array<Client> | null = [];
    @observable isLoading = false;

    @action getClients = async () => {
        this.isLoading = true;
        try {
            const localClients = await api.ClientMethods.getClients();
            runInAction(() => {
                runInAction('List activities.', () => {
                    this.clients = localClients.slice();
                });

                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
            });
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action getClient = async (clientId: number) => {
        this.isLoading = true;
        try {
            const client = await api.ClientMethods.getClient(clientId);
            runInAction(() => {
                this.client = client;
                this.isLoading = false;
            });

            return client;
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
            });
            console.log(error);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action loadClient = (client: any) => {
        this.isLoading = true;

        runInAction(() => {
            this.client = client;
            this.isLoading = false;
        })

        return this.client
    }


    @action createClient = async (client: any) => {
        if(client){
            this.isLoading = true;
            try {
                const newClient : Client = await api.ClientMethods.create(client);
                runInAction('Create Client.', () => {
                    this.isLoading = false;
                });
                return newClient;
    
            } catch (error) {
                console.log(error);
                runInAction(() => {
                    this.isLoading = false;
                });
            } finally {
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        }
    }

    @action editClient = async (client: Client) => {
        this.isLoading = true;
        try {
            console.log(client);
            await api.ClientMethods.edit(client);

            runInAction('Edit Client.', () => {

                this.clients = this.clients!.map(objClient =>
                    objClient.id === client.id
                      ? { ...objClient, clientName: client.clientName, contactName: client.contactName, contactEmail: client.contactEmail }
                      : objClient
                  );

                this.client = client;
                this.isLoading = false;
            });
            
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action clearLoadedClient = () => {
        runInAction(() => { this.client = null; });
    }
}