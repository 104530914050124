import React, { useContext } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { NavLink } from "react-router-dom";
import UserMenu from "../molecules/UserMenu";
import { observer } from "mobx-react-lite";
import Logo from "../atoms/Logo";
import { Member } from "../../types/member";

type HeaderProps = {
  member: Member | null;
};

const Header: React.FC<HeaderProps> = observer((props) => {
  const { member } = props;
  const rootStore = useContext(RootStoreContext);
  const { login, isAuthenticated } = rootStore.memberStore;
  const { isOnboarded } = rootStore.practiceStore;
  return (
    <header className="header">
      <div className="large-content-container ">
        <div className="header__logo-container ">
          {isAuthenticated ? (
            <NavLink to="/projects" className="logo logo--header">
              <Logo />
            </NavLink>
          ) : (
            <span className="logo logo--header active">
              <Logo />
            </span>
          )}
        </div>
        <div className="header__title-container">
          {isAuthenticated ? (
              <a href="/projects"><h2 className="form-heading__title ml-20 mt-25">RIBA Fee Calculator</h2></a>
            ) : (
              <h2 className="form-heading__title ml-20 mt-25">RIBA Fee Calculator</h2>
            )}

        </div>
        <div className="header__primary-nav-container">
          <nav className="primary-nav" data-js-has-toggle="">
            <div
              className="primary-nav__content"
              data-js-toggle-element=""
            >
              {member && (
                <>
                  <UserMenu member={member} />
                  {isOnboarded && (
                    <ul className="primary-nav__items">
                      <li
                        className="primary-nav-first-level-item "
                        data-js-has-toggle="1"
                      >
                        <NavLink
                          className="primary-nav-first-level-item__title-link"
                          to="/projects"
                        >
                          Projects and fee calculations
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
});

export default Header;
