import * as Yup from 'yup';

export const AddEditMemberFormValidation = Yup.object().shape({
    title: Yup.string()
        .required('Please complete this mandatory field.'),
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Please complete this mandatory field.'),
    firstName: Yup.string()
        .required('Please complete this mandatory field.'),
    lastName: Yup.string()
        .required('Please complete this mandatory field.'),
    jobTitle: Yup.string()
        .required('Please complete this mandatory field.'),
    billableStatus: Yup.string()
        .required(''),
    workingHoursPerWeek: Yup.number()
        .when('billableStatus', {
            is: 'Billable',
            then: Yup.number()
                .required('Please complete this mandatory field.')
                .positive('Field must be a positive number.')
                .max(168,'The value you entered is too big (maximum is 168).')
        }),
    workingWeeksPerYear: Yup.number()
        .when('billableStatus', {
            is: 'Billable',
            then: Yup.number()
                .required('Please complete this mandatory field.')
                .max(52,'The value you entered is too big (maximum is 52).')
                .positive('Field must be a positive number.')
                .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        }),
    billableHours: Yup.number()
        .when('billableStatus', {
            is: 'Billable',
            then: Yup.number()
                .required('Please complete this mandatory field.')
                .positive('Please complete this mandatory field.')
                .max(100,'The value you entered is too big (maximum is 100).')
                .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
        }),
    annualSalary: Yup.number()
        .required('Please complete this mandatory field.')
        .moreThan(0, 'Please complete this mandatory field.')
        .positive('Field must be a positive number.')
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).'),
    otherBenefits: Yup.number()
        .required('Please complete this mandatory field.')
        .moreThan(-1, 'Field must be a positive number.')
        .integer('This field can only contain whole numbers (e.g. 1, 2, 3).')
});
