import React, { useEffect, useContext } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import TermsAndConditionsAdminForm from '../../organisms/TermsAndConditionsAdminForm';
import { history } from '../../..';
import { observer } from 'mobx-react-lite';

const Setup: React.FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const { getPractice } = rootStore.practiceStore;

    const handleSubmit = () => {
        history.push('/setup/step1')
    }

    useEffect(() => {
        let isLoading = true;
        getPractice().finally(() => {
            // isLoading(true);
        })
        return () => {
            isLoading = false;   // clean up function
        };
    }, []);

    return (
        <TermsAndConditionsAdminForm handleSubmit={handleSubmit}/>
    )

})

export default Setup;