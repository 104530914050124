import React, { useContext } from 'react';
import { RootStoreContext } from '../../stores/rootStore';

const Settings: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { member, auth } = rootStore.memberStore;
    return (
        <div className="narror-content-container">
            <div className="content-container">
                <div className="heading-one">
                    <h1 className="heading-one__heading">SETTINGS</h1>
                </div>
                <h2>Auth</h2>
                <pre>{JSON.stringify(auth, null, 2) }</pre>
                <h2>Member</h2>
                <pre>{JSON.stringify(member, null, 2) }</pre>
            </div>
        </div>
    )
}

export default Settings;