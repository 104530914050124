import React, { useEffect, useContext, useState } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import FeeCalculationStagesForm from '../../organisms/FeeCalculationStagesForm';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import { history } from '../../..';
import Loading from '../../organisms/Loading';
import ProjectSideNavigation from '../../organisms/ProjectSideNavigation';
import { FeeCalculationStage } from '../../../types/feeCalculationStage';
import { canEditFeeCalculation } from '../../../utils/Helpers';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';

interface FeeCalculationDetailsProps { id: string, feeCalculationId: string }

const FeeCalculationStages: React.FC<RouteComponentProps<FeeCalculationDetailsProps>> = observer(({ match }) => {

    const rootStore = useContext(RootStoreContext);
    const { project, getProject, isLoading } = rootStore.projectStore;
    const { member } = rootStore.memberStore;
    const { feeCalculationDetailed, getFeeCalculationDetailed, isLoadingFeeCalc } = rootStore.feeCalculationStore;
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        if (match.params.id && match.params.feeCalculationId) {
            getProject(match.params.id).finally(() => {
                getFeeCalculationDetailed(match.params.feeCalculationId)
            })
        }
    }, [getProject, getFeeCalculationDetailed, match.params.feeCalculationId, match.params.id]);

    useEffect(() => {
        if (feeCalculationDetailed && member) {
            setCanEdit(canEditFeeCalculation(feeCalculationDetailed.feeCalculation, member));
        }
    })

    const handleSubmit = (feeCalc: FeeCalculationStage) => {
        history.push(`/projects/${project?.id}/fee-calculation/${feeCalculationDetailed?.feeCalculation?.id}`)
    }

    return (
        <>
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <SideNavigationMobile />
                    <ProjectSideNavigation project={project} feeCalcId={feeCalculationDetailed?.feeCalculation?.id} currentPage={3} />
                    {isLoading || isLoadingFeeCalc ? (
                        <Loading />
                    ) : (
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30">
                                            <FeeCalculationStagesForm
                                                handleSubmit={handleSubmit}
                                                feeCalculationDetailed={feeCalculationDetailed}
                                                canEdit={canEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
})

export default FeeCalculationStages;