import React, { useContext, useEffect, useRef, useState } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import FormInput from '../molecules/FormInput';
import Loading from './Loading';
import FormLabel from '../molecules/FormLabel';
import Alert from '../molecules/Alert';
import { Formik, Form, Field, getIn, useFormikContext } from "formik";
import { PracticeOverheadsFormValidation } from '../../utils/validation/PracticeOverheadsFormValidation';
import CurrencyFormInput from '../molecules/CurrencyFormInput';
import PromptIfDirty from '../molecules/PromptIfDirty';

type PracticeOverheadsFormProps = {
    handleSubmit: () => any,
    handleBack: () => any,
    confirmButtonText?: string
    cancelButtonText?: string,
    title: string,
    isSettingsPage?: boolean
}

const PracticeOverheadsForm: React.FC<PracticeOverheadsFormProps> = (props) => {
    const { handleSubmit, handleBack, confirmButtonText, cancelButtonText, title, isSettingsPage } = props;
    const rootStore = useContext(RootStoreContext);
    const { practice, isLoadingPractice, editPractice } = rootStore.practiceStore;
    const [showWarning, setShowWarning] = useState(false);
    const [isDirtyForm, setIsDirtyForm] = useState(false);

    const CalculateValuesComponent = () => {
        const { values, setFieldValue } = useFormikContext<any>();
        const fieldValues = [getIn(values, 'officeITAndCommunications'), getIn(values, 'pensionCosts'), getIn(values, 'nationalInsuranceContribution'), getIn(values, 'depreciation'), getIn(values, 'legalCosts'), getIn(values, 'otherInsuranceCosts'), getIn(values, 'professionalIndemnityInsurance'), getIn(values, 'bankChargesAndInterest'), getIn(values, 'auditFeesAndAccountancy'), getIn(values, 'travelCost'), getIn(values, 'companyCarFuelAllowance'), getIn(values, 'marketingCosts'), getIn(values, 'softwareAndIT'), getIn(values, 'utilities'), getIn(values, 'rentAndServiceCharge'), getIn(values, 'otherAnnualCosts'), getIn(values, 'badDebts')]
        const initialRender = useRef(true);

        useEffect(() => {
            if (!initialRender.current) {
                var val = Number(values?.rentAndServiceCharge) +
                    Number(values?.utilities) +
                    Number(values?.softwareAndIT) +
                    Number(values?.officeITAndCommunications) +
                    Number(values?.marketingCosts) +
                    Number(values?.companyCarFuelAllowance) +
                    Number(values?.travelCost) +
                    Number(values?.auditFeesAndAccountancy) +
                    Number(values?.bankChargesAndInterest) +
                    Number(values?.professionalIndemnityInsurance) +
                    Number(values?.otherInsuranceCosts) +
                    Number(values?.legalCosts) +
                    Number(values?.depreciation) +
                    Number(values?.badDebts) +
                    Number(values?.pensionCosts) +
                    Number(values?.nationalInsuranceContribution) +
                    Number(values?.otherAnnualCosts);
                setFieldValue('totalAnnualOverheadCosts', val);
                setShowWarning(false);
            } else {
                initialRender.current = false;
            }
        }, fieldValues);

        useEffect(() => {
            var val = Number(values?.rentAndServiceCharge) +
                Number(values?.utilities) +
                Number(values?.softwareAndIT) +
                Number(values?.officeITAndCommunications) +
                Number(values?.marketingCosts) +
                Number(values?.companyCarFuelAllowance) +
                Number(values?.travelCost) +
                Number(values?.auditFeesAndAccountancy) +
                Number(values?.bankChargesAndInterest) +
                Number(values?.professionalIndemnityInsurance) +
                Number(values?.otherInsuranceCosts) +
                Number(values?.legalCosts) +
                Number(values?.depreciation) +
                Number(values?.badDebts) +
                Number(values?.pensionCosts) +
                Number(values?.nationalInsuranceContribution) +
                Number(values?.otherAnnualCosts);
            if (val > 0 && val !== values?.totalAnnualOverheadCosts) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        }, [getIn(values, 'totalAnnualOverheadCosts')]);

        return null;
    }

    const saveOverheads = (values: any) => {
        setIsDirtyForm(false);
        setShowWarning(false);
        editPractice(values).finally(() => {
            handleSubmit()
        });
    }

    const goBack = () => {
        setShowWarning(false);
        handleBack()
    }

    return (
        <>
            {isLoadingPractice ? (<Loading />) : (
                <>
                    <div className="form-heading">
                        <h2 className="form-heading__title">{title} </h2>
                        {props.children}
                        <p><strong>You can either complete this itemised list, or provide one total overhead cost.</strong></p>
                        <p>Check our <a target="_BLANK" href="/help">guidance notes</a> for help and information on how to calculate these costs.</p>
                    </div>
                    <div className="content-container">
                        { practice && (
                            <Formik
                                initialValues={practice}
                                validationSchema={PracticeOverheadsFormValidation}
                                validateOnChange={false}
                                validateOnBlur={true}
                                onSubmit={values => saveOverheads(values)}>
                                {(overheadsForm) => (
                                    <>
                                        <CalculateValuesComponent />
                                        <Form onKeyDown={(keyEvent) => {
                                            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                                keyEvent.preventDefault();
                                            }
                                        }}>
                                            {isSettingsPage && (
                                                <PromptIfDirty isDirtyForm={isDirtyForm} setIsDirtyForm={setIsDirtyForm}  />
                                            )}
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Rent and service charge:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="rentAndServiceCharge" type="number" name="rentAndServiceCharge" helptext="Include business taxes and maintenance charges, but not utilities or insurance." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Utilities:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="utilities" type="number" name="utilities" helptext="Include electricity, gas, telephone, broadband, TV, cleaning premises, and repair costs. " placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Architectural and design software and IT:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="softwareAndIT" type="number" name="softwareAndIT" helptext="Specialist architecture software, such as design and modelling programmes like Autodesk Revit or NBS Chorus" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Office IT and telecommunications:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="officeITAndCommunications" type="number" name="officeITAndCommunications" helptext="Include any system maintenance contracts, software purchases or leases for equipment like printers." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Marketing:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="marketingCosts" type="number" name="marketingCosts" helptext="Include marketing, promotion or PR activities, such as printing brochures or paid advertising. " placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Company car and fuel allowance:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="companyCarFuelAllowance" type="number" name="companyCarFuelAllowance" helptext="Include company cars and their maintenance, as well as any fuel allowance paid. " placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Additional travel costs:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="travelCost" type="number" name="travelCost" helptext="Include any rail or air fares." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Accountancy and auditing:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="auditFeesAndAccountancy" type="number" name="auditFeesAndAccountancy" helptext="Any fees you pay an accountant, auditor, or other financial advisor." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Banking:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="bankChargesAndInterest" type="number" name="bankChargesAndInterest" helptext="Interest or charges by your bank or credit card company. " placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Professional Indemnity Insurance:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="professionalIndemnityInsurance" type="number" name="professionalIndemnityInsurance" helptext="All RIBA Chartered Practices must hold suitable insurance to cover potential liabilities arising from negligence or breach of contract." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Other insurance costs:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="otherInsuranceCosts" type="number" name="otherInsuranceCosts" helptext="" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Legal costs:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="legalCosts" type="number" name="legalCosts" helptext="Include any fees you pay solicitors or legal advisors." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Depreciation:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="depreciation" type="number" name="depreciation" helptext="A provision for the reduction in the value of fixed assets over time, due in particular to wear and tear."  placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Bad debts:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="badDebts" type="number" name="badDebts" helptext="Provision for income that is no longer considered collectable." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Pension costs:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="pensionCosts" type="number" name="pensionCosts" helptext="Employer’s contribution to staff pensions." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Employer National Insurance contribution:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="nationalInsuranceContribution" type="number" name="nationalInsuranceContribution" helptext="Employer’s national insurance contribution on earnings and benefits provided to employees." placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Other annual costs:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} id="otherAnnualCosts" type="number" name="otherAnnualCosts" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="divider "></div>
                                            {showWarning && (
                                                <p style={{ color: '#f44336' }}>You have overwritten the total annual overhead costs. Please check the itemised list above and make sure you’ve provided the correct details.</p>
                                            )}
                                            <div className="join-riba-form-content__section mt-50">
                                                <div className="form-input-container ">
                                                    <div className="form-input-container__label">
                                                        <FormLabel labelText={'Total Annual Overhead Costs:'} />
                                                    </div>
                                                    <div className="form-input-container__input">
                                                        <Field component={CurrencyFormInput} onChange={() => { }} id="totalAnnualOverheadCosts" type="number" name="totalAnnualOverheadCosts" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            {isSettingsPage && (
                                                <p style={{ color: '#407ec9' }}><strong>IMPORTANT: You need to click on the Apply Changes button below if you want to apply your changes to new fee calculations. Staff will be notified but will not see this data.</strong></p>
                                            )}
                                            {Object.keys(overheadsForm.errors).length > 0 && (
                                                <Alert text='Please correct the errors highlighed above' styleClass='alert alert--error' title='Error' />
                                            )}
                                            <div className="join-riba-form-content__actions">
                                                <button onClick={() => { goBack() }} className="button  ">{cancelButtonText ? cancelButtonText : 'Back'}</button>
                                                <button onClick={() => { overheadsForm.submitForm() }} disabled={overheadsForm.values?.totalAnnualOverheadCosts === 0} className="button button--opaque ">{confirmButtonText ? confirmButtonText : 'Confirm and continue'}</button>
                                            </div>
                                        </Form>
                                    </>

                                )}
                            </Formik>
                        )}
                    </div>
                </>
            )}

        </>

    )
};

export default PracticeOverheadsForm;