import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../organisms/Loading';
import { history } from '../../..';
import ProjectDetailsForm from '../../organisms/ProjectDetailsForm';
import ProjectSideNavigation from '../../organisms/ProjectSideNavigation';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';

interface AddEditProjectProps { id: string }

const ProjectDetails: React.FC<RouteComponentProps<AddEditProjectProps>> = observer(({ match }) => {

    const rootStore = useContext(RootStoreContext);
    const { project, getProject, isLoading } = rootStore.projectStore;

    useEffect(() => {
        let isLoaded = false;
        if (match.params.id) {
            getProject(match.params.id)
        }
        return () => {
            isLoaded = true;   // clean up function
        };

    }, [getProject, match.params.id]);

    const handleSubmit = () => {
        history.push('/projects')
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">

                {isLoading ? (
                    <Loading />
                ) : (
                        <>
                            <SideNavigationMobile />
                            <ProjectSideNavigation project={project} currentPage={1} />
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30">
                                            <ProjectDetailsForm
                                                confirmButtonText={'Submit'}
                                                cancelButtonText={'Cancel'}
                                                project={project}
                                                handleSubmit={handleSubmit}
                                                handleBack={handleSubmit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </div>
    )

})

export default ProjectDetails;