import React from 'react';
import HelpSideNavigation from '../../organisms/HelpSideNavigation';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';


const TutorialAndArticles: React.FC = () => {

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <SideNavigationMobile />
                <HelpSideNavigation currentPage={3} />
                <div className="dashboard-content-wrapper">
                    <div className="dashboard-body-content dashboard-content-wrapper__white">
                        <div className="dashboard-body-content__form">
                            <div className="dashboard-panel mt-40 ml-30 mr-50">
                                <h1>Tutorials and articles</h1>
                                <h3><strong>Step by step tutorials to help you get started with the RIBA Fee Calculator</strong></h3>
                                <p>Additional articles, webinars, and exclusive Member-only content exploring fees.</p>
                                <p>
                                    <a href="https://www.architecture.com/digital-practice-tools/riba-fee-calculator/fee-calculator-setup-guide" target="_blank">Setting up your practice on the RIBA Fee Calculator</a><br />
                                    <a href="https://www.architecture.com/digital-practice-tools/riba-fee-calculator/how-to-generate-a-fee-calculation" target="_blank">How to generate a fee calculation</a><br />
                                    <a href="https://www.architecture.com/digital-practice-tools/riba-fee-calculator/getting-the-most-out-of-the-RIBA-fee-calculator" target="_blank">Getting the most out of the RIBA Fee Calculator</a><br />
                                    <a href="https://riba-academy.architecture.com/ilp/pages/description.jsf?menuId=1106#/users/@self/catalogues/150435/courses/606084/description" target="_blank">RIBA Academy webinar and video demonstration: How to use the RIBA Fee Calculator</a><br />
                                </p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default TutorialAndArticles;