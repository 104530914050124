import React from 'react';
import { RouteComponentProps } from 'react-router-dom';


interface ErrorProps { errorId: string }

const Error: React.FC<RouteComponentProps<ErrorProps>> = ({ match }) => {
    return (
        
        <div className="narror-content-container mt-20">
            <div className="content-container">
                <div className="join-riba-form-content">
            
                {match.params.errorId === '473' ? (
                        <div className="join-riba-form-content__form">
                            <div className="heading-one">
                                <h1 className="heading-one__heading">RESTRICTED ACCESS</h1>
                            </div>
                            <div className="subtitle">
                                <h2 className="subtitle__title">Sorry - there seems to be a problem accessing the Fee Calculator</h2>
                            </div>

                            <p><strong>Please get in touch with our Contact Centre &#38; Membership Servicing team:</strong></p>
                            email: <a href="mailto:support@riba.org?subject=Fee Calculator support needed">support@riba.org</a><br />
                            telephone: <a href="tel:+44(0)2073075355">+44 (0)20 7307 5355</a><br />
                            Monday to Friday, 9am to 5pm <br />
                            <br />
                            <p></p>
                        </div>  
                ) : (
                        <div className="join-riba-form-content__form">
                            <div className="heading-one">
                                <h1 className="heading-one__heading">ERROR</h1>
                            </div>
                            <div className="subtitle">
                                <h2 className="subtitle__title">Sorry - there seems to be a problem accessing the Fee Calculator</h2>
                            </div>

                            <p><strong>Please get in touch with our Contact Centre &#38; Membership Servicing team:</strong></p>
                            email: <a href="mailto:support@riba.org?subject=Fee Calculator support needed">support@riba.org</a><br />
                            telephone: <a href="tel:+44(0)2073075355">+44 (0)20 7307 5355</a><br />
                            Monday to Friday, 9am to 5pm <br />
                            <br />
                            <p></p>
                        </div>
                )}

                </div>
            </div>
        </div>
    )
}

export default Error;