import React, { useContext, useState, useLayoutEffect } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { Formik, Form } from "formik";
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { RibaStages } from '../../utils/Constants'
import { FeeCalculation, FeeCalculationDetailed } from '../../types/feeCalculation';
import { FeeCalculationStage } from '../../types/feeCalculationStage';
import { getFeeCalculationStatusName } from '../../utils/Helpers';
import { FeeCalculationStageTask } from '../../types/feeCalculationStageTask';
import Alert from '../molecules/Alert';
import Modal from 'react-modal';
import { runInAction } from 'mobx';

type FeeCalculationStagesFormProps = {
    handleSubmit: (feeCalc: any) => any,
    feeCalculationDetailed: FeeCalculationDetailed | undefined,
    canEdit: boolean
}

const FeeCalculationStagesForm: React.FC<FeeCalculationStagesFormProps> = observer((props) => {
    const { feeCalculationDetailed, handleSubmit, canEdit } = props;
    const rootStore = useContext(RootStoreContext);
    const { editFeeCalculation, deleteTasks } = rootStore.feeCalculationStore;
    const { pathname } = useLocation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [stagesToDelete, setStagesToDelete] = useState<any>([]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const saveFeeCalculationStages = (feeCalculation: FeeCalculation) => {
        editFeeCalculation(feeCalculation).then((feeCalc) => {
            handleSubmit(feeCalc);
        })
    };

    const handleFormSubmit = (feeCalculation: FeeCalculation) => {
        if (feeCalculation.feeCalculationStages?.some((feeCalculationStage: FeeCalculationStage) => { return (!feeCalculationStage.enabled && feeCalculationStage.feeCalculationStageTasks.length > 0) })) {
            setStagesToDelete(feeCalculation.feeCalculationStages?.filter((feeCalculationStage: FeeCalculationStage) => { return (!feeCalculationStage.enabled && feeCalculationStage.feeCalculationStageTasks.length > 0) }))
            setModalIsOpen(true)
        } else {
            saveFeeCalculationStages(feeCalculation)
        }
    };

    const deleteStageData = (feeCalculation: FeeCalculation) => {
        const tasksToDelete: any = [];
        feeCalculation.feeCalculationStages?.forEach((feeCalculationStage: FeeCalculationStage) => {
            if (!feeCalculationStage.enabled && feeCalculationStage.feeCalculationStageTasks.length > 0) {
                feeCalculationStage.feeCalculationStageTasks.forEach((feeCalculationStageTask: FeeCalculationStageTask) => {
                    tasksToDelete.push(feeCalculationStageTask.id)
                })
            }
        })
        deleteTasks(tasksToDelete).then((deletedTasks) => {
            runInAction(() => {
                feeCalculation.feeCalculationStages?.forEach((feeCalculationStage: FeeCalculationStage) => {
                    if (!feeCalculationStage.enabled) {
                        feeCalculationStage.feeCalculationStageTasks = [];
                        feeCalculationStage.practiceMargin = 0
                        feeCalculationStage.clientStageFee = 0
                        feeCalculationStage.totalStageCost = 0
                    }
                })
            })
            
            saveFeeCalculationStages(feeCalculation);
        })
    };

    return (
        <>
            { feeCalculationDetailed?.feeCalculation && (
                <>
                
                    {feeCalculationDetailed?.feeCalculation?.name && feeCalculationDetailed?.feeCalculation?.name != "" ? 
                        (
                            <>
                                <h1 className="mb-0">{feeCalculationDetailed?.feeCalculation?.name}</h1>
                                <h3 className="mt-0">{`${getFeeCalculationStatusName(feeCalculationDetailed?.feeCalculation.state)}_${feeCalculationDetailed?.feeCalculation.version}`}</h3>
                            </>
                        ) : 
                        (
                            <><h1>{`${getFeeCalculationStatusName(feeCalculationDetailed?.feeCalculation.state)}_${feeCalculationDetailed?.feeCalculation.version}`}</h1></>
                        )
                    }
                    <Formik
                        initialValues={feeCalculationDetailed?.feeCalculation}
                        enableReinitialize={true}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values) => {
                            handleFormSubmit(values)
                        }}>
                        {(feeCalculationForm) => (
                            <>
                                <Form onChange={() => { }}>
                                    <fieldset>
                                        <div className="join-riba-form-content__section">
                                            <div className="form-heading ">
                                                <h2 className="form-heading__title">Select stages from the RIBA Plan of Work</h2>
                                                <div className="divider divider--dark"></div>
                                                <p>Select which <a target="_BLANK" href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/riba-plan-of-work">RIBA Plan of Work</a> stages you want to include as part of this project, and you can add activities relating to them as you prepare your fee calculation.</p>
                                            </div>

                                            <div className="narrow-content-container ml-0 mb-40 mt-20" >
                                                <div className="checkbox-group">

                                                    {feeCalculationForm.values.feeCalculationStages?.map((feeCalculationStage: FeeCalculationStage, i) => {
                                                        return (
                                                            <label key={feeCalculationStage.id} className="checkbox__group mb-20">
                                                                <input disabled={!canEdit} onChange={(e) => { feeCalculationForm.handleChange(e) }} type="checkbox" name={`feeCalculationStages[${i}].enabled`} checked={feeCalculationStage.enabled} className="checkbox__input" />
                                                                <i className="checkbox__check"></i>
                                                                <p className="checkbox__text">Stage {feeCalculationStage.stage.stageId} {RibaStages[feeCalculationStage.stage.stageId].name}</p>
                                                            </label>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="join-riba-form-content__actions">
                                            {canEdit && (<button type="submit" disabled={!feeCalculationForm.values.feeCalculationStages?.some((feeCalcStage: FeeCalculationStage) => feeCalcStage?.enabled)} className="button button--opaque ">Save</button>)}
                                        </div>
                                    </fieldset>
                                </Form>
                                <Modal
                                    appElement={document.getElementById('root') as HTMLElement}
                                    isOpen={modalIsOpen}
                                    onRequestClose={() => {setStagesToDelete([]); setModalIsOpen(false);}}
                                    overlayClassName="Overlay"
                                    contentLabel="Permanently delete RIBA Stage from fee calculation"
                                    className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                                    <div className="dashboard-modal">

                                        <>
                                            <span onClick={() => {setStagesToDelete([]); setModalIsOpen(false);}} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                            <h1 className="dashboard-modal__title">Permanently delete RIBA Stage from fee calculation</h1>
                                            <div className="whitespace whitespace--single"></div>
                                            <Alert title='Warning' styleClass='alert alert--warning' text='This action cannot be undone. All data will be lost for deselecetd RIBA Stages.' />
                                            <p className="dashboard-modal__subtext">You are about to remove <strong>{stagesToDelete.map((stage : FeeCalculationStage, index : any) => { return <span key={`demo_snap_${index}`}>{ (index ? ', ' : '') + RibaStages[stage.stage.stageId].name }</span>; })}</strong> from your fee calculation. All data against that Stage will be lost.</p>
                                            <div className="whitespace whitespace--single"></div>
                                            <div className="cta-band__buttons">
                                                <button onClick={() => {setStagesToDelete([]); setModalIsOpen(false);}} className="button  cta-band__button">Cancel</button>
                                                <button onClick={() => { deleteStageData(feeCalculationForm.values) }} className="button button--opaque cta-band__button">Delete selected stages</button>
                                            </div>
                                        </>

                                    </div>
                                </Modal>
                            </>

                        )}
                    </Formik>
                </>
            )}
        </>


    )
});

export default FeeCalculationStagesForm;