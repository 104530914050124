
import * as Yup from 'yup';

export const EditPracticeFormValidation = Yup.object().shape({
    name: Yup.string()
        .required('Please complete this mandatory field.'),
    addressLineOne: Yup.string()
        .required('Please complete this mandatory field.'),
    addressLineTwo: Yup.string()
        .optional(),
    addressLineThree: Yup.string()
        .optional(),
    postcode: Yup.string()
        .required('Please complete this mandatory field.')
});