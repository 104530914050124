import React from 'react';

const ContactUs: React.FC = () => {
    return (
        <div className="info-box  mt-50">
            <h3 className="info-box__header">Need help?</h3>
            <div className="info-box__content">
                If your details are wrong or need updating, call us on <a href="tel:+44(0)2073075355">+44 (0)20 7307 5355</a>  or email us <a href="mailto:support@riba.org?subject=Fee Calculator support needed">support@riba.org</a>
            </div>
        </div>
    )
};

export default ContactUs;
