import AddEditProjectForm from '../../organisms/AddEditProjectForm';
import Loading from '../../organisms/Loading';
import ProjectSideNavigation from '../../organisms/ProjectSideNavigation';
import React, { useContext, useEffect } from 'react';
import SideNavigationMobile from '../../organisms/SideNavigationMobile';
import { history } from '../../..';
import { RootStoreContext } from '../../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';

interface AddEditProjectProps { id: string }

const AddEditProject: React.FC<RouteComponentProps<AddEditProjectProps>> = observer(({ match }) => {

    const rootStore = useContext(RootStoreContext);
    let { setIsDirtyForm } = rootStore.feeCalculationStore;
    const { project, getProject, isLoading } = rootStore.projectStore;

    useEffect(() => {
        if (match.params.id) {
            getProject(match.params.id)
        } else {
            getProject('')
        }

        setIsDirtyForm(false);
    }, [getProject, match.params.id, setIsDirtyForm]);

    const handleSubmit = (projectId: number) => {
        setIsDirtyForm(false);
        history.push(`/projects/${projectId}`)
    }

    const handleCancel = (projectId: number) => {
        setIsDirtyForm(false);
        history.goBack();
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                {isLoading ? (
                    <Loading />
                ) : (
                        <>
                            <SideNavigationMobile />
                            <ProjectSideNavigation project={project} currentPage={1} />
                            <div className="dashboard-content-wrapper">
                                <div className="dashboard-body-content dashboard-content-wrapper__white">
                                    <div className="dashboard-body-content__form">
                                        <div className="dashboard-panel mt-40 ml-30">
                                            <AddEditProjectForm
                                                confirmButtonText={'Save'}
                                                cancelButtonText={'Cancel'}
                                                project={project}
                                                handleSubmit={handleSubmit}
                                                handleBack={handleCancel} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </div>
    )

})

export default AddEditProject;