import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga';
import { RootStoreContext } from '../../stores/rootStore';
import { FeeCalculation, FeeCalculationDetailed } from '../../types/feeCalculation';
import Modal from 'react-modal';
import Alert from './Alert';
import { runInAction } from 'mobx';
import { NonEditableStates } from '../../utils/Constants';
import { history } from '../..';
import { FormikProps } from 'formik';


type FeeCalculationActionsDropdownProps = {
    feeCalculation: FeeCalculation,
    handleSelection: () => any,
    saveFeeCalculation?: (feeCalculation: FeeCalculation, isAutoSave? : boolean, callback? : () => any) => any,
    canEdit: boolean,
    isOwnerOrAdmin: boolean,
    projectName: string | undefined,
    feeCalculationForm?: FormikProps<FeeCalculation>
}

const FeeCalculationActionsDropdown: React.FC<FeeCalculationActionsDropdownProps> = (props) => {

    const { feeCalculation, handleSelection, isOwnerOrAdmin, projectName, feeCalculationForm, saveFeeCalculation } = props;
    const rootStore = useContext(RootStoreContext);
    const { getProject } = rootStore.projectStore;
    const { editFeeCalculation, exportFeeCalculationExcel, exportFeeCalculationCreateContract, getFeeCalculationDetailed, duplicateFeeCalculation, deleteFeeCalculation } = rootStore.feeCalculationStore;
    const [value, setValue] = useState('');

    //modals
    const [loading, setLoading] = useState(false);
    const [exportContractModalIsOpen, setExportContractModalIsOpen] = useState(false);
    const [stateModalIsOpen, setStateModalIsOpen] = useState(false);
    const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [state, setState] = useState('');

    const trackEvent = (event: string) => 
        ReactGA.event({
            category: 'Fee Calculation',
            action: 'Fee Calculation Action Tray',
            label: event
        })

    const handleChange = (e: any) => {
        switch (e.target.value) {
            case "DELETE_FEE_CALCULATION":
                setDeleteModalIsOpen(true);
                break;
            case "DOWNLOAD_EXCEL":
                setLoading(true);
                trackEvent('Download to Excel');
                if (feeCalculationForm && saveFeeCalculation) {
                    saveFeeCalculation(feeCalculationForm.values, true, () => {
                        //Auto save before exporting
                        exportFeeCalculationExcel(feeCalculation.id?.toString()).then(() => {
                            setLoading(false);
                        });
                    });
                } else {
                    exportFeeCalculationExcel(feeCalculation.id?.toString()).then(() => {
                        setLoading(false);
                    });
                }
                break;
            case "DUPLICATE_FEE_CALCULATION":
                setDuplicateModalIsOpen(true);
                break;
            case "CREATE_DIGITAL_RIBA_CONTRACT":
                setExportContractModalIsOpen(true);
                break;
            case "APPROVED":
                setStateModalIsOpen(true);
                setState('Approved')
                break;
            case "ACCEPTED":
                setStateModalIsOpen(true);
                setState('Accepted')
                break;
            case "DECLINED":
                setStateModalIsOpen(true);
                setState('Declined')
                break;
        }
        setValue('')
    }

    const confirmExportFeeCalculationCreateContract = () => {
        setLoading(true);
        trackEvent('Create digital RIBA contract');
        exportFeeCalculationCreateContract(feeCalculation.id?.toString()).then(() => {
            setExportContractModalIsOpen(false);
            setLoading(false);
            handleSelection();
        });
    }

    const confirmStateChange = () => {
        setLoading(true);
        // ga event tracking
        switch(state){
            case "Approved": trackEvent('Change status to Internal approval'); break;
            case "Accepted": trackEvent('Change status to Client accepted'); break;
            case "Declined": trackEvent('Change status to Client declined'); break;
        }
        getFeeCalculationDetailed(feeCalculation.id?.toString()).then((feeCalculationDetailed: FeeCalculationDetailed | undefined) => {
            if (feeCalculationDetailed) {
                runInAction(() => {
                    feeCalculationDetailed.feeCalculation.state = state;
                    editFeeCalculation(feeCalculationDetailed.feeCalculation).then(() => {
                        setStateModalIsOpen(false);
                        setState('');
                        getProject(feeCalculationDetailed.feeCalculation.projectId.toString());
                        setLoading(false);
                        handleSelection();
                    })
                })
            } else {
                setLoading(false);
            }
        })
    }

    const saveUnderExistingProject = () => {
        trackEvent('Duplicate fee calculation under existing project');
        duplicateFeeCalculation(feeCalculation.id?.toString(), feeCalculation.projectId?.toString()).then((feeCalculationCopy: any | undefined) => {
            if (feeCalculationCopy && feeCalculationCopy.feeCalculationId && feeCalculationCopy.projectId) {
                history.push(`/projects/${feeCalculationCopy.projectId}/fee-calculation/${feeCalculationCopy.feeCalculationId}`)
            }
        })
    }

    const saveUnderNewProject = () => {
        trackEvent('Duplicate fee calculation under new project');
        history.push(`/duplicate-fee-calculation/${feeCalculation.id}`)
    }


    const deleteFeeCalc = () => {
        trackEvent('Delete fee calculation');
        var project = feeCalculation.projectId;
        deleteFeeCalculation(feeCalculation.id?.toString())
        .then(() => {
            var path = window.location.pathname;
            if(path == '/projects') window.location.reload()
            else history.push(`/projects/${project}`)
        });
    } 

    return (
        <>
            <div className="input-wrapper">
                <select value={value} onChange={handleChange} className="dropdown-input ">
                    <option value="" >Select an action</option>
                    <option value="DELETE_FEE_CALCULATION">Delete fee calculation</option>
                    <option value="DOWNLOAD_EXCEL">Download into Excel</option>
                    <option value="DUPLICATE_FEE_CALCULATION">Duplicate fee calculation</option>
                    <option disabled={!isOwnerOrAdmin || (NonEditableStates.includes(feeCalculation.state))} value="CREATE_DIGITAL_RIBA_CONTRACT">Create digital RIBA contract</option>
                    <option disabled={!isOwnerOrAdmin || (NonEditableStates.includes(feeCalculation.state)) || feeCalculation.state === 'Approved'} value="APPROVED">Change status to 'Internal approval'</option>
                    <option disabled={!isOwnerOrAdmin || (NonEditableStates.includes(feeCalculation.state)) || feeCalculation.state === 'Accepted'} value="ACCEPTED">Change status to 'Client accepted'</option>
                    <option disabled={!isOwnerOrAdmin || (NonEditableStates.includes(feeCalculation.state)) || feeCalculation.state === 'Declined'} value="DECLINED">Change status to 'Client declined'</option>
                </select>
            </div>

            <Modal
                isOpen={exportContractModalIsOpen}
                onRequestClose={() => setExportContractModalIsOpen(false)}
                overlayClassName="Overlay"
                contentLabel="Create a contract using this fee calculation"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    {loading ? (
                        <div className="load-container loader--rectangles">
                            <div className="loader">Loading...</div>
                        </div>
                    ) : (
                            <>
                                <span onClick={() => setExportContractModalIsOpen(false)} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                <h1 className="dashboard-modal__title">Create a contract using this fee calculation</h1>
                                <div className="whitespace whitespace--single"></div>
                                {feeCalculation.state !== 'Approved' ? (
                                    <Alert title='Information' styleClass='alert alert--information' text="The status of this calculation will change from Draft to Approved and you won't be able to make any more changes to it. You can duplicate it if you'd like to keep a Draft version." />
                                ) : (
                                        <Alert title='Information' styleClass='alert alert--information' text='You will be taken to the RIBA Contracts Digital website' />
                                    )}
                                <p className="dashboard-modal__subtext">This project will be exported into RIBA Contracts Digital. You will leave the RIBA Fee Calculator, and see your project on the RIBA Contracts Digital homepage when you click to proceed. </p>
                                <div className="whitespace whitespace--single"></div>
                                <div className="cta-band__buttons">
                                    <button onClick={() => setExportContractModalIsOpen(false)} className="button  cta-band__button">Cancel</button>
                                    <button onClick={() => { confirmExportFeeCalculationCreateContract() }} className="button button--opaque cta-band__button">Proceed</button>
                                </div>
                            </>
                        )}
                </div>
            </Modal>

            <Modal
                isOpen={stateModalIsOpen}
                onRequestClose={() => setStateModalIsOpen(false)}
                overlayClassName="Overlay"
                contentLabel="Change status of the fee calculation"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    {loading ? (
                        <div className="load-container loader--rectangles">
                            <div className="loader">Loading...</div>
                        </div>
                    ) : (
                            <>
                                <span onClick={() => setStateModalIsOpen(false)} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                <h1 className="dashboard-modal__title">Change status of the fee calculation</h1>
                                <div className="whitespace whitespace--single"></div>
                                <Alert title='Warning' styleClass='alert alert--warning' text='This action cannot be undone.' />
                                <p className="dashboard-modal__subtext">You are about to change the status of <strong>{feeCalculation.state}_{feeCalculation.version}</strong> from <strong>{projectName}</strong>. Your fee calculation name will change to <strong>{state}_{feeCalculation.version}</strong> and you will not be able to edit it any more. However, you will still be able to duplicate it.</p>
                                <div className="whitespace whitespace--single"></div>
                                <div className="cta-band__buttons">
                                    <button onClick={() => setStateModalIsOpen(false)} className="button  cta-band__button">Cancel</button>
                                    <button onClick={() => { confirmStateChange() }} className="button button--opaque cta-band__button">Change status</button>
                                </div>
                            </>
                        )}
                </div>
            </Modal>

            <Modal
                isOpen={duplicateModalIsOpen}
                onRequestClose={() => setDuplicateModalIsOpen(false)}
                overlayClassName="Overlay"
                contentLabel="Duplicate fee calculation"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    {loading ? (
                        <div className="load-container loader--rectangles">
                            <div className="loader">Loading...</div>
                        </div>
                    ) : (
                            <>
                                <span onClick={() => setDuplicateModalIsOpen(false)} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                <h1 className="dashboard-modal__title">Duplicate fee calculation</h1>
                                <div className="whitespace whitespace--single"></div>
                                <Alert title='Warning' styleClass='alert alert--warning' text='Specify the location for the new fee calculation.' />
                                <p className="dashboard-modal__subtext">You are about to duplicate <strong>{feeCalculation.state}_{feeCalculation.version}</strong> from <strong>{projectName}</strong>. You need to indicate where you want the new fee calculation to be saved.</p>
                                <div className="whitespace whitespace--single"></div>
                                <div className="cta-band__buttons">
                                    <button onClick={() => saveUnderExistingProject()} className="button  cta-band__button">Save under existing project</button>
                                    <button onClick={() => saveUnderNewProject()} className="button button--opaque cta-band__button">Save under new project</button>
                                </div>
                            </>
                        )}
                </div>
            </Modal>

            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={() => setDeleteModalIsOpen(false)}
                overlayClassName="Overlay"
                contentLabel="Archive project"
                className='modal modal-react remodal remodal-is-initialized remodal-is-opened'>
                <div className="dashboard-modal">
                    {loading ? (
                        <div className="load-container loader--rectangles">
                            <div className="loader">Loading...</div>
                        </div>
                    ) : (
                            <>
                                <span onClick={() => setDeleteModalIsOpen(false)} data-remodal-action="close" className="remodal-close dashboard-modal__close">close</span>
                                <h1 className="dashboard-modal__title">Delete fee calculation</h1>
                                <div className="whitespace whitespace--single"></div>
                                <Alert title='Error' styleClass='alert alert--error' text='Deleting a fee calculation will completely remove it. This action is cannot be undone' />
                                <p className="dashboard-modal__subtext">You are about to delete <strong>{feeCalculation.name && feeCalculation?.name != "" ? feeCalculation?.name : `${feeCalculation.state}_${feeCalculation.version}`}</strong>. Are you sure you would like to proceed?</p>
                                <div className="whitespace whitespace--single"></div>
                                <div className="cta-band__buttons">
                                    <button onClick={() => setDeleteModalIsOpen(false)} className="button  cta-band__button">Cancel</button>
                                    <button onClick={() => deleteFeeCalc()} className="button button--opaque cta-band__button">Confirm delete</button>
                                </div>
                            </>
                        )}
                </div>
            </Modal>
        </>
    );
}

export default FeeCalculationActionsDropdown;
